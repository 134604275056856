<div class="loader" *ngIf="loader">
  <mat-spinner style="margin:0 auto;"></mat-spinner>
  </div>
<div class="container-fluid">
  <div class="row">
    <div class="col-sm-12">
      <div class="card">
        <div class="card-header ">
          <div class="row">
            <div class="col-sm-9">
              <h5>DROPSHIP LISTS</h5>
            </div>
            <div class="col-sm-3">
              <div class="input-group">
                <input type="text" (keyup.enter)="search(searchText)" [(ngModel)]="searchText" class="form-control "
                  placeholder="Search By Email & Phone">
                <div class="input-group-append">
                  <button (click)="search(searchText)" class="btn btn-secondary" type="button">
                    <i class="fa fa-search"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card-body">
          <div class="pull-right ml-2">
            <button class="btn btn-primary" (click)="exportAsXLSX()" type="button" data-toggle="collapse"
              data-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
              EXPORT
            </button>
          </div>
          <div class="btn-popup pull-right ml-2">
            <button type="button" class="btn btn-secondary" [cdkCopyToClipboard]="regLink">COPY REFERRAL LINK</button>
          </div>

          <div id="batchDelete" class="custom-datatable">

            <div class="table-responsive">
              <!-- <ng2-smart-table [settings]="settings" [source]="digital_list"></ng2-smart-table> -->
              <table class="table">
                <thead class="thead-dark">
                  <tr>
                    <th>ID</th>
                    <th>REGISTRATION DATE</th>
                    <th>NAME</th>
                    <th>EMAIL</th>
                    <th>PHONE</th>
                    <th>ADDRESS</th>
                    <th>UPLINE NAME</th>
                    <th>UPLINE EMAIL</th>
                    <th>STATUS</th>
                    <th colspan="3">ACTION</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let dropship of dropship; let i = index">
                    <td>{{dropship.salt}}
                      <!-- OBP-{{ dropship.id }}MY -->
                    </td>

                    <td>{{ dropship.created_at | date: "dd/MM/YYYY" }}</td>
                    <!-- <td>{{ dropship.user_name }}</td> -->
                    <td *ngIf="dropship.details!=null">{{dropship.details?.first_name ? dropship.details.first_name:''}} {{dropship.details?.last_name}}</td>
                    <td *ngIf="dropship.details==null">N/A</td>
                    <td>{{ dropship.email }}</td>
                    <td>{{ dropship.details?.phone ? dropship.details.phone:'------------'}}</td>
                    <td>{{ dropship.details?.address_line1 ? dropship.details.address_line1:'------------'}}<br>
                      {{ dropship.details?.address_line2 ? dropship.details.address_line2:''}}
                    </td>
                    <td>{{ dropship.uplink_ds }}</td>
                    <td>{{ dropship.uplink_email ? dropship.uplink_email:'' }}</td>
                    <td *ngIf="dropship.status == 1">
                      <span class="statusCircles1"></span>
                    </td>
                    <td *ngIf="dropship.status == 0">
                      <span class="statusCircles2"></span>
                      </td>
                    <td>
                      <!-- (click)="test(dropship.id)" -- >
                      <div class="dropdown">
                      <app-feather-icons title="Affiliate"  [icon]="'more-vertical'"></app-feather-icons>
                          <a class="dropdown-content itemz" href="#2">View Downline</a>
                          <a class="dropdown-content itemz" href="#3">View Personal Sale</a>
                          <a class="dropdown-content itemz" href="#1">View Downline Sale</a>
                      </div>
                <! -- position: static|absolute|fixed|relative|sticky|initial|inherit; -->
                <div ngbDropdown>
                  <!-- <button type="button" class="btn btn-outline-primary btn-sm" ngbDropdownToggle>Actions</button> -->
                  <app-feather-icons title="Affiliate" ngbDropdownToggle  [icon]="'more-vertical'"></app-feather-icons>

                  <div ngbDropdownMenu>
                    <button (click)="showDownline(dropship.id,dropship.email)" ngbDropdownItem>View Downline</button>
                   <div class="dropdown-divider"></div>
                    <button (click)="showGenealogy(dropship)" ngbDropdownItem>View Genealogy</button>
                    <!-- <div class="dropdown-divider"></div>
                    <button ngbDropdownItem>Delete</button> -->
                  </div>
                </div>

                    </td>

                    <td>
                      <app-feather-icons title="Edit" (click)="edit(dropship.id)" [icon]="'edit-3'"></app-feather-icons>
                    </td>
                    <td>
                      <app-feather-icons title="Delete" (click)="delete(dropship.id)" [icon]="'trash-2'"></app-feather-icons>
                    </td>



                  </tr>

                </tbody>
              </table>
            </div>
            <div class="btn-popup pull-right" *ngIf="lastPage > 1 && dropship?.length > 0">
              <pagination-with-api [apiUrl]="apiUrl" [totalPage]="totalPage" (valueChanged)="pageChanged($event)">
              </pagination-with-api>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
