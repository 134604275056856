import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/@services/api.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-customer-list',
  templateUrl: './customer-list.component.html',
  styleUrls: ['./customer-list.component.scss']
})
export class CustomerListComponent implements OnInit {
  customers: any;
  customersExport: any;
  searchText: string = '';
  totalPage: number;
  lastPage: any;
  currentPage: any;
  pages: any[] = [];
  next = 2;
  constructor(
    private api: ApiService,
  ) { }

  ngOnInit(): void {
    this.getCustomers();
  }

  get apiUrl (){
    return this.api.userApi + 'guest-user?page=' + 1 + '&search=' + this.searchText;
  }
  getCustomers() {

    this.api.get(this.apiUrl).subscribe((res: any) => {
      this.customers = res.data;
      this.lastPage = res.last_page;
      this.currentPage = res.current_page
      this.totalPage = res.last_page
    })
  }
  search(searchKey) {
    if(searchKey.length >=3){
      this.getCustomers();
    }else{
      this.getCustomers();
    }
  }
  pageChanged(customers:any){
    console.log(customers);
    if(customers == '')return
    this.customers = customers
    window.scrollBy(0, -1990);
   }


   delete(id) {
    var model = {
      id: id,
      status: 2
    }
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        this.api.create(this.api.userApi + "user-status", model).subscribe(res => {
          Swal.fire(
            'Deleted!',
            'Customer has been deleted.',
            'success'
          )
          this.getCustomers();
        })

      }
    })
  }

}
