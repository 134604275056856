import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { ApiService } from 'src/app/@services/api.service';
import { StorageService } from 'src/app/@services/storage.service';
import { orderDB } from '../../../shared/tables/order-list';
@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.scss'],
})
export class OrdersComponent implements OnInit {
  public order = [];
  public temp = [];
  loader: boolean = false;
  fromDate: string = '';
  toDate: string = '';
  searchText: string = '';
  orders: any;
  currentPage: any;
  perPage: any;
  totalPage: number;
  lastPage: any;
  orderSatatusFilter: string = '';
  paymentFilter: string = '';
  shippingTypeFilter: string = '';
  searchType: any = 'Invoice';
  interfaceFilter: string = '';

  isCollapsed: boolean = true;

  isClose: boolean;

  fullfillStatus: string = '';
  filterlist = [
    {
      value: 1,
      name: 'Unfulfilled',
    },
    {
      value: 2,
      name: 'Fullfiled',
    },
    {
      value: 3,
      name: 'Shipped',
    },
    {
      value: 4,
      name: 'Cancelled',
    },
    {
      value: 7,
      name: 'Failed Consignment',
    },
  ];

  paymentTypeFilter: string = '';
  paymentTypeFilterlist = [
    {
      value: 'bank',
      name: 'Bank',
    },
    {
      value: 'billplz',
      name: 'Billplz',
    },
    {
      value: 'credit',
      name: 'Credit',
    },
    {
      value: 'credit-card',
      name: 'Credit Card',
    },
  ];

  shippingTypeFilterlist: any;
  showFilterByOutlet : boolean = false;

  formFilter: FormGroup;

  @ViewChild(DatatableComponent, { static: false }) table: DatatableComponent;
  constructor(
    private api: ApiService,
    private router: Router,
    public formBuilder: FormBuilder,
    public datepipe: DatePipe,
    private storage: StorageService
  ) {
    this.order = orderDB.list_order;
  }

  updateFilter(event) {
    const val = event.target.value.toLowerCase();

    // filter our data
    const temp = this.temp.filter(function (d) {
      return d.name.toLowerCase().indexOf(val) !== -1 || !val;
    });

    // update the rows
    this.order = temp;
    // Whenever the filter changes, always go back to the first page
    this.table.offset = 0;
  }

  ngOnInit() {

    this.checkSuperAdmin();
    this.formFilter = this.formBuilder.group({
      fromDate: [''],
      toDate: [''],
      orderStatus: [''],
      shippingType: [''],
      paymentType: [''],
    });
    this.api
      .get(this.api.systemApi + 'shipping-type')
      .subscribe((res: any) => {
        // this.api.get(this.api.systemApi + "shipping-type-all").subscribe((res:any) => {
        this.shippingTypeFilterlist = res;
      });
    
    this.getOutlets();
    this.getOrder();
  }

  checkSuperAdmin(){

    // check warehouse id, if superadmin make default display HQ
    let warehouse = this.storage.get('auth.warehouse');
    if(warehouse.id==1) {
      this.warehouseId = 5;// warehouse.id; // #change-id
    } else { 
    this.warehouseId = warehouse.id;
    } 

    // check superadmin
    const user = this.storage.get('auth');
    if(user.user.email=='shamsul@iwhost.com' || user.user.email=='obsess2you@gmail.com') { this.showFilterByOutlet = true; }
    if(user.warehouse.id==1 && (user.user.email!='shamsul@iwhost.com' && user.user.email!='obsess2you@gmail.com')) { this.orders = this.orders.filter(w => w.warehouse_id == user.warehouse.id);}
    
  }


  warehouseId: number | undefined;
  get apiUrl() {
    return (
      this.api.orderApi +
      'admin-orders?page=' +
      1 +
      '&search=' +
      this.searchText +
      '&status=' +
      this.orderSatatusFilter +
      '&pstatus=' +
      this.paymentFilter +
      '&ptype=' +
      this.paymentTypeFilter +
      '&from=' +
      this.fromDate +
      '&to=' +
      this.toDate +
      '&stype=' +
      encodeURIComponent(this.shippingTypeFilter) +
      '&search_type=' +
      this.searchType +
      '&interface=' +
      this.interfaceFilter +
      '&wid=' +
      this.warehouseId
    );
  }

  selectedOutletData : any = null;
  outlets : any ;
  getOutlets() {
    this.api.get(this.api.warehouseApi + "warehouse").subscribe((res: any) => {
      this.outlets = res.data.filter(i=> i.name != "Warehouse" && i.status == 1);
      // console.log(this.outlets)
    });
  }

  selectedWarehouse(e : any){
    console.log(e)
    // debugger
    const selectedOutlet = this.outlets.filter(outlet=> outlet.id == e);
    // console.log(selectedOutlet[0]);
    this.selectedOutletData = selectedOutlet[0];
    console.log(this.selectedOutletData);

    this.warehouseId = this.selectedOutletData.id;
  }

  getOrder() {
    console.log(this.selectedOutletData);

    this.loader = true;
    if (this.fromDate === null) {
      this.fromDate = '';
    }
    if (this.toDate === null) {
      this.toDate = '';
    }
    this.api.get(this.apiUrl).subscribe((res: any) => {

      this.orders = res.data;
      if(this.selectedOutletData!=null) {
        const filteredData = res.data.filter((w: any) => w.warehouse_id == this.selectedOutletData.id);
        this.orders = filteredData;
      }

      
      this.currentPage = res.current_page;
      this.totalPage = res.last_page;
      this.perPage = res.per_page;
      this.lastPage = res.last_page;

      // this.firstPageChanged(1, 1);
      this.loader = false;
    });
  }
  search(searchKey) {
    if (searchKey.length >= 3) {
      this.getOrder();
    } else {
      this.getOrder();
    }
  }

  pageChanged(event: any) {
    console.log(event);
    this.currentPage = event.cp;
    if (event == '') return;
    this.orders = event;

    // for admin hq 
    const user = this.storage.get('auth');
    if(user.user.email!='shamsul@iwhost.com' && user.user.email!='obsess2you@gmail.com') { this.orders = this.orders.filter(w => w.warehouse_id == user.warehouse.id); }

    window.scrollBy(0, -1990);
  }
  viewOrderDetails(orderId) {
    this.router.navigate(['sales/order-details', orderId]);
  }
  collapsed() {
    this.isCollapsed = !this.isCollapsed;
  }

  submitFilter() {
    
    this.isClose = false;
    this.orderSatatusFilter = this.formFilter.value.orderStatus;

    this.shippingTypeFilter = this.formFilter.value.shippingType;

    this.paymentTypeFilter = this.formFilter.value.paymentType;

    // this.sortOptionSelect = this.sortOptionSelectCtr.value && this.sortOptionSelectCtr.value.toString();
    // if(this.sortOptionSelect === null ){
    //   this.sortOptionSelect = '';
    // }
    //
    var fromDate = this.formFilter.value.fromDate;
    var toDate = this.formFilter.value.toDate;
    if (fromDate && toDate) {
    // from
    if (fromDate.month.toString().length < 2) {
      fromDate.month = '0' + fromDate.month;
    }
    if (fromDate.day.toString().length < 2) {
      fromDate.day = '0' + fromDate.day;
    }

    // to
    if (toDate.month.toString().length < 2) {
      toDate.month = '0' + toDate.month;
    }
    if (toDate.day.toString().length < 2) {
      toDate.day = '0' + toDate.day;
    }

    this.fromDate = fromDate.year + '-' + fromDate.month + '-' + fromDate.day;
    this.toDate = toDate.year + '-' + toDate.month + '-' + toDate.day;
  }
    this.getOrder();
    this.isCollapsed = false;
  }
}
