import { Component, OnInit } from '@angular/core';
import { Router  } from '@angular/router';
import { ApiService } from 'src/app/@services/api.service';
import { ExcelService } from 'src/app/@services/excel.service';
import Swal from 'sweetalert2';
import { FormBuilder, FormGroup } from '@angular/forms';
import * as moment from 'moment';

@Component({
  selector: 'app-points',
  templateUrl: './points.component.html',
  styleUrls: ['./points.component.scss']
})
export class PointsComponent implements OnInit {

  points: any = [];
  apiUrl: any ='';
  searchText: string = '';

  totalPage: number;
  lastPage: any;
  currentPage: any;

  loader: boolean = true;
  isCollapsed: boolean = true;
  formFilter: FormGroup;
  pointsExport: any;
  
  fromDate = moment().format('YYYY-MM-DD, HH:mm');
  toDate = moment().format('YYYY-MM-DD, HH:mm');

  constructor(
    private api: ApiService,
    private excelService: ExcelService,
    public formBuilder: FormBuilder,
    private router: Router) { }

  ngOnInit(): void {

    this.formFilter = this.formBuilder.group({
      fromDate: [''],
      toDate: [''],
    });

    this.getPoints();
    
  }

  getPoints() {
    this.apiUrl = this.api.orderApi +
    'point-list?page=1' +
    '&search=' +
    this.searchText +
    '&email=' +
    this.searchText +
    '&sort=' +
    'DESC';
    
    this.api.get(this.apiUrl).subscribe((res: any) => {
      // debugger
      // console.clear();
      console.log(res.data.data);
      this.points = res.data.data;
      this.lastPage = res.data.last_page;
      this.currentPage = res.data.current_page;
      this.totalPage = res.data.last_page;
      // this.firstPageChanged(1, 1);
    });
  }

  pageChanged(event: any) {
    console.log(event);
    if (event == '') return;
    this.points = event.data.data;
    window.scrollBy(0, -1990);
  }

  searchByEmail(searchKey: any) {
    if (searchKey.length >= 3) {
      this.searchText = searchKey;
      this.getPoints();
    } else {
      this.getPoints();
    }
  }

  exportAsXLSX(): void {
    this.loader = true;
    // if (this.userRole === 'Sales') {
    //   var urlApi = this.api.orderApi + 'export-credit-list' + this.userId + '?search=' + this.searchText;
    // } else {
    var urlApi =
      this.api.orderApi + 'export-credit-list/0?search=' + this.searchText;
    // }
    this.api.get(urlApi).subscribe(
      (res: any) => {
        this.pointsExport = res;
        console.log(res);

        var exportOrder = [];
        this.pointsExport?.forEach((e) => {
          var expo = [];
          expo['First name'] = e?.details?.first_name;
          expo['Last name'] = e?.details?.last_name;
          expo['Email'] = e?.user?.email;
          expo['Current amount'] = e?.current_amount;
          expo['Accumulative amount'] = e?.accumulative_amount;
          expo['Accumulative Principal Amount'] = e?.principal_amount;
          expo['Accumulative Bonus Amount'] = e?.bonus_amount;
          expo['Updated at'] = e?.updated_at;
          exportOrder.push(expo);
        });
        this.excelService.exportAsExcelFile(exportOrder, 'Point Report');
        this.loader = false;
      },
      (error) => {
        // this.loader = false
        console.log(error);
        Swal.fire('Error', 'File too large');
      }
    );
  }

  submitFilter() {
    var fromDate = this.formFilter.value.fromDate;
    var toDate = this.formFilter.value.toDate;

    // from
    if (fromDate.month.toString().length < 2) {
      fromDate.month = '0' + fromDate.month;
    }
    if (fromDate.day.toString().length < 2) {
      fromDate.day = '0' + fromDate.day;
    }

    // to
    if (toDate.month.toString().length < 2) {
      toDate.month = '0' + toDate.month;
    }
    if (toDate.day.toString().length < 2) {
      toDate.day = '0' + toDate.day;
    }

    this.fromDate = fromDate.year + '-' + fromDate.month + '-' + fromDate.day;
    this.toDate = toDate.year + '-' + toDate.month + '-' + toDate.day;

    this.getPoints();
  }

  showDetails(data: any) {

    this.router.navigate(['points/point-transaction',data.user_id]);
  }
  
  addPoint(email, id) {
    Swal.fire({
      title: 'Add Point',
      html:
        '<label for="amount">Amount:</label>' +
        '<input type="number" id="amount" class="swal2-input" name="amount"><br>' +
        '<label for="note">Note:&nbsp;&nbsp;&nbsp;&nbsp;</label>' +
        '<input width="100%" id="note" class="swal2-input" name="note">',
      showCancelButton: true,
      confirmButtonText: 'Submit',
      showLoaderOnConfirm: true,
    }).then((result) => {
      if (result.isConfirmed) {
        var amount = (<HTMLInputElement>document.getElementById('amount'))
          .value;
        var note = (<HTMLInputElement>document.getElementById('note')).value;
        var data = { id:id, value: amount, description: note, type:"1" };

        // console.log(data); return;
        this.api
          .create(this.api.orderApi + 'update-obsess-point', data)
          .subscribe((res: any) => {
            if (res.status == 'ok') {
              Swal.fire({
                position: 'center',
                icon: 'success',
                title: 'Point Added',
                showConfirmButton: false,
                timer: 1500,
              });
              this.getPoints();
            }
          });
      }
    });
  }

  deductPoint(email, id) {
    Swal.fire({
      title: 'Deduct Point',
      html:
        '<label for="amount">Amount:</label>' +
        '<input type="number" id="amount" class="swal2-input" name="amount"><br>' +
        '<label for="note">Note:&nbsp;&nbsp;&nbsp;&nbsp;</label>' +
        '<input width="100%" id="note" class="swal2-input" name="note">',
      showCancelButton: true,
      confirmButtonText: 'Submit',
      showLoaderOnConfirm: true,
    }).then((result) => {
      if (result.isConfirmed) {
        var amount = (<HTMLInputElement>document.getElementById('amount'))
          .value;
        var note = (<HTMLInputElement>document.getElementById('note')).value;
        var data = { id:id, value: amount, description: note, type:2 };

        this.api
          .create(this.api.orderApi + 'update-obsess-point', data)
          .subscribe((res: any) => {
            if (res.status == 'ok') {

              if(res.message=='Successful updated.'){ 
                Swal.fire({
                  position: 'center',
                  icon: 'success',
                  title: 'Point Deducted',
                  showConfirmButton: false,
                  timer: 1500,
                });
              } else {
                Swal.fire({
                  position: 'center',
                  icon: 'error',
                  title: res.message,
                  showConfirmButton: false,
                  timer: 1500,
                });
              }

              this.getPoints();
            }
          });
      }
    });
  }


}
