import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from 'src/app/@services/api.service';
import { SweetalertService } from 'src/app/@services/fireSwal';

@Component({
  selector: 'app-picker-edit-password',
  templateUrl: './picker-edit-password.component.html',
  // styleUrls: ['./add-banner-dialog.component.scss']
})
export class PickerEditPasswordComponent implements OnInit {
  @Input() data: any = {};
  form: FormGroup;

  constructor(
    public activeModal: NgbActiveModal,
    private api: ApiService,
    private formBuilder: FormBuilder,
    private sweetalertService: SweetalertService

  ) { }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      id: [this.data],
      password: ['', Validators.required],
    })

  }
  action() {
    if (!this.form.valid) return;
      this.edit();
  }
edit(){
  this.api.create(this.api.userApi + "change-password-admin", this.form.value).subscribe((res: any) => {
    if (res.status == "ok") {
      this.sweetalertService.fireSwal('success', 'Password updated');
      this.activeModal.close()
    }
  })
}

close(){
  this.activeModal.dismiss('close')
}
}
