    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-12">
          <div class="card">
            <div class="card-header ">
              <div class="row">
                <div class="col-sm-9">
                  <h5>GENEALOGY </h5>
                </div>
              </div>
            </div>
            <div class="card-body">
              <div class="btn-popup pull-right ml-2">
              </div>
              <div id="batchDelete" class="custom-datatable">
              <button type="button" class="btn btn-primary" style="margin-bottom: 10px;" (click)="goBack">Back</button>
                <div class="p-1">
                  <div fxLayout="row" class="py-1" align="center">
                    <div class="text-left p-2">
                      <div id="searchBy">
                        <form id="search">
                          <input #search type="search" class="search-box" placeholder="Search by Name & Phone Number"
                            [ngClass]="{'filled':filled}" (click)="filled=true" (blur)="filled=search.value?true:false"
                            (keyup.enter)="searchDS(searchText,  $event)" (keyup)="searchDS(searchText, $event)"
                            [(ngModel)]="searchText" name="search">
                        </form>
                      </div>
                    </div>
                  </div>

                  <!-- <div align="center">

                    <div style="text-align: center;
                    width: fit-content;">
                      <div class="row center">
                        <div class="col-2">
                            <img
                            src="https://s3.ap-southeast-1.amazonaws.com/images.obsesscosmetics.net/assets/client/geneology/red.png"
                            class="dot" alt="">
                        </div>
                        <div class="col-10">
                            <h6>OBSESS BUSINESS PARTNER</h6>
                        </div>
                        </div>
                        &nbsp;
                        <div class="row">
                        <div class="col-2">
                            <img
                            src="https://s3.ap-southeast-1.amazonaws.com/images.obsesscosmetics.net/assets/client/geneology/green.png"
                            class="dot" alt="">
                        </div>
                        <div class="col-10">
                            <h6>OBSESS SILVER PARTNER</h6>
                        </div>
                    </div>
                    &nbsp;

                    <div class="row">

                        <div class="col-2">
                            <img
                            src="https://s3.ap-southeast-1.amazonaws.com/images.obsesscosmetics.net/assets/client/geneology/yellow2.png"
                            class="dot" alt="">
                        </div>
                        <div class="col-10">
                            <h6>OBSESS GOLD PARTNER</h6>
                        </div>
                        </div>
                        &nbsp;

                        <div class="row">

                        <div class="col-2">
                            <img
                            src="https://s3.ap-southeast-1.amazonaws.com/images.obsesscosmetics.net/assets/client/geneology/blue.png"
                            class="dot" alt="">
                        </div>
                        <div class="col-10">
                            <h6>OBSESS PLATINUM PARTNER</h6>
                        </div>
                        </div>
                    </div>

                  </div> -->
                  <div align="center">

                    <div style="text-align: center;
                    width: fit-content;">
                    <div class="row center">
                      <div class="col-2">
                          <img src="https://s3.ap-southeast-1.amazonaws.com/images.obsesscosmetics.net/assets/client/geneology/yellow.png" class="dot" alt="">
                      </div>
                      <div class="col-10 text">
                        <h6>Total Affiliate : {{totalAffiliate}}</h6>
                      </div>
                      </div>
                      &nbsp;
                
                      <div class="row center">
                        <div class="col-2">
                            <img src="https://s3.ap-southeast-1.amazonaws.com/images.obsesscosmetics.net/assets/client/geneology/red.png" class="dot" alt="">
                        </div>
                        <div class="col-10 text">
                          <h6>Affilate Lv1 : {{affiliateLv1}}</h6>
                            <!-- <h6>OBSESS BUSINESS PARTNER</h6> -->
                        </div>
                        </div>
                        &nbsp;
                        <div class="row">
                        <div class="col-2">
                            <img src="https://s3.ap-southeast-1.amazonaws.com/images.obsesscosmetics.net/assets/client/geneology/green.png" class="dot" alt="">
                        </div>
                        <div class="col-10 text">
                            <h6>Affilate Lv2 : {{affiliateLv2}}</h6>
                            <!-- <h6>OBSESS SILVER PARTNER</h6> -->
                        </div>
                    </div>
                    &nbsp;
                
                    <div class="row">
                
                        <div class="col-2">
                            <img src="https://s3.ap-southeast-1.amazonaws.com/images.obsesscosmetics.net/assets/client/geneology/yellow2.png" class="dot" alt="">
                        </div>
                        <div class="col-10 text">
                          <h6>Affilate Lv3 : {{affiliateLv3}}</h6>
                            <!-- <h6>OBSESS GOLD PARTNER</h6> -->
                        </div>
                        </div>
                        &nbsp;
                
                        <!-- <div class="row">
                
                        <div class="col-2">
                            <img src="https://s3.ap-southeast-1.amazonaws.com/images.obsesscosmetics.net/assets/client/geneology/blue.png" class="dot" alt="">
                        </div>
                        <div class="col-10 text">
                          <h6>Affilate Lv4 : {{affiliateLv4}}</h6>
                            <h6>OBSESS PLATINUM PARTNER</h6>
                        </div>
                        </div> -->
                    </div>
                
                  </div>
                  <div fxLayout="row">
                    <h4 *ngIf="headOfGeneology">{{headOfGeneology}}</h4>
                    <!-- <mat-icon style="margin-left: 15px;" (click)="previousGeneology()">reply
                    </mat-icon> -->
                    <app-feather-icons style="margin-left: 15px;" title="Back to top" *ngIf="previousUserID.length > 1" (click)="previousGeneology()" [icon]="'corner-up-left'"></app-feather-icons>

                  </div>
                  <ng-container *ngIf="horizontal">
                    <div [style]="scrollValue">
                    <div style="margin-left: 20px;" *ngFor="let user of users">
                      <span fxLayout="row">
                        <mat-divider vertical class="line"></mat-divider><br />
                      </span>
                      <div class="row">
                        <div class="col-11">
                        <h6 *ngIf="user.salutation === 'OBSESS BUSINESS PARTNER' && user.salutation" (click)="userChange(user)"
                          style="margin-top: -1px; cursor: pointer;" class="text-nowrap"><img *ngIf="user.salutation === 'OBSESS BUSINESS PARTNER' && horizontal"
                          src="https://s3.ap-southeast-1.amazonaws.com/images.obsesscosmetics.net/assets/client/geneology/red.png"
                          class="dsDot">&nbsp;&nbsp;&nbsp;&nbsp;<span style="color:#ac5765">ID-{{user.id}}</span> -
                          {{user.details?.first_name}} {{user.details?.last_name}} - {{user.details?.phone}} - ({{user.created_at |
                          date:'dd-MM-yyyy'}})</h6>
                        <h6 *ngIf="user.salutation === 'OBSESS SILVER PARTNER' && user.salutation" (click)="userChange(user)"
                          style="margin-top: -1px; cursor: pointer;" class="text-nowrap"><img *ngIf="user.salutation === 'OBSESS SILVER PARTNER' && horizontal"
                          src="https://s3.ap-southeast-1.amazonaws.com/images.obsesscosmetics.net/assets/client/geneology/green.png"
                          class="pDot">&nbsp;&nbsp;&nbsp;&nbsp;<span style="color:#32e379">ID-{{user.id}}</span> -
                          {{user.details?.first_name}} {{user.details?.last_name}} - {{user.details?.phone}} - ({{user.created_at |
                          date:'dd-MM-yyyy'}})</h6>
                        <h6 *ngIf="user.salutation === 'OBSESS GOLD PARTNER' && user.salutation" (click)="userChange(user)"
                          style="margin-top: -1px; cursor: pointer;" class="text-nowrap"><img *ngIf="user.salutation === 'OBSESS GOLD PARTNER' && horizontal"
                          src="https://s3.ap-southeast-1.amazonaws.com/images.obsesscosmetics.net/assets/client/geneology/yellow2.png"
                          class="ppDot">&nbsp;&nbsp;&nbsp;&nbsp;<span style="color:#e3c732">ID-{{user.id}}</span> -
                          {{user.details?.first_name}} {{user.details?.last_name}} - {{user.details?.phone}} - ({{user.created_at |
                          date:'dd-MM-yyyy'}})</h6>
                          <h6 *ngIf="user.salutation === 'OBSESS PLATINUM PARTNER' && user.salutation" (click)="userChange(user)"
                          style="margin-top: -1px; cursor: pointer;" class="text-nowrap"> <img *ngIf="user.salutation === 'OBSESS PLATINUM PARTNER' && horizontal"
                          src="https://s3.ap-southeast-1.amazonaws.com/images.obsesscosmetics.net/assets/client/geneology/blue.png"
                          class="ppDot">&nbsp;&nbsp;&nbsp;&nbsp;<span style="color:#55b3ca">ID-{{user.id}}</span> -
                          {{user.details?.first_name}} {{user.details?.last_name}} - {{user.details?.phone}} - ({{user.created_at |
                          date:'dd-MM-yyyy'}})</h6>
                        </div>
                      </div>
                    </div>
                </div>
                  </ng-container>
                  &nbsp;
                  <h6 *ngIf="users.length < 1">No More Downline</h6>
                <div class="btn-popup pull-right" *ngIf="lastPage > 1 && users?.length > 0">
                  <pagination-with-api [apiUrl]="apiUrl" [totalPage]="totalPage" (valueChanged)="pageChanged($event)">
                  </pagination-with-api>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
