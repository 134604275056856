<div class="printarea">
    <!-- <div class="page-header" style="text-align: center">
        <img src="../../../assets/OBSESS LOGO.png" style="width: 10vw;">
    </div> -->

    <div class="page-footer">
        <!-- <img src="/assets/footer.png"> -->

    </div>

    <table width="100%">

        <thead>
            <tr>
                <td>
                    <!--place holder for the fixed-position header-->
                    <div class="page-header-space"></div>
                </td>
            </tr>
        </thead>

        <tbody>
            <tr style="margin-top: 5px;">
                <td>
                    <div [innerHTML]="html"></div>
                    <!-- <div style="position: absolute; bottom: 10px; right: 0px; font-size: 11px">Printing Time: {{time | date: 'short'}}</div> -->
                </td>
            </tr>
        </tbody>

        <tfoot>
            <tr>
                <td>
                    <div class="page-footer-space"></div>
                </td>
            </tr>
        </tfoot>

    </table>
</div>