import { AccessService } from './../../../@services/access.service';
import { Location } from "@angular/common";
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from 'src/app/@services/api.service';
import Swal from "sweetalert2";
import { ManualConsignmentDialogComponent } from '../manual-consignment-dialog/manual-consignment-dialog.component';
import { OrderCancelDialogComponent } from '../order-cancel-dialog/order-cancel-dialog.component';
import { OrderOnHoldComponent } from '../order-on-hold/order-on-hold.component';
import { ChangeShippingAddressDialogComponent } from './../change-shipping-address/change-shipping-address-dialog.component';
import { StorageService } from 'src/app/@services/storage.service';
import { UserService } from './../../../@services/user.service';

@Component({
  selector: 'app-order-details',
  templateUrl: './order-details.component.html',
  styleUrls: ['./order-details.component.scss']
})
export class OrderDetailsComponent implements OnInit {
  order: any;
  orderId:number;
  pdfLink : any;
  customerNoteModel = {
    id: "",
    order_note: "",
  };

  userDetails: any;
  showCancelButtown: boolean = true;


  constructor(
    private api: ApiService,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private _location: Location,
    private accessService : AccessService,
    private storageService : StorageService,
    public user: UserService
  ) { }

    isViewerOnly : boolean = false;
    ngOnInit(): void {
    this.userDetails = this.user.getUserFromLocalStorage();
    // console.log(this.userDetails.role.role);
    this.isViewerOnly = this.accessService.isViewerCheck();
    this.orderId = this.route.snapshot.params.id;
    if (this.orderId) {
      this.getOrderDetails(this.orderId);
    }
  }

  postSystem: boolean = false;
  userRoleIsTiga: boolean = false;

  getOrderDetails(orderId) {
    this.api
      .get(this.api.orderApi + `orders/${orderId}`)
      .subscribe((res: any) => {
        this.order = res;
        if(res.user.user_role){ 
        this.userRoleIsTiga = true;
        } else { this.userRoleIsTiga = false; }
        if(res.pos_system==1){ 
          this.postSystem = true; 
          console.log(res.warehouse_id);
          this.getWhData(res.warehouse_id);
        } else { this.postSystem = false; }
        this.pdfLink = res.awb_image;
      });
  }
  wh: any = null;
  getWhData(wid:any){
    console.log(wid);
    this.api.get(this.api.warehouseApi + "warehouse").subscribe((res: any) => {
      let outlets = res.data.filter(i=> i.name != "Warehouse" && i.status == 1);
      // console.log(outlets);
      this.wh = outlets.find((i) => i.id == wid);
      // console.log(this.wh);
    });
  }
  sendInvoice() {
    this.api
      .get(this.api.orderApi + `send-invoice/${this.orderId}`)
      .subscribe((res: any) => {
        Swal.fire("Invoice has been send!");
      });
  }
  cancelOrder(orderId) {
    this.showCancelButtown = false;
    const modalRef = this.modalService.open(OrderCancelDialogComponent, { ariaLabelledBy: 'modal-basic-title', backdrop:true });
    (<OrderCancelDialogComponent>modalRef.componentInstance).id = orderId;
    modalRef.result.then((result) => {
    }).catch( (result) => {
      if((this.order?.order_status == 0 || this.order?.order_status== 1)) { this.showCancelButtown = true;}
    });
  }
  onholdOrder(order) {
    const modalRef = this.modalService.open(OrderOnHoldComponent, { ariaLabelledBy: 'modal-basic-title', backdrop:true });
    (<OrderOnHoldComponent>modalRef.componentInstance).order = order;
    modalRef.result.then((result) => {
    }).catch( (result) => {
      this.getOrderDetails(this.orderId);
    });
  }
  unholdOrder(orderId) {
    var model = {
    id: orderId,
    remarks: "Unhold",
    status: 1,
    onhold_status: 0,
  }
    Swal.fire({
      title: "Are you sure, you want to unhold this order?",
      text: "",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes!",
    }).then((result) => {
      if (result.isConfirmed) {
        this.api
          .create(this.api.orderApi + "onhold-order", model)
          .subscribe((res: any) => {
            Swal.fire("Unhold!", "Order has been unhold.", "success");
            this.getOrderDetails(this.orderId);
          });
        // this.getOrderDetails(this.orderId);
      }
    });
  }

  confirmOrder(orderId) {
    var model = {
    id: orderId,
    remarks: "Unhold",
    status: 1,
    onhold_status: 0,
  }
    Swal.fire({
      title: "Are you sure, you want to confirm this order?",
      text: "",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes!",
    }).then((result) => {
      if (result.isConfirmed) {
        this.api
          .create(this.api.orderApi + "onhold-order", model)
          .subscribe((res: any) => {
            Swal.fire("Confirm!", "This AWB Order has been confirmed.", "success");
            this.getOrderDetails(this.orderId);
          });
      }
    });
  }
  manualConsignmentDialog(order) {
    const modalRef = this.modalService.open(ManualConsignmentDialogComponent, { ariaLabelledBy: 'modal-basic-title', backdrop:true });
    (<ManualConsignmentDialogComponent>modalRef.componentInstance).order = order;
    modalRef.result.then((result) => {
      this.getOrderDetails(order.id);
    }).catch( (result) => {
    });
  }
  editShippingAddess(address, orderId) {
    const modalRef = this.modalService.open(ChangeShippingAddressDialogComponent, { ariaLabelledBy: 'modal-basic-title', backdrop:true });
    (<ChangeShippingAddressDialogComponent>modalRef.componentInstance).address = address;
    modalRef.result.then((result) => {
      this.getOrderDetails(orderId);
    }).catch( (result) => {
    });
  }

  updateCustomerNoteMode: boolean = false;
  editCustomerNote() {
    this.updateCustomerNoteMode = !this.updateCustomerNoteMode;
  }
  updateCustomerNote(id) {
    this.customerNoteModel.id = id;
    this.api
      .create(
        this.api.orderApi + "update-customer-note",
        this.customerNoteModel
      )
      .subscribe((res) => {
        this.updateCustomerNoteMode = !this.updateCustomerNoteMode;
        this.getOrderDetails(id);
      });
  }
  get goBack() {
    return this._location.back();
  }

  awbPDF(){
    window.open(this.pdfLink);
  }
}
