import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'src/app/@guards/auth.guard';
import { CustomerDashboardComponent } from './customer-dashboard/customer-dashboard.component';
import { DropshipDashboardComponent } from './dropship-dashboard/dropship-dashboard.component';


const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'dropship-dashboard',
        component: DropshipDashboardComponent,
        data: {
          title: "Dashboard",
          breadcrumb: "Dashboard"
        },
        canActivate: [AuthGuard]
      },
      {
        path: 'customer-dashboard',
        component: CustomerDashboardComponent,
        data: {
          title: "Dashboard",
          breadcrumb: "Dashboard"
        },
        canActivate: [AuthGuard]
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DashboardRoutingModule { }
