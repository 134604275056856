<!-- Container-fluid starts-->
<div class="container-fluid">
  <div class="modal-header">
      <h5 class="modal-title f-w-600" id="exampleModalLabel">EDIT PASSWORD</h5>
      <button type="button" class="close" aria-label="Close" (click)="close()">
          <span aria-hidden="true">&times;</span>
      </button>
  </div>
  <div class="modal-body">
      <form [formGroup]="form"  class="needs-validation">
          <div class="form">
              <div class="form-group row">
                  <label for="validationCustom3" class="col-xl-3 col-md-4"><span>*</span>
                      Password</label>
                  <input formControlName="password" class="form-control col-xl-8 col-md-7" id="validationCustom3" type="password"
                      required="">
              </div>
          </div>
      </form>
  </div>
  <div class="modal-footer">
      <button type="button" class="btn btn-primary" (click)="action()">Save</button>
      <button class="btn btn-secondary" type="button" (click)="close()" data-dismiss="modal">Close</button>
  </div>
</div>

<!-- Container-fluid Ends-->
