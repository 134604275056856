import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from 'src/app/@services/api.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-order-on-hold',
  templateUrl: './order-on-hold.component.html',
  styleUrls: ['./order-on-hold.component.scss']
})
export class OrderOnHoldComponent implements OnInit {
  @Input() order: any = {};
  selectedProducts:any =[]
  remarks:any;
  products:any

  constructor(
    private api: ApiService,
    public activeModal: NgbActiveModal,
  ) { }

  ngOnInit(): void {
    this.products = this.order.order_products
    console.log(this.order);

  }
  selectProduct(product){
    var isExist = this.selectedProducts.find(i=> i.id == product.id)
    if(!isExist){
      this.selectedProducts.push(product)
    }else{
      this.selectedProducts.in
        var index = this.selectedProducts.findIndex(i=> i.id == product.id);
          this.selectedProducts.splice(index, 1);
    }
    console.log(this.selectedProducts);

  }
  onhold(){

    var selectedProducts = JSON.stringify(this.selectedProducts)
    var model = {
      id: this.order.id,
      invoice: this.order.invoice,
      order_products: selectedProducts,
      remarks: this.remarks,
      status: 6,
      onhold_status: 1
    }
    Swal.fire({
      title: 'Are you sure, you want to hold this order?',
      text: "",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes!'
    }).then((result) => {
      if (result.isConfirmed) {
        this.api.create(this.api.orderApi + "onhold-order", model).subscribe((res:any) => {
          if(res.status === "ok"){
            Swal.fire(
              // 'On-Hold!',
              'Order has been hold.',
              'success'
            );
          }else{
            Swal.fire(
              // 'On-Hold!',
              res.message,
              'error'
            )
          }
         }
        )
      }
    })
  }
  close() {
    this.activeModal.dismiss('close');
  }
}
