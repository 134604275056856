import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { JwtHelperService } from '@auth0/angular-jwt';
import { ApiService } from './api.service';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: "root",
})
export class AuthService {
  jwtHelper = new JwtHelperService();
  token: string = null
  constructor(private api: ApiService, private storage: StorageService, private router: Router) { }

  register(type, value) {
    const user = value;
    return new Promise<any>((resolve, reject) => {
      this.api.post("/" + type + "/register", user).subscribe(
        (res) => {
          resolve(res);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  login(username: string, password: string) {
    const user = {
      email: username, password: password
    };
    return new Promise<any>((resolve, reject) => {
      this.api.post(this.api.userApi + "auth/login", user).subscribe(
        (res) => {
          resolve(res);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  // registerAdmin(value) {
  //   const user = value;
  //   return new Promise<any>((resolve, reject) => {
  //     this.api.post("/admin/register", user).subscribe(
  //       (res) => {
  //         resolve(res);
  //       },
  //       (error) => {
  //         reject(error);
  //       }
  //     );
  //   });
  // }




  loggedIn() {
    // debugger;
    const token = this.storage.get("auth.accesstoken");
    if (token) {
      // return true;
      return !this.jwtHelper.isTokenExpired(token);
    }
    return false;
  }

  // getToken() {
  //   if (this.token == null) {
  //     const token = this.storage.get("auth.accesstoken");
  //     this.token = token;

  //   }
  //   return this.token
  // }

  logout() {
    localStorage.clear();
    this.router.navigate(["/login"]);
  }
}
