<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card tab2-card">
                <div class="card-header">
                    <h5> Add User</h5>
                </div>
                <div class="card-body">
                    <form [formGroup]="accountForm" class="needs-validation user-add" novalida>
                        <h4>Account Details</h4>
                        <div class="form-group row">
                            <label for="validationCustom0" class="col-xl-3 col-md-4"><span>*</span>
                                Name</label>
                            <input formControlName="user_name" class="form-control col-xl-8 col-md-7" id="validationCustom0" type="text"
                                required="">
                        </div>
                        <div class="form-group row">
                            <label for="validationCustom2" class="col-xl-3 col-md-4"><span>*</span>
                                Email</label>
                            <input formControlName="email" class="form-control col-xl-8 col-md-7" id="validationCustom2" type="text"
                                required="">
                        </div>
                        <div class="form-group row">
                            <label for="validationCustom3" class="col-xl-3 col-md-4"><span>*</span>
                                Password</label>
                            <input formControlName="password" class="form-control col-xl-8 col-md-7" id="validationCustom3" type="password"
                                required="">
                        </div>
                    </form>
                    <div class="pull-right">
                        <button type="button" class="btn btn-primary" (click)="save()">Save</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Container-fluid Ends-->