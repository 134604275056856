import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

export class Data {
  constructor(

  ) {}
}

@Injectable()
export class AppService {
 
  public url = "assets/data/";
  constructor(public http: HttpClient, 
    ) {}

  // public getCategories(): Observable<Category[]>{
  //     return this.http.get<Category[]>(this.url + 'categories.json');
  // }


  public getBrands() {
    return [
      { name: "aloha", image: "assets/images/brands/aloha.png" },
      { name: "dream", image: "assets/images/brands/dream.png" },
      { name: "congrats", image: "assets/images/brands/congrats.png" },
      { name: "best", image: "assets/images/brands/best.png" },
      { name: "original", image: "assets/images/brands/original.png" },
      { name: "retro", image: "assets/images/brands/retro.png" },
      { name: "king", image: "assets/images/brands/king.png" },
      { name: "love", image: "assets/images/brands/love.png" },
      { name: "the", image: "assets/images/brands/the.png" },
      { name: "easter", image: "assets/images/brands/easter.png" },
      { name: "with", image: "assets/images/brands/with.png" },
      { name: "special", image: "assets/images/brands/special.png" },
      { name: "bravo", image: "assets/images/brands/bravo.png" },
    ];
  }

  public getCountries() {
    return [
      // {name: 'Afghanistan', code: 'AF'},
      // {name: 'Aland Islands', code: 'AX'},
      // {name: 'Albania', code: 'AL'},
      // {name: 'Algeria', code: 'DZ'},
      // {name: 'American Samoa', code: 'AS'},
      // {name: 'AndorrA', code: 'AD'},
      // {name: 'Angola', code: 'AO'},
      // {name: 'Anguilla', code: 'AI'},
      // {name: 'Antarctica', code: 'AQ'},
      // {name: 'Antigua and Barbuda', code: 'AG'},
      // {name: 'Argentina', code: 'AR'},
      // {name: 'Armenia', code: 'AM'},
      // {name: 'Aruba', code: 'AW'},
      // {name: 'Australia', code: 'AU'},
      // {name: 'Austria', code: 'AT'},
      // {name: 'Azerbaijan', code: 'AZ'},
      // {name: 'Bahamas', code: 'BS'},
      // {name: 'Bahrain', code: 'BH'},
      // {name: 'Bangladesh', code: 'BD'},
      // {name: 'Barbados', code: 'BB'},
      // {name: 'Belarus', code: 'BY'},
      // {name: 'Belgium', code: 'BE'},
      // {name: 'Belize', code: 'BZ'},
      // {name: 'Benin', code: 'BJ'},
      // {name: 'Bermuda', code: 'BM'},
      // {name: 'Bhutan', code: 'BT'},
      // {name: 'Bolivia', code: 'BO'},
      // {name: 'Bosnia and Herzegovina', code: 'BA'},
      // {name: 'Botswana', code: 'BW'},
      // {name: 'Bouvet Island', code: 'BV'},
      // {name: 'Brazil', code: 'BR'},
      // {name: 'British Indian Ocean Territory', code: 'IO'},
      // {name: 'Brunei Darussalam', code: 'BN'},
      // {name: 'Bulgaria', code: 'BG'},
      // {name: 'Burkina Faso', code: 'BF'},
      // {name: 'Burundi', code: 'BI'},
      // {name: 'Cambodia', code: 'KH'},
      // {name: 'Cameroon', code: 'CM'},
      // {name: 'Canada', code: 'CA'},
      // {name: 'Cape Verde', code: 'CV'},
      // {name: 'Cayman Islands', code: 'KY'},
      // {name: 'Central African Republic', code: 'CF'},
      // {name: 'Chad', code: 'TD'},
      // {name: 'Chile', code: 'CL'},
      // {name: 'China', code: 'CN'},
      // {name: 'Christmas Island', code: 'CX'},
      // {name: 'Cocos (Keeling) Islands', code: 'CC'},
      // {name: 'Colombia', code: 'CO'},
      // {name: 'Comoros', code: 'KM'},
      // {name: 'Congo', code: 'CG'},
      // {name: 'Congo, The Democratic Republic of the', code: 'CD'},
      // {name: 'Cook Islands', code: 'CK'},
      // {name: 'Costa Rica', code: 'CR'},
      // {name: 'Cote D\'Ivoire', code: 'CI'},
      // {name: 'Croatia', code: 'HR'},
      // {name: 'Cuba', code: 'CU'},
      // {name: 'Cyprus', code: 'CY'},
      // {name: 'Czech Republic', code: 'CZ'},
      // {name: 'Denmark', code: 'DK'},
      // {name: 'Djibouti', code: 'DJ'},
      // {name: 'Dominica', code: 'DM'},
      // {name: 'Dominican Republic', code: 'DO'},
      // {name: 'Ecuador', code: 'EC'},
      // {name: 'Egypt', code: 'EG'},
      // {name: 'El Salvador', code: 'SV'},
      // {name: 'Equatorial Guinea', code: 'GQ'},
      // {name: 'Eritrea', code: 'ER'},
      // {name: 'Estonia', code: 'EE'},
      // {name: 'Ethiopia', code: 'ET'},
      // {name: 'Falkland Islands (Malvinas)', code: 'FK'},
      // {name: 'Faroe Islands', code: 'FO'},
      // {name: 'Fiji', code: 'FJ'},
      // {name: 'Finland', code: 'FI'},
      // {name: 'France', code: 'FR'},
      // {name: 'French Guiana', code: 'GF'},
      // {name: 'French Polynesia', code: 'PF'},
      // {name: 'French Southern Territories', code: 'TF'},
      // {name: 'Gabon', code: 'GA'},
      // {name: 'Gambia', code: 'GM'},
      // {name: 'Georgia', code: 'GE'},
      // {name: 'Germany', code: 'DE'},
      // {name: 'Ghana', code: 'GH'},
      // {name: 'Gibraltar', code: 'GI'},
      // {name: 'Greece', code: 'GR'},
      // {name: 'Greenland', code: 'GL'},
      // {name: 'Grenada', code: 'GD'},
      // {name: 'Guadeloupe', code: 'GP'},
      // {name: 'Guam', code: 'GU'},
      // {name: 'Guatemala', code: 'GT'},
      // {name: 'Guernsey', code: 'GG'},
      // {name: 'Guinea', code: 'GN'},
      // {name: 'Guinea-Bissau', code: 'GW'},
      // {name: 'Guyana', code: 'GY'},
      // {name: 'Haiti', code: 'HT'},
      // {name: 'Heard Island and Mcdonald Islands', code: 'HM'},
      // {name: 'Holy See (Vatican City State)', code: 'VA'},
      // {name: 'Honduras', code: 'HN'},
      // {name: 'Hong Kong', code: 'HK'},
      // {name: 'Hungary', code: 'HU'},
      // {name: 'Iceland', code: 'IS'},
      // {name: 'India', code: 'IN'},
      // {name: 'Indonesia', code: 'ID'},
      // {name: 'Iran, Islamic Republic Of', code: 'IR'},
      // {name: 'Iraq', code: 'IQ'},
      // {name: 'Ireland', code: 'IE'},
      // {name: 'Isle of Man', code: 'IM'},
      // {name: 'Israel', code: 'IL'},
      // {name: 'Italy', code: 'IT'},
      // {name: 'Jamaica', code: 'JM'},
      // {name: 'Japan', code: 'JP'},
      // {name: 'Jersey', code: 'JE'},
      // {name: 'Jordan', code: 'JO'},
      // {name: 'Kazakhstan', code: 'KZ'},
      // {name: 'Kenya', code: 'KE'},
      // {name: 'Kiribati', code: 'KI'},
      // {name: 'Korea, Democratic People\'S Republic of', code: 'KP'},
      // {name: 'Korea, Republic of', code: 'KR'},
      // {name: 'Kuwait', code: 'KW'},
      // {name: 'Kyrgyzstan', code: 'KG'},
      // {name: 'Lao People\'S Democratic Republic', code: 'LA'},
      // {name: 'Latvia', code: 'LV'},
      // {name: 'Lebanon', code: 'LB'},
      // {name: 'Lesotho', code: 'LS'},
      // {name: 'Liberia', code: 'LR'},
      // {name: 'Libyan Arab Jamahiriya', code: 'LY'},
      // {name: 'Liechtenstein', code: 'LI'},
      // {name: 'Lithuania', code: 'LT'},
      // {name: 'Luxembourg', code: 'LU'},
      // {name: 'Macao', code: 'MO'},
      // {name: 'Macedonia, The Former Yugoslav Republic of', code: 'MK'},
      // {name: 'Madagascar', code: 'MG'},
      // {name: 'Malawi', code: 'MW'},
      { name: "Malaysia" },
      // {name: 'Singapore'}, 
      // {name: 'Brunei'}, 
      // {name: 'Maldives', code: 'MV'},
      // {name: 'Mali', code: 'ML'},
      // {name: 'Malta', code: 'MT'},
      // {name: 'Marshall Islands', code: 'MH'},
      // {name: 'Martinique', code: 'MQ'},
      // {name: 'Mauritania', code: 'MR'},
      // {name: 'Mauritius', code: 'MU'},
      // {name: 'Mayotte', code: 'YT'},
      // {name: 'Mexico', code: 'MX'},
      // {name: 'Micronesia, Federated States of', code: 'FM'},
      // {name: 'Moldova, Republic of', code: 'MD'},
      // {name: 'Monaco', code: 'MC'},
      // {name: 'Mongolia', code: 'MN'},
      // {name: 'Montserrat', code: 'MS'},
      // {name: 'Morocco', code: 'MA'},
      // {name: 'Mozambique', code: 'MZ'},
      // {name: 'Myanmar', code: 'MM'},
      // {name: 'Namibia', code: 'NA'},
      // {name: 'Nauru', code: 'NR'},
      // {name: 'Nepal', code: 'NP'},
      // {name: 'Netherlands', code: 'NL'},
      // {name: 'Netherlands Antilles', code: 'AN'},
      // {name: 'New Caledonia', code: 'NC'},
      // {name: 'New Zealand', code: 'NZ'},
      // {name: 'Nicaragua', code: 'NI'},
      // {name: 'Niger', code: 'NE'},
      // {name: 'Nigeria', code: 'NG'},
      // {name: 'Niue', code: 'NU'},
      // {name: 'Norfolk Island', code: 'NF'},
      // {name: 'Northern Mariana Islands', code: 'MP'},
      // {name: 'Norway', code: 'NO'},
      // {name: 'Oman', code: 'OM'},
      // {name: 'Pakistan', code: 'PK'},
      // {name: 'Palau', code: 'PW'},
      // {name: 'Palestinian Territory, Occupied', code: 'PS'},
      // {name: 'Panama', code: 'PA'},
      // {name: 'Papua New Guinea', code: 'PG'},
      // {name: 'Paraguay', code: 'PY'},
      // {name: 'Peru', code: 'PE'},
      // {name: 'Philippines', code: 'PH'},
      // {name: 'Pitcairn', code: 'PN'},
      // {name: 'Poland', code: 'PL'},
      // {name: 'Portugal', code: 'PT'},
      // {name: 'Puerto Rico', code: 'PR'},
      // {name: 'Qatar', code: 'QA'},
      // {name: 'Reunion', code: 'RE'},
      // {name: 'Romania', code: 'RO'},
      // {name: 'Russian Federation', code: 'RU'},
      // {name: 'RWANDA', code: 'RW'},
      // {name: 'Saint Helena', code: 'SH'},
      // {name: 'Saint Kitts and Nevis', code: 'KN'},
      // {name: 'Saint Lucia', code: 'LC'},
      // {name: 'Saint Pierre and Miquelon', code: 'PM'},
      // {name: 'Saint Vincent and the Grenadines', code: 'VC'},
      // {name: 'Samoa', code: 'WS'},
      // {name: 'San Marino', code: 'SM'},
      // {name: 'Sao Tome and Principe', code: 'ST'},
      // {name: 'Saudi Arabia', code: 'SA'},
      // {name: 'Senegal', code: 'SN'},
      // {name: 'Serbia and Montenegro', code: 'CS'},
      // {name: 'Seychelles', code: 'SC'},
      // {name: 'Sierra Leone', code: 'SL'},
      // {name: 'Singapore'},
      // {name: 'Slovakia', code: 'SK'},
      // {name: 'Slovenia', code: 'SI'},
      // {name: 'Solomon Islands', code: 'SB'},
      // {name: 'Somalia', code: 'SO'},
      // {name: 'South Africa', code: 'ZA'},
      // {name: 'South Georgia and the South Sandwich Islands', code: 'GS'},
      // {name: 'Spain', code: 'ES'},
      // {name: 'Sri Lanka', code: 'LK'},
      // {name: 'Sudan', code: 'SD'},
      // {name: 'Suriname', code: 'SR'},
      // {name: 'Svalbard and Jan Mayen', code: 'SJ'},
      // {name: 'Swaziland', code: 'SZ'},
      // {name: 'Sweden', code: 'SE'},
      // {name: 'Switzerland', code: 'CH'},
      // {name: 'Syrian Arab Republic', code: 'SY'},
      // {name: 'Taiwan, Province of China', code: 'TW'},
      // {name: 'Tajikistan', code: 'TJ'},
      // {name: 'Tanzania, United Republic of', code: 'TZ'},
      // {name: 'Thailand', code: 'TH'},
      // {name: 'Timor-Leste', code: 'TL'},
      // {name: 'Togo', code: 'TG'},
      // {name: 'Tokelau', code: 'TK'},
      // {name: 'Tonga', code: 'TO'},
      // {name: 'Trinidad and Tobago', code: 'TT'},
      // {name: 'Tunisia', code: 'TN'},
      // {name: 'Turkey', code: 'TR'},
      // {name: 'Turkmenistan', code: 'TM'},
      // {name: 'Turks and Caicos Islands', code: 'TC'},
      // {name: 'Tuvalu', code: 'TV'},
      // {name: 'Uganda', code: 'UG'},
      // {name: 'Ukraine', code: 'UA'},
      // {name: 'United Arab Emirates', code: 'AE'},
      // {name: 'United Kingdom', code: 'GB'},
      // {name: 'United States', code: 'US'},
      // {name: 'United States Minor Outlying Islands', code: 'UM'},
      // {name: 'Uruguay', code: 'UY'},
      // {name: 'Uzbekistan', code: 'UZ'},
      // {name: 'Vanuatu', code: 'VU'},
      // {name: 'Venezuela', code: 'VE'},
      // {name: 'Viet Nam', code: 'VN'},
      // {name: 'Virgin Islands, British', code: 'VG'},
      // {name: 'Virgin Islands, U.S.', code: 'VI'},
      // {name: 'Wallis and Futuna', code: 'WF'},
      // {name: 'Western Sahara', code: 'EH'},
      // {name: 'Yemen', code: 'YE'},
      // {name: 'Zambia', code: 'ZM'},
      // {name: 'Zimbabwe', code: 'ZW'}
    ];
  }
  public getStates() {
    return [
      { name: "Johor" },
      { name: "Kedah" },
      { name: "Kelantan" },
      { name: "Kuala Lumpur" },
      { name: "Labuan" },
      { name: "Melaka" },
      { name: "Negeri Sembilan" },
      { name: "Pahang" },
      { name: "Perak" },
      { name: "Perlis" },
      { name: "Pulau Pinang" },
      { name: "Putrajaya" },
      { name: "Sabah" },
      { name: "Sarawak" },
      { name: "Selangor" },
      { name: "Terengganu" },
    ];
  }
  public getStatesMalaysia() {
    return [
      { name: "Johor" },
      { name: "Kedah" },
      { name: "Kelantan" },
      { name: "Kuala Lumpur" },
      { name: "Labuan" },
      { name: "Melaka" },
      { name: "Negeri Sembilan" },
      { name: "Pahang" },
      { name: "Perak" },
      { name: "Perlis" },
      { name: "Pulau Pinang" },
      { name: "Putrajaya" },
      { name: "Sabah" },
      { name: "Sarawak" },
      { name: "Selangor" },
      { name: "Terengganu" },
    ];
  }
  public getStatesSingapore(){
    return [ 
        {name: 'Central Singapore Community Development Council'},
        {name: 'North East Community Development Region'},
        {name: 'South East Community Development Council'},
        {name: 'South West Community Development Council'},
        {name: 'North West Singapore'}

    ]
}
public getStatesBrunei(){
  return [ 
      
      {name : 'Kuala Belait'},
      {name : 'Temburong'},
      {name : 'Brunei-Muara'},
      {name : 'Tutong'}
  ]
}

public getStateAll(){
  return [ 
      {name : 'Johor'},
      {name : 'Kedah'},
      {name : 'Kelantan'},
      {name : 'Kuala Lumpur'},
      {name : 'Labuan'},
      {name : 'Melaka'},
      {name : 'Negeri Sembilan'},
      {name : 'Pahang'},
      {name : 'Perak'},
      {name : 'Perlis'},
      {name : 'Pulau Pinang'},
      {name : 'Putrajaya'},
      {name : 'Sabah'},
      {name : 'Sarawak'},
      {name : 'Selangor'},
      {name : 'Terengganu'},
      {name : 'Kuala Belait'},
      {name : 'Temburong'},
      {name : 'Brunei-Muara'},
      {name : 'Tutong'},
      {name: 'Central Singapore Community Development Council'},
      {name: 'North East Community Development Region'},
      {name: 'South East Community Development Council'},
      {name: 'South West Community Development Council'},
      {name: 'North West Singapore'}
  ]
}

  public getMonths() {
    return [
      {
        name: "January",
        number: "01",
      },
      {
        name: "February",
        number: "02",
      },
      {
        name: "March",
        number: "03",
      },
      {
        name: "April",
        number: "04",
      },
      {
        name: "May",
        number: "05",
      },
      {
        name: "June",
        number: "06",
      },
      {
        name: "July",
        number: "07",
      },
      {
        name: "August",
        number: "08",
      },
      {
        name: "September",
        number: "09",
      },
      {
        name: "October",
        number: "10",
      },
      {
        name: "Novermber",
        number: "11",
      },
      {
        name: "December",
        number: "12",
      },
    ];
  }

  public getYears() {
    // return ["2018", "2019", "2020", "2021", "2022", "2023", "2024", "2025", "2026", "2027", "2028", "2029", "2030" ]

    return [
      {
        name: "2021",
        number: 2021,
      },
      {
        name: "2022",
        number: 2022,
      },
      {
        name: "2023",
        number: 2023,
      },
      {
        name: "2024",
        number: 2024,
      },
      {
        name: "2025",
        number: 2025,
      },
      {
        name: "2026",
        number: 2026,
      },
      {
        name: "2027",
        number: 2027,
      },
      {
        name: "2028",
        number: 2028,
      },
      {
        name: "2029",
        number: 2029,
      },
      {
        name: "2030",
        number: 2030,
      },
    ];
  }

    public getBanks(){ 
        return [
        {
          bank_name: 'HSBC Bank Berhad',
          bank_code: '22'
        },
        {
          bank_name: 'United Overseas Bank (M) Bhd',
          bank_code: '26'
        },
        {
          bank_name: 'Standard Chartered Bank Berhad',
          bank_code: '14'
        },
    
        {
          bank_name: 'RHB Bank Berhad',
          bank_code: '18'
        },
    
        {
          bank_name: 'Public Bank Berhad/ Public Finance Berhad',
          bank_code: '33'
        },
    
        {
          bank_name: 'OCBC Bank Berhad',
          bank_code: '29'
        },
    
        {
          bank_name: 'Malayan Banking Berhad',
          bank_code: '27'
        },
    
        {
          bank_name: 'Citibank',
          bank_code: '17'
        },
        {
          bank_name: 'CIMB Bank Berhad',
          bank_code: '35'
        },
    
        {
          bank_name: 'Bank Simpanan Nasional Berhad',
          bank_code: '10'
        },
        {
          bank_name: 'Bank Rakyat Berhad',
          bank_code: '02'
        },
        {
          bank_name: 'Bank Islam Malaysia Berhad',
          bank_code: '45'
        },
    
        {
          bank_name: 'Bank Muamalat Malaysia Berhad',
          bank_code: '41'
        },
        {
          bank_name: 'Agro Bank',
          bank_code: '49'
        },
        {
          bank_name: 'Affin Bank Berhad',
          bank_code: '32'
        },
        {
          bank_name: 'Alliance Bank Berhad',
          bank_code: '12'
        },
        {
          bank_name: 'AmBank Berhad',
          bank_code: '08'
        },
        {
          bank_name: 'HONG LEONG BANK',
          bank_code: '24'
        }
      ]
    }

} 
