import { Component, ViewEncapsulation } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { StorageService } from 'src/app/@services/storage.service';
import { Menu, NavService } from '../../service/nav.service';
import { UserService } from './../../../@services/user.service';
import * as CryptoJS from "crypto-js";
import { AccessmodelService } from 'src/app/@models/accessmodel.service';
import { ApiService } from '../../../@services/api.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SidebarComponent {
  public menuItems: Menu[];
  public url: any;
  public fileurl: any;

  userDetails: any;

  constructor(
    private router: Router,
    public navServices: NavService,
    public user: UserService,
    public storage: StorageService,
    private accessmodel : AccessmodelService,
    private api : ApiService

  ) {
    this.userDetails = this.user.getUserFromLocalStorage();
    var menu;

    let isThisHQ = this.storage.get('auth');
    // debugger
    // console.log(this.userDetails);
    // console.log(isThisHQ.warehouse.id);

    if(this.userDetails && this.userDetails.role.id == 17){
      menu = this.navServices.pickerItems;
    } else if(this.userDetails && this.userDetails.role.id == 18){
      if(isThisHQ.warehouse.id==5) { // HQ id = 2 , wh = 1 // real data : HQ = 5
        menu = this.navServices.outletItemsHQ; // #change-id
      }
      else {
        menu = this.navServices.outletItems;
      }
    }else{
      menu = this.navServices.items;
    }

    menu.subscribe((menuItems) => {
      this.menuItems = menuItems;
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          menuItems.filter((items) => {
            if (items.path === event.url) this.setNavActive(items);
            if (!items.children) return false;
            items.children.filter((subItems) => {
              if (subItems.path === event.url) this.setNavActive(subItems);
              if (!subItems.children) return false;
              subItems.children.filter((subSubItems) => {
                if (subSubItems.path === event.url)
                  this.setNavActive(subSubItems);
              });
            });
          });
        }
      });
    });

    const userC = this.storage.get('auth.user');
      // console.log(userC.role.id);

      if(userC.role.id!=18) {
    this.getAccess();
      }
  }

  resp : any ;
  userAccessCodeList: any = null;
  async getAccess(){
    const user = this.storage.get('auth.user');
    // console.log(user.id);
    let apiUrl = this.api.userApi + "getaccess/"+user.id;
    // try { 
    this.resp = await this.api.get(apiUrl).toPromise(); 
  // }
    // catch (e){
      // console.log(e)
    // }
    /**
     * // must fetch from db instead of localstorage
     * Reason : when superadmin update the access, so any login staff will automatically update their access without relogin
     */
    // let aclKey = this.storage.get('auth.acl');
    let aclKey = this.resp.data.access;
    let rawDec = CryptoJS.AES.decrypt(aclKey, this.accessmodel.sk);
    let aceStr = rawDec.toString(CryptoJS.enc.Utf8);
    this.userAccessCodeList = aceStr.split(",");
    // console.log(this.userAccessCodeList);
    // console.log(this.menuItems);
    // Filter the menuItems array based on accessKey
    const filteredMenuItems = this.menuItems.filter(item => {
      if (item.accessKey) {
        // Check if the accessKey exists in userAccessCodeList
        if (this.userAccessCodeList.includes(item.accessKey)) {
          // Filter the children array based on accessKey
          if (item.children) {
            item.children = item.children.filter(child => {
              return this.userAccessCodeList.includes(child.accessKey);
            });
          }
          return true; // Include the item in the filtered array
        }
      }
      return false; // Exclude the item from the filtered array
    });

    // debugger // Log the filtered menu items
    // console.log(filteredMenuItems);

    if(user.role.role!='Picker') { 
    this.menuItems = filteredMenuItems; // no-access-debug
    }
  }

  // Active Nave state
  setNavActive(item) {
    this.menuItems.filter((menuItem) => {
      if (menuItem != item) menuItem.active = false;
      if (menuItem.children && menuItem.children.includes(item))
        menuItem.active = true;
      if (menuItem.children) {
        menuItem.children.filter((submenuItems) => {
          if (submenuItems.children && submenuItems.children.includes(item)) {
            menuItem.active = true;
            submenuItems.active = true;
          }
        });
      }
    });
  }

  // Click Toggle menu
  toggletNavActive(item) {
    if (!item.active) {
      this.menuItems.forEach((a) => {
        if (this.menuItems.includes(item)) a.active = false;
        if (!a.children) return false;
        a.children.forEach((b) => {
          if (a.children.includes(item)) {
            b.active = false;
          }
        });
      });
    }
    item.active = !item.active;
  }

  //Fileupload
  readUrl(event: any) {
    if (event.target.files.length === 0) return;
    //Image upload validation
    var mimeType = event.target.files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }
    // Image upload
    var reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (_event) => {
      this.url = reader.result;
    };
  }
}
