export const environment = {
  production: true
};

export const environmentImage = {
  production: true,
s3:
  {
    accessKeyId: 'AKIAXTCZPD3OU2LGB47F',
    secretAccessKey: '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',
    region: 'ap-southeast-1'
  },
  AWS_BUCKET:'images.obsesscosmetics.net',
  AWS_IMAGE_URL:'https://images.obsesscosmetics.net/'
};