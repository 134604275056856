import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/@services/api.service';
import { StorageService } from 'src/app/@services/storage.service';
import { UserService } from 'src/app/@services/user.service';
import * as chartData from '../../../shared/data/chart';
import { MatSnackBar } from '@angular/material/snack-bar';

export var cumulativeSalesChartData: Array<any> = [];
export var cumulativeOrdersChartData: Array<any> = [];
export var cumulativeBillplzOrdersChartData: Array<any> = [];
export var cumulativeBillplzTopupChartData: Array<any> = [];

@Component({
  selector: 'app-dropship-dashboard',
  templateUrl: './dropship-dashboard.component.html',
  styleUrls: ['./dropship-dashboard.component.scss']
})
export class DropshipDashboardComponent implements OnInit {

  topTenGrouplSales: any;
  topTenPersonalSales: any;
  userDetails: any;

  openOrder:number;
  totalProducts:number;
  totalDropship:number;
  activePromotions:number;
  dropshipByRank:any;
  personalSales:any;
  top10Affiliate:any;
  groupSales:any;
  recruitment:any;
  orderByLocation:any;
  constructor(
    private api: ApiService,
    private storage: StorageService,
    public formBuilder: FormBuilder,
    public user: UserService,
    public router: Router,
    public snackBar: MatSnackBar,

    ) {
    // Object.assign(this, { doughnutData, pieData })
    this.userDetails = this.user.getUserFromLocalStorage();
    if(this.userDetails){
      if(this.userDetails.role.id == 17){
        this.router.navigate(["/fullfillment/fullfillment-list"]);
        }
      }
  }

  // doughnut 2
  ordersByLocationChartData;
  salesByLocationChartData;
  view = chartData.view;
  doughnutChartColorScheme = chartData.doughnutChartcolorScheme;
  doughnutChartShowLabels = chartData.doughnutChartShowLabels;
  doughnutChartGradient = chartData.doughnutChartGradient;
  doughnutChartTooltip = chartData.doughnutChartTooltip;

  // public chart5 = chartData.chart5;


  // lineChart
  cumulativeSales:number;
  cumulativeSalesChartData = cumulativeSalesChartData;
  cumulativeSalesChartLabels = chartData.lineChartLabels;

  cumulativeOrders:number;
  cumulativeOrdersChartData = cumulativeOrdersChartData;
  cumulativeOrdersChartLabels = chartData.lineChartLabels;

  cumulativeBillplzOrders:number;
  cumulativeBillplzOrdersChartData = cumulativeBillplzOrdersChartData;
  cumulativeBillplzOrdersChartLabels = chartData.lineChartLabels;

  cumulativeBillplzTopup:number;
  cumulativeBillplzTopupChartData = cumulativeBillplzTopupChartData;
  cumulativeBillplzTopupChartLabels = chartData.lineChartLabels;

  lineChartOptions = chartData.lineChartOptions;
  lineChartColors = chartData.lineChartColors;
  lineChartLegend = false;
  lineChartType = 'line';

  // lineChart

  // public chart3 = chartData.chart3;



  // events
  public chartClicked(e: any): void {
  }
  public chartHovered(e: any): void {
  }


  dashboard:any;
  userSummary:any;
  loader:boolean = false;

  fromDate:any = "";
  toDate:any = "";
  formFilter: FormGroup;


  ngOnInit() {
    this.verifyAdmin();
    // this.getDashboard();
    // this.getUserSummary();
    this.formFilter = this.formBuilder.group({
      fromDate: [''],
      toDate: [''],
    })
    this.callApis();
    this.getTopTenGrouplSales();
    this.getTopTenPersonalSales();
    this.getTopTenAffiliate();
  }

  getTopTenPersonalSales(){
    this.api.get(this.api.orderApi + 'top-ten-personal-sales').subscribe((res:any) =>{
        // console.log(res);
        this.topTenPersonalSales = res;
    })
  };
  getTopTenAffiliate(){
    this.api.get(this.api.userApi + 'top-ten-recruters').subscribe((res:any) =>{
        // console.log(res);
        this.top10Affiliate = res;
    })
  };
  getTopTenGrouplSales(){
    this.api.get(this.api.orderApi + 'top-ten-group-sales').subscribe((res:any) =>{
        // console.log(res);
        this.topTenGrouplSales = res;
    })
  };

  callApis(){

    this.getOpenOrder();
    this.getTotalProducts();
    this.getTotalDropship();
    this.getActivePromotions();
    this.getDropshipByRank();
    this.getPersonalSales();
    this.getGroupSales();
    // this.getRecruitment(); // unused api
    this.getCumulativeSales();
    this.getCumulativeOrders();
    this.getCumulativeBillplzOrders();
    this.getCumulativeBillplzTopup();
    this.getOrderByLocation();
  }

  getOpenOrder(){
    this.api.get(this.api.orderApi + 'open-orders-ds').subscribe((res: any) => {
      this.openOrder = res.open_order
    });
  }
  getTotalProducts(){
    this.api.get(this.api.orderApi + 'total-products').subscribe((res: any) => {
      this.totalProducts = res.published_product
    });
  }
  getTotalDropship (){
    this.api.get(this.api.orderApi + 'total-dropship').subscribe((res: any) => {
      this.totalDropship = res.customer
    });
  }
  getActivePromotions (){
    this.api.get(this.api.orderApi + 'active-promotions').subscribe((res: any) => {
      this.activePromotions = res.promotion
    });
  }
  getDropshipByRank(){
    this.api.get(this.api.orderApi + 'dropship-by-rank').subscribe((res: any) => {
      this.dropshipByRank = res
    });
  }

  getPersonalSales(){
    this.api.get(this.api.orderApi + 'top-personal-sales?month=09&year=2022').subscribe((res: any) => {
      this.personalSales = res
    });
  }
  getGroupSales(){
    this.api.get(this.api.orderApi + 'top-group-sales?month=09&year=2022').subscribe((res: any) => {
      this.groupSales = res
    });
  }
  getRecruitment(){
    this.api.get(this.api.orderApi + 'top-recruitment?from=&to=').subscribe((res: any) => {
      this.recruitment = res
    },error=> {
      this.snackBar.open('top-recruitment api error', 'Dismiss', { panelClass: 'error', verticalPosition: 'top', duration: 3000 });
      console.error(error);
    });
  }

  getCumulativeSales(){
    this.api.get(this.api.orderApi + `amount-graph?from=${this.fromDate}&to=${this.toDate}&role=3`).subscribe((res: any) => {
      this.cumulativeSales = res.cumulative_amount;
      cumulativeSalesChartData[0].data = res.order_amount.map(e=>{
        return e.amount
      })
      this.cumulativeSalesChartLabels = res.order_amount.map(e=>{
        return e.date
      })
    });
  }

  getCumulativeOrders(){
    this.api.get(this.api.orderApi + `quantity-graph?from=${this.fromDate}&to=${this.toDate}&role=3`).subscribe((res: any) => {
      this.cumulativeOrders = res.cumulative_order;
      cumulativeOrdersChartData[0].data = res.order_quantity.map(e=>{
        return e.amount
      })
      this.cumulativeOrdersChartLabels = res.order_quantity.map(e=>{
        return e.date
      })

    });
  }
  getCumulativeBillplzOrders(){
    this.api.get(this.api.orderApi + `cumulative-billplz-ds-order?from${this.fromDate}=&to=${this.toDate}`).subscribe((res: any) => {
      this.cumulativeBillplzOrders = res.cumulative_amount;
      cumulativeBillplzOrdersChartData[0].data = res.order_amount.map(e=>{
        return e.amount
      })
      this.cumulativeBillplzOrdersChartLabels = res.order_amount.map(e=>{
        return e.date
      })
    });
  }
  getCumulativeBillplzTopup(){
    this.api.get(this.api.orderApi + `cumulative-billplz-ds-topup?from=${this.fromDate}&to=${this.toDate}`).subscribe((res: any) => {
      this.cumulativeBillplzTopup = res.cumulative_amount;
      cumulativeBillplzTopupChartData[0].data = res.order_amount.map(e=>{
        return e.amount
      })
      this.cumulativeBillplzTopupChartLabels = res.order_amount.map(e=>{
        return e.date
      })
    });
  }
  getOrderByLocation(){
    this.api.get(this.api.orderApi + `order-by-location-graph-ds?from=${this.fromDate}&to=${this.toDate}`).subscribe((res: any) => {
      this.orderByLocation = res
      this.orderByLocation.forEach(element => {
        element.total_amount = element.total_amount.toFixed(2)
      });

      setTimeout(() => {
        this.ordersByLocationChartData = res.map(e=>{
          return {
            value: e.total_order,
            name: e.state
          }
        })

        this.salesByLocationChartData = res.map(e=>{
          return {
            value: e.total_amount.toFixed(2),
            name: e.state
          }
        })
      }, 500);

    });
  }


  submitFilter(graphName){
    var fromDate  = this.formFilter.value.fromDate
    var toDate  = this.formFilter.value.toDate

    // from
      if(fromDate.month.toString().length < 2){
        fromDate.month = '0' + fromDate.month
      }
      if(fromDate.day.toString().length < 2){
        fromDate.day = '0' + fromDate.day
      }

    // to
      if(toDate.month.toString().length < 2){
        toDate.month = '0' + toDate.month
      }
      if(toDate.day.toString().length < 2){
        toDate.day = '0' + toDate.day
      }

      this.fromDate = fromDate.year + '-' + fromDate.month +'-' + fromDate.day
      this.toDate = toDate.year + '-' + toDate.month +'-' + toDate.day

    console.log(this.fromDate);
    console.log(this.toDate);
    if(graphName == 'Cumulative Sales'){
      this.getCumulativeSales();
    }else if(graphName == 'Cumulative Orders'){
      this.getCumulativeOrders();
    }else if(graphName == 'Cumulative Billplz Order'){
      this.getCumulativeBillplzOrders();
    }else if (graphName == 'Orders By Location'){
      this.getOrderByLocation();
    }else{
      this.getCumulativeBillplzTopup();
    }
  }
  verifyAdmin() {
    const user = this.storage.get('auth.user');
    console.clear();
    if(user.role.role=='Agent') {
      console.log("logout");
      this.storage.clear();
      location.reload();
    }
  }
}
