import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { StorageService } from 'src/app/@services/storage.service';
import { AuthService } from './../@services/auth.service';
import { AccessService } from '../@services/access.service';
import { ApiService } from '../@services/api.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    private authService: AuthService,
    private router: Router,
    private storage: StorageService,
    private access:AccessService,
    private api : ApiService
    ) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    if (!this.authService.loggedIn()) {
      this.router.navigate(["/login"])
    }else{
      // access service start
      // console.log(next.data.accessCode);
      const user = this.storage.get('auth.user');
      // console.log(user.role.id);

      if(user.role.id!=18) {
      let apiUrl = this.api.userApi + "getaccess/"+user.id;
      this.api.get(apiUrl).subscribe((res: any) => {
      let acl = res.data.access;
      let accessToCheck = {
        encAccessCode : acl,
        pageAccessCode : next.data.accessCode
      }
      let hasAccess = this.access.checkAccessCode(accessToCheck);
      if(!hasAccess) {
        console.log("no access");
        this.router.navigate(["/no-access"]); // no-access-debug
      }
      // this.checkUserAuth(next);
     });
    }

    }
    return true;
  }


  checkUserAuth(route: ActivatedRouteSnapshot) {
    let user = this.storage.get('auth.user');
      if (route.data.role && route.data.role.indexOf(user.role.id) == -1) {
        return false;
      }
  }
}
