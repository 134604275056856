<div class="container-fluid">
  <div class="row">
    <div class="col-sm-12">
      <div class="card">
        <div class="card-header ">
          <div class="row">
            <div class="col-sm-9">
              <h5>PICKER LISTS</h5>
            </div>
            <!-- <div class="col-sm-3">
              <div class="input-group">
                <input type="text" class="form-control " placeholder="Search">
                <div class="input-group-append">
                  <button class="btn btn-secondary" type="button">
                    <i class="fa fa-search"></i>
                  </button>
                </div>
              </div>
            </div> -->
          </div>
        </div>
        <div class="card-body">
          <div class="btn-popup pull-right">
            <button type="button" class="btn btn-secondary" (click)="addPicker()">ADD PICKER</button>
          </div>
          <div id="batchDelete" class="custom-datatable">
            <div class="table-responsive">
              <!-- <ng2-smart-table [settings]="settings" [source]="digital_list"></ng2-smart-table> -->
              <table class="table">
                <thead class="thead-dark">
                  <tr>
                    <th>ID</th>
                    <th>NAME</th>
                    <th>EMAIL</th>
                    <th colspan="2">ACTION</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let picker of pickers; let i = index">
                    <td>OB-{{ picker.id }}</td>
                    <td>{{ picker.user_name }}</td>
                    <td>{{ picker.email }}</td>
                    <td>
                      <app-feather-icons title="Edit" (click)="edit(picker.id)" [icon]="'edit-3'"></app-feather-icons>
                    </td>
                    <td>
                      <app-feather-icons title="Delete" (click)="delete(picker)" [icon]="'trash-2'">
                      </app-feather-icons>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>