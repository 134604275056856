import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AccessmodelService {

  sk : string = 'obsesscosmetics@Iwhost'; // centralize the secret key access

  constructor() { }

  accessModel : any = [
    {
      name: "Dashboard",
      completed: false,
      someChild: false,
      value: "0000",
      subChild: [
        { name: "Dropship", value: "0010", completed: false },
        { name: "Customer", value: "0020", completed: false },
      ],
    },
    {
      name: "Sales",
      completed: false,
      someChild: false,
      value: "1000",
      subChild: [
        { name: "Orders", value: "1010", completed: false },
        { name: "Fulfillment", value: "1020", completed: false },
        { name: "Pickup Fulfillment", value: "1030", completed: false, },
        { name: "AWB Fullfillment", value: "1040", completed: false },
      ],
    },
    {
      name: "Sales",
      completed: false,
      someChild: false,
      value: "RT1000",
      subChild: [
        { name: "Place Order", value: "RT1010", completed: false },
      ],
    },
    {
      name: "Products",
      completed: false,
      someChild: false,
      value: "2000",
      subChild: [
        { name: "Product List", value: "2010", completed: false },
        { name: "Add Product", value: "2020", completed: false },
        { name: "Create Combo", value: "2030", completed: false },
        { name: "Starter Pack", value: "2040", completed: false },
      ],
    },
    {
      name: "Inventory",
      completed: false,
      someChild: false,
      value: "3000",
      subChild: [
        { name: "Request Inventory", value: "3060", completed: false },
        { name: "Warehouse Inventory Out Info", value: "3070", completed: false },
        { name: "Product Inventory", value: "3010", completed: false },
        { name: "Inventory Out", value: "3020", completed: false },
        { name: "Inventory Out Info", value: "3030", completed: false },
        { name: "Inventory Out Log", value: "3040", completed: false },
        { name: "Item Check", value: "3050", completed: false },
      ],
    },
    {
      name: "Variations",
      completed: false,
      someChild: false,
      value: "4000",
      subChild: [
        { name: "Category", value: "4010", completed: false },
        { name: "Sub Category", value: "4020", completed: false },
        { name: "Flavour", value: "4030", completed: false },
        { name: "Size", value: "4040", completed: false },
        { name: "Code", value: "4050", completed: false },
      ],
    },
    {
      name: "Promotion",
      completed: false,
      someChild: false,
      value: "5000",
      subChild: [
        { name: "Promotion List", value: "5010", completed: false },
        { name: "Add Promotion", value: "5020", completed: false },
      ],
    },
    {
      name: "Report",
      completed: false,
      someChild: false,
      value: "6000",
      subChild: [
        { name: "DS Commission", value: "6010", completed: false },
        { name: "Sales By Dropship", value: "6020", completed: false },
        { name: "Sales By Group", value: "6030", completed: false },
        { name: "Sales By Product", value: "6040", completed: false },
        { name: "Product Units Sold", value: "6050", completed: false },
      ],
    },
    {
      name: "Credit",
      completed: false,
      someChild: false,
      value: "7000",
      subChild: [
        { name: "Credit Lists", value: "7010", completed: false }
      ],
    },
    {
      name: "Points",
      completed: false,
      someChild: false,
      value: "11000",
      subChild: [
        { name: "Points", value: "11010", completed: false }
      ],
    },
    {
      name: "eWallet",
      completed: false,
      someChild: false,
      value: "7100",
      subChild: [
        { name: "Withdraw Lists", value: "7110", completed: false },
        { name: "eWallet Lists", value: "7120", completed: false }
      ],
    },
    {
      name: "Users",
      completed: false,
      someChild: false,
      value: "8000",
      subChild: [
        { name: "Picker List", value: "8010", completed: false },
        { name: "Dropship List", value: "8020", completed: false },
        { name: "Customer List", value: "8030", completed: false },
        { name: "Deleted Users", value: "8040", completed: false },
        // { name: "Create Users", value: "8050", completed: false },
      ],
    },
    {
      name: "Settings",
      completed: false,
      someChild: false,
      value: "9000",
      subChild: [
        { name: "User Role", value: "9010", completed: false },
        { name: "Tier Configuration", value: "9020", completed: false },
        { name: "Courier", value: "9030", completed: false },
        { name: "Shipping Fees", value: "9040", completed: false },
        { name: "Self Pickup", value: "9050", completed: false },
        { name: "Banner", value: "9060", completed: false },
        { name: "Slider", value: "9070", completed: false },
        { name: "Home Video", value: "9080", completed: false },
        { name: "Announcement", value: "9090", completed: false },
        { name: "Maintenance", value: "9100", completed: false }
      ],
    },
    {
      name: "Outlet Settings",
      completed: false,
      someChild: false,
      value: "10000",
      subChild: [
        { name: "Outlet Admin", value: "10010", completed: false },
        { name: "Outlet", value: "10020", completed: false },
        { name: "Inventory Request", value: "10030", completed: false },
        { name: "Inventory Out", value: "10040", completed: false },
        { name: "Report", value: "10050", completed: false },
      ],
    }
  ];


}
