<!-- <div class="loader" *ngIf="loader">
    <mat-spinner style="margin:0 auto;"></mat-spinner>
    </div> -->
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-12">
        <div class="card">
          <div class="card-header ">
            <div class="row">
              <div class="col-sm-9">
                <h5>DONWLINE LISTS </h5>
                 <h6>OBP-{{dropshId}}MY - <b>{{dropshipname}}  ( {{totalDownline}} downlines )</b></h6>
              </div>
              <div class="col-sm-3">
                <div class="input-group">
                  <input type="text" (keyup.enter)="search(searchText)" [(ngModel)]="searchText" class="form-control "
                    placeholder="Search By Email">
                  <div class="input-group-append">
                    <button (click)="search(searchText)" class="btn btn-secondary" type="button">
                      <i class="fa fa-search"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <!-- <div class="pull-right ml-2">
              <button class="btn btn-primary" (click)="exportAsXLSX()" type="button" data-toggle="collapse"
                data-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
                EXPORT
              </button>
            </div> -->
            <div class="btn-popup pull-right ml-2">
              <!-- <button type="button" class="btn btn-secondary" [cdkCopyToClipboard]="regLink">COPY REFERRAL LINK</button> -->
            </div>

            <div id="batchDelete" class="custom-datatable">
            <button type="button" class="btn btn-primary" style="margin-bottom: 10px;" (click)="goBack">Back</button>
              <div class="table-responsive">
                <!-- <ng2-smart-table [settings]="settings" [source]="digital_list"></ng2-smart-table> -->
                <table class="table">
                  <thead class="thead-dark">
                    <tr>
                      <th>ID</th>
                      <th>NAME</th>
                      <th>EMAIL</th>
                      <th>PHONE</th>
                      <th>ADDRESS</th>
                      <th>STATUS</th>
                    </tr>
                  </thead>
                  <tbody>
                <tr *ngIf="havedata">
                <td colspan="6" style="text-align:center;"><h1>No results</h1></td>
                </tr>
                <tr *ngFor="let downline of downlineList; let i = index">

                    <td>OBP-{{downline.id}}MY</td>
                    <!-- <td>{{downline.user_name}}</td> -->
                    <td *ngIf="downline.details!=null">{{downline.details.first_name}} {{downline.details.last_name}}</td>
                    <td *ngIf="downline.details==null">N/A</td>

                    <td>{{downline.email}}</td>
                    <td>{{downline.details?.phone}}</td>
                    <td>{{downline.details?.address_line1}}<br>{{downline.details?.address_line2}}<br>{{downline.details?.post_code}},{{downline.details?.state}} </td>
                    <td>
                        <span class="statusCircles1" *ngIf="downline.email_verified == 1"></span>
                        <span class="statusCircles2" *ngIf="downline.email_verified == 0"></span>
                    </td>
                    <!-- <td>{{downline.email_verified}}</td> -->
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="btn-popup pull-right" *ngIf="lastPage > 1 && downlineList?.length > 0">
                <pagination-with-api [apiUrl]="apiUrl" [totalPage]="totalPage" (valueChanged)="pageChanged($event)">
                </pagination-with-api>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
