import { Location } from "@angular/common";
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/@services/api.service';
import { emailValidator } from 'src/app/shared/utils/app-validators';
import Swal from "sweetalert2";
@Component({
  selector: 'app-create-outlet-admin',
  templateUrl: './create-outlet-admin.component.html',
  styleUrls: ['./create-outlet-admin.component.scss']
})
export class CreateOutletAdminComponent implements OnInit {
  model: any;
  outletWarehouse
  public accountForm: FormGroup;
  public permissionForm: FormGroup;
  dropdownSettings = {
    singleSelection: true,
    idField: 'id',
    textField: 'name',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 5,
    allowSearchFilter: false,
    enableCheckAll: false,
    limitSelection: -1,
    showSelectedItemsAtTop: true,
    defaultOpen: false,
  };
  constructor(private formBuilder: FormBuilder,
    private api: ApiService,
    private route: ActivatedRoute,
    private _location: Location

    ) {
    this.createAccountForm();
    this.createPermissionForm();
  }

  createAccountForm() {
    this.accountForm = this.formBuilder.group({
      'first_name': ['', Validators.required],
      'last_name': ['', Validators.required],
      'user_name': [''],
      'email': ['', Validators.compose([Validators.required, emailValidator])],
      'phone': ['', Validators.compose([Validators.required,Validators.pattern('[0-9]{6,16}')])],
      'password': ['', Validators.required],
      'outlet_id': ['', Validators.required], // removed
      'user_role': [18]
    })
  }
  createPermissionForm() {
    this.permissionForm = this.formBuilder.group({
    })
  }

  ngOnInit() {
     var id = this.route.snapshot.params.id
    if (id) {
      this.getOutlet(id)
    }
    this.api.get(this.api.warehouseApi + "all-warehouse").subscribe((res: any) => {
      this.outletWarehouse = res.filter(i=> i.name != "Warehouse 1");
      // this.outletWarehouse = res.data.filter(i=> i.name != "Warehouse 1");
      // this.outlets = res.filter(i=> i.name != "Warehouse 1" && i.status == 1);

    })
  }

  getOutlet(id) {
    this.api.get(this.api.userApi + `users/${id}`).subscribe((res: any) => {
      this.model.id = res.id;
      this.model.user_name = res.user_name;
      this.model.email = res.email;
      this.model.password = res.password;
      this.model.user_role = res.user_role;
    });
  }
  save() {
    if(!this.accountForm.controls['user_name'].valid) { Swal.fire('','','error'); }
    if(!this.accountForm.controls['email'].valid){ Swal.fire('','Wrong email','error'); }
    if(!this.accountForm.controls['phone'].valid){ Swal.fire('','Phone Invalid','error'); }
    // if (this.model.id) {
    //   // console.log(this.warehouse);

    //   // for update
    //   this.api.create(this.api.userApi + "users", this.accountForm.value).subscribe(res => {
    //     // console.log(this.warehouse);
    //   })
    // } else {
      this.accountForm.controls['user_name'].setValue(this.accountForm.value.first_name + ' ' + this.accountForm.value.last_name);
      if(!this.accountForm.valid) { return; }
      this.accountForm.value.outlet_id = this.accountForm.value.outlet_id[0].id; // removed
      this.api.create(this.api.userApi + "users", this.accountForm.value).subscribe((res:any) => {
        if(res.status == "ok"){
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Oulet Admin Added!',
            showConfirmButton: false,
            timer: 1500
          });
          this.goBack;
        }else{
          Swal.fire({
            position: 'center',
            icon: 'error',
            title: 'Username or email already exist!',
            showConfirmButton: false,
            timer: 1500
          });
        }
      }, error => {
        console.log(error);

      })
    // }
  }
  get goBack() {
    return this._location.back();
  }
  onItemSelect(item: any) {
    console.log(item);
  }
  onSelectAll(items: any) {
    console.log(items);
  }
  onInput(event: any) {
    const value = event.target.value;
    const pattern = /^[0-9]*$/;
    const valid = pattern.test(value);
    if (!valid) {
      event.target.value = value.replace(/[^0-9]/g, '');
    }
  }

}
