import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as moment from 'moment';
import { ApiService } from 'src/app/@services/api.service';

@Component({
  selector: 'pagination-with-api',
  templateUrl: './pagination-with-api.component.html',
  styleUrls: ['./pagination-with-api.component.scss']
})
export class PaginationWithApiComponent implements OnInit {
  @Input() apiUrl: any;
  @Input() totalPage:any;
  @Output() orders: any;


  // totalPage: number;
  @Output() lastPage: any;

  @Output() valueChanged = new EventEmitter<any>();

  currentPage: any;
  previous = 0;
  pages: any[] = [];
  next = 2;
  pageId = 1;
  searchText: string = '';
  fromDate = moment().format('YYYY-MM-DD');
  toDate= moment().format('YYYY-MM-DD');
  constructor(
    private api: ApiService,
  ) { }

  firstPageChange = false
  ngOnInit(): void {
      this.firstPageChanged(1,1)
      // console.log(this.apiUrl);
  }


  getPaginationData(id:any) {

    // var urlApi = this.api.orderApi + 'order-by/2?page=' + id + '&search=' + this.searchText + '&from=' + this.fromDate + '&to=' + this.toDate;
    var url = this.apiUrl.replace('page=1', "page="+id);
    // console.log(url);
    this.api.get(url).subscribe((res: any) => {
      if(Array.isArray(res.data)){
        // if (res.data.cp){ remove dymanic
          let val = res.data;
          val.cp = res.current_page;
          this.valueChanged.emit(val);
          // this.orders = res.data
          this.lastPage = res.last_page;
          this.currentPage = res.current_page;
        // } else { this.valueChanged.emit(res.data); } remove dymanic
        this.previous = id - 1;
        this.next = id + 1;
      }else{
        if (res.data.cp){ 
          let val = res.data.data;
          val.cp = res.data.current_page; 
          this.valueChanged.emit(val);
          // this.orders = res.data
          this.lastPage = res.data.last_page;
          this.currentPage = res.data.current_page;
        } else {
          this.valueChanged.emit(res);
        }
        this.previous = id - 1;
        this.next = id + 1;
      }

      // console.log(res.last_page);
      // this.pageChanged(1, 1);
    });
  }

  pagination(nop:any) {
    let pgs = [];
    for (var i = 1; i <= nop; i++) {
      pgs.push(i);
    }
    return pgs;
  }

  prevPage() {
    this.pageChanged(--this.currentPage, 1);
  }
  nextPage() {
    this.pageChanged(++this.currentPage, 1);

  }
  pageChanged(page:any, index:any) {
    if (page == "...") {
      const low = this.pages[index - 1];
      const high = this.pages[index + 1];
      const mid = Math.round((high - low) / 2) + low;
      this.pages = this.paginate(mid, this.totalPage);
    } else {
      this.pages = this.paginate(page, this.totalPage);
    }
    this.getPaginationData(this.currentPage)
  }

  firstPageChanged(page: string | number, index: number) {
      if (page == "...") {
        const low = this.pages[index - 1];
        console.log(this.pages);

        const high = this.pages[index + 1];
        const mid = Math.round((high - low) / 2) + low;
        this.pages = this.paginate(mid, this.totalPage);
      } else {
        this.pages = this.paginate(page, this.totalPage);
      }

  }
  paginate(c:any, m:any) {
    this.currentPage = c;
    var delta = 2,
      range = [],
      rangeWithDots = [],
      l;

    range.push(1);
    for (let i = c - delta; i <= c + delta; i++) {
      if (i < m && i > 1) {
        range.push(i);
      }
    }
    range.push(m);

    for (let i of range) {
      if (l) {
        if (i - l === 2) {
          rangeWithDots.push(l + 1);
        } else if (i - l !== 1) {
          rangeWithDots.push("...");
        }
      }
      rangeWithDots.push(i);
      l = i;
    }

    return rangeWithDots;
  }
}
