import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
// import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  localLink= "http://localhost:4200/#/"

  siteLink:any;
  warehouseApi:any;
  orderApi:any;
  order2Api:any;
  productApi :any;
  systemApi :any;
  userApi: any;
  placeOrderApi:any;

  baseUrl = ''; // environment.apiRoot;
  baseUrlLidt = ''; // environment.apiRoot;
  baseUrlCr = ''; // environment.apiRoot;
  // baseUrlUP = environment.apiRoot;
  // baseUrl = '';//environment.apiRoot;S

  public loading = false;
  public loadingMessage = 'Loading...';
  constructor(private http: HttpClient, private router: Router, private _route: ActivatedRoute) {

    if (window.location.href.includes('obsesscosmetics.com')) {
      this.siteLink= "https://obsesscosmetics.com/#/";
      this.warehouseApi = 'https://warehouse2.obsesscosmetics.com/public/';
      this.orderApi = 'https://order.obsesscosmetics.com/public/';
      this.order2Api = 'https://order2.obsesscosmetics.com/public/';
      this.productApi = 'https://products.obsesscosmetics.com/public/';
      this.systemApi = 'https://system.obsesscosmetics.com/public/';
      this.userApi = 'https://user.obsesscosmetics.com/public/';
      this.placeOrderApi = 'https://order-place.obsesscosmetics.com/public/';

    }else{
      this.siteLink= "https://dev.obsesscosmetics.net/#/";
      this.warehouseApi = 'https://warehousedev.obsesscosmetics.net/public/';
      this.orderApi = 'https://orderdev.obsesscosmetics.net/public/';
      this.order2Api = 'https://orderdev.obsesscosmetics.net/public/';
      this.productApi = 'https://productsdev.obsesscosmetics.net/public/';
      this.systemApi = 'https://systemdev.obsesscosmetics.net/public/';
      this.userApi = 'https://userdev.obsesscosmetics.net/public/';
      this.placeOrderApi = 'https://orderdev.obsesscosmetics.net/public/';

      // live api || ng build --configuration production
      // this.siteLink= "https://obsesscosmetics.com/#/";
      // this.warehouseApi = 'https://warehouse2.obsesscosmetics.com/public/';
      // this.orderApi = 'https://order.obsesscosmetics.com/public/';
      // this.order2Api = 'https://order2.obsesscosmetics.com/public/';
      // this.productApi = 'https://products.obsesscosmetics.com/public/';
      // this.systemApi = 'https://system.obsesscosmetics.com/public/';
      // this.userApi = 'https://user.obsesscosmetics.com/public/';
    }
  }

  // GET
  public get(controller) {
    this.loading = true;
    return this.http.get(this.baseUrl + controller);
  }
  public read(controller, id) {
    this.loading = true;
    return this.http.get(this.baseUrl + controller + '/' + id);
  }
  // CREATE
  create(controller, model) {
    return this.post(controller, model);
  }

  // Post
  postSimple(controller) {
    this.loading = true;
    return this.http.post(this.baseUrl + controller, {});
  }
  // Post
  post(controller, model) {
    this.loading = true;
    return this.http.post(this.baseUrl + controller, model);
  }

  public getAsync(controller): Observable<any> {
    this.loading = true;
    return this.http.get<any>(this.baseUrl + controller).pipe(tap((_) => console.log('Loaded')));
  }

  // list
  public list(listName) {
    this.loading = true;
    return this.http.get(this.baseUrl + 'list/' + listName);
  }

  // READ
  public getWithId(controller, model) {
    this.loading = true;
    return this.http.get(this.baseUrl + controller + '/' + model.id);
  }

  // Update
  public update(controller, model) {
    this.loading = true;
    return this.http.put(this.baseUrl + controller + '/' + model.id, model);
  }

  // Delete
  public delete(controller, model) {
    this.loading = true;
    return this.http.delete(this.baseUrl + controller + '/' + model.id);
  }
}
