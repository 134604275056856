import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { StorageService } from './storage.service';

@Injectable({ providedIn: 'root' })

export class UserService {
    constructor(
        private api: ApiService,
        private storage: StorageService,

    ){

    }

    getAllUsers() {
        return this.api.get(this.api.userApi);
    }
    getUserByType(type) {
        return this.api.get(this.api.userApi + type);
    }
    getUserById(id) {
        return this.api.get(this.api.userApi + id);
    }
    getUserAuth() {
        return this.storage.get('auth.user');
    }
    getUserFromLocalStorage(){
        return this.storage.get('auth.user');
    }
}
