import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './components/auth/login/login.component';
import { PrintingComponent } from './components/printing/printing.component';
import { ContentLayoutComponent } from './shared/layout/content-layout/content-layout.component';
import { content } from './shared/routes/content-routes';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard/dropship-dashboard', // note: pegi ke content-routes.ts
    pathMatch: 'full'
    
  },
  {
    path: '',
    component: ContentLayoutComponent,
    children: content
  },
  {
    path: 'auth/login',
    component: LoginComponent,
  },
  { path: "print", component: PrintingComponent },

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled',
    relativeLinkResolution: 'legacy',
    useHash: true,

})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
