import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { Ng2SmartTableModule } from 'ng2-smart-table';

import { SharedModule } from 'src/app/shared/shared.module';
import { ChangeShippingAddressDialogComponent } from './change-shipping-address/change-shipping-address-dialog.component';
import { ManualConsignmentDialogComponent } from './manual-consignment-dialog/manual-consignment-dialog.component';
import { OrderCancelDialogComponent } from './order-cancel-dialog/order-cancel-dialog.component';
import { OrderDetailsComponent } from './order-details/order-details.component';
import { OrderOnHoldComponent } from './order-on-hold/order-on-hold.component';
import { OrdersComponent } from './orders/orders.component';
import { SalesRoutingModule } from './sales-routing.module';
import { TransactionsComponent } from './transactions/transactions.component';

@NgModule({
  declarations: [
    OrdersComponent,
    TransactionsComponent,
    OrderDetailsComponent,
    ManualConsignmentDialogComponent,
    OrderCancelDialogComponent,
    OrderOnHoldComponent,
    ChangeShippingAddressDialogComponent
  ],
  imports: [
    CommonModule,
    SalesRoutingModule,
    Ng2SmartTableModule,
    NgxDatatableModule,
    SharedModule,
  ],
})
export class SalesModule {}
