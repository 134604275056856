import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CustomerDashboardComponent } from './customer-dashboard/customer-dashboard.component';

import { CountToModule } from 'angular-count-to';
import { DashboardRoutingModule } from './dashboard-routing.module';
import { DropshipDashboardComponent } from './dropship-dashboard/dropship-dashboard.component';
// import { ChartistModule } from 'ng-chartist';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { ChartsModule } from 'ng2-charts';
import { Ng2GoogleChartsModule } from 'ng2-google-charts';
import { SharedModule } from '../../shared/shared.module';

@NgModule({
  declarations: [DropshipDashboardComponent, CustomerDashboardComponent],
  imports: [
    CommonModule,
    DashboardRoutingModule,
    CountToModule,
    SharedModule,
    ChartsModule,
    Ng2GoogleChartsModule,
    NgxChartsModule,
    // ChartistModule
  ]
})
export class DashboardModule { }
