<div class="loader" *ngIf="loader">
  <mat-spinner style="margin:0 auto;"></mat-spinner>
  </div>
<div class="container-fluid">
  <div class="row">
    <div class="col-sm-12">
      <div class="card">
        <div class="card-header ">
          <div class="row">
            <div class="col-sm-6">
              <h5>ORDERS</h5>
            </div>
            <div class="col-sm-3 text-right">
              <button class="btn btn-primary" (click)="isCollapsed = !isCollapsed" type="button" data-toggle="collapse" data-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
                FILTER
              </button>
            </div>
            <div class="col-sm-3">
              <div class="input-group">
                <input type="text" (keyup.enter)="search(searchText)"  [(ngModel)]="searchText" class="form-control " placeholder="Search By Invoice">
                <div class="input-group-append">
                  <button (click)="search(searchText)" class="btn btn-secondary" type="button">
                    <i class="fa fa-search"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row text-right">
          <div class="collapse w-100" id="collapseExample" [ngbCollapse]="isCollapsed">
            <div class="card-body">
              <form [formGroup]="formFilter">
                <div class="row px-2">
                    <div class="col-xl-3 col-md-4 p-1">
                        <div class="input-group">
                            <input class="form-control" placeholder="FORM" name="dp1"
                                formControlName="fromDate" ngbDatepicker #d1="ngbDatepicker">
                            <div class="input-group-append">
                                <button class="btn btn-outline-secondary calendar" (click)="d1.toggle()"
                                    type="button">
                                    <i style="font-size:19px" class="fa">&#xf073;</i>
                                </button>

                            </div>
                        </div>
                    </div>
                    <div class="col-xl-3 col-md-4 p-1">
                        <div class="input-group">
                            <input class="form-control" placeholder="TO" name="dp2"
                                formControlName="toDate" ngbDatepicker #d2="ngbDatepicker">
                            <div class="input-group-append">
                                <button class="btn btn-outline-secondary calendar" (click)="d2.toggle()"
                                    type="button">
                                    <i style="font-size:19px" class="fa">&#xf073;</i>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-3 col-md-4 p-1">
                        <select class="custom-select" formControlName="orderStatus"
                            placeholder="Order status">
                            <option selected disabled value="">Order status</option>
                            <option *ngFor="let item of filterlist" [value]="item.value">{{
                                item.name }}
                            </option>
                        </select>
                    </div>
                    <div class="col-xl-3 col-md-4 p-1">
                        <select class="custom-select" formControlName="shippingType"
                            >
                            <option selected disabled value="">Shipping Type</option>
                            <option *ngFor="let item of shippingTypeFilterlist"
                                [value]="item.shipping_name">
                                {{item.shipping_name }} <span *ngIf="item.status==0">[x]</span>
                            </option>
                        </select>
                    </div>
                    <div class="col-xl-3 col-md-4 p-1">
                      <select class="custom-select" formControlName="paymentType"
                          >
                          <option selected disabled value="">Payment type</option>
                          <option *ngFor="let item of paymentTypeFilterlist"
                              [value]="item.name">
                              {{item.name }}
                          </option>
                      </select>
                  </div>
            <div class="col-xl-3 col-md-4 p-1" *ngIf="showFilterByOutlet==true">
              <select (change)="selectedWarehouse($event.target.value)" class="custom-select">
                <option selected value="">Outlet</option>
                <ng-container *ngFor="let outlet of outlets">
                  <option [value]="outlet.id">{{outlet.name}}</option>
                </ng-container>
              </select>
                </div>
          </div>
            </form>
            <button type="button" class="btn btn-primary mr-1 pull-right" (click)="submitFilter()">SUBMIT</button>
            </div>
        </div>
        <div class="card-body" style="overflow-x: hidden;">
          <div class="btn-popup pull-right">
          </div>
          <div id="batchDelete" class="custom-datatable" >
            <div class="table-responsive text-center" >
              <!-- <ng2-smart-table [settings]="settings" [source]="digital_list"></ng2-smart-table> -->
              <table class="table">
                <thead class="thead-dark">
                  <tr>
                    <th>#</th>
                    <th>INVOICE</th>
                    <th>DATE</th>
                    <th>ORDER STATUS</th>
                    <th>PAYMENT STATUS</th>
                    <th>PAYMENT METHOD</th>
                    <th>SHIPPING TYPE</th>
                    <th>TRACKING NUMBER</th>
                    <th>TOTAL</th>
                    <th>ACTION</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let order of orders; let i = index">
                    <th scope="row">
                      {{ i + (currentPage - 1) * perPage + 1 }}
                    </th>
                    <td>{{ order.invoice }}</td>
                    <td>{{ order.order_date }}</td>
                    <td>
                      <span *ngIf="order.order_status === 0">Order Placed</span>
                      <span *ngIf="order.order_status === 1">Unfullfiled</span>
                      <span *ngIf="order.order_status === 2">Fullfiled</span>
                      <ng-container *ngIf="order.shipping_type === 'Pickup'">
                        <span *ngIf="order.order_status === 3">Collected</span>
                      </ng-container>
                      <ng-container *ngIf="order.shipping_type !== 'Pickup'">
                        <span *ngIf="order.order_status === 3 && order.shipping_type!='in_store'">Shipped </span>
                        <span *ngIf="order.order_status === 3 && order.shipping_type=='in_store'">Collected</span>
                      </ng-container>
                      <span *ngIf="order.order_status === 4" class="pending_paid">Canceled</span>
                      <span *ngIf="order.order_status === 5">Aborted</span>
                      <span *ngIf="order.order_status === 6">On Hold</span>
                      <span *ngIf="order.order_status === 7" class="pending_paid">Failed Consignment</span>
                      <span *ngIf="order.order_status === 8">Fulfilled But Failed PDF Creation (Consignment Created)</span>
                      <span *ngIf="order.order_status === 9">Ready to print</span>
                      <span *ngIf="order.order_status === 10">Sent for fulfillment</span>
                      <span *ngIf="order.order_status === 11">Failed AWB</span>
                      <span *ngIf="order.order_status === 12"></span>
                      <span *ngIf="order.order_status === 13">Preorder Paid</span>
                      <span *ngIf="order.order_status === 14">PPO Paid</span>
                      <span *ngIf="order.order_status === 15">Order waiting for approval</span>
                    </td>
<!--
Order status from backend:
0 => Order placed
1 => Unfulfilled
2 => Fulfilled / Consignment Created /completed
3 => Shipped / Collected
4 => Canceled
5 => Aborted
6 => On Hold
7 => Failed Consignment
8 => Fulfilled But Failed PDF Creation (Consignment Created)
9 => Ready to print
10 => Sent for fulfillment
11 => Failed AWB
12 => 
13 => Preorder Paid
14 => PPO Paid
15 => Order waiting for approval
-->

                    <td>
                      <span *ngIf="order.payment_status == 1" class="paid">Paid</span>
                      <span *ngIf="order.payment_status == 0" class="pending_paid">Pending</span>

                    <td>{{ order.payment_type | uppercase }}</td>
                    <td>{{ order.shipping_type | uppercase }}</td>
                    <td>{{ order.jntconsign?.bill_code ? order.jntconsign.bill_code: '---------' }}</td>
                    <td> RM{{ order.transaction_amount | number: "1.2-2" }}</td>
                    <td>
                      <app-feather-icons title="View" (click)="viewOrderDetails(order.id)" [icon]="'eye'"></app-feather-icons>
                    </td>
                  </tr>

                </tbody>
              </table>
            </div>
            <div class="btn-popup pull-right" *ngIf="lastPage > 1 && orders?.length > 0">
              <pagination-with-api [apiUrl]="apiUrl" [totalPage]="totalPage" (valueChanged)="pageChanged($event)">
              </pagination-with-api>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
