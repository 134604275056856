import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from 'src/app/@services/api.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-manual-consignment-dialog',
  templateUrl: './manual-consignment-dialog.component.html',
  styleUrls: ['./manual-consignment-dialog.component.scss']
})
export class ManualConsignmentDialogComponent implements OnInit {

  @Input() order: any = {};
  billCode:any;
  constructor(
    private api: ApiService,
    public activeModal: NgbActiveModal,
  ) { }

  ngOnInit(): void {
  }
  confirm(){
    var model = {
      order_id: this.order.id,
      shipping_type: this.order.shipping_type,
      bill_code: this.billCode
    }
    if(!this.billCode)return;
        this.api.create(this.api.order2Api +"update-consignment",model).subscribe((res:any) =>{   
            if(res.status = 'ok'){              
              Swal.fire({
                position: 'center',
                icon: 'success',
                title: 'Consignment update manually!',
                showConfirmButton: false,
                timer: 1500
              });
              this.activeModal.close();
            }
        })
  }
close() {
    this.activeModal.dismiss('close');
  }
}
