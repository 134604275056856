<!-- Container-fluid starts-->

<div class="container-fluid">

    <div class="row">
        <div class="col-xl-3 col-md-6 xl-50">
            <div class="card o-hidden widget-cards">
                <div class="bg-warning card-body">
                    <div class="media static-top-widget row">
                        <div class="icons-widgets col-4">
                            <div class="align-self-center text-center">
                                <app-feather-icons [icon]="'shopping-bag'" class="font-warning"></app-feather-icons>
                            </div>
                        </div>
                        <div class="media-body col-8"><span class="m-0">Open Orders</span>
                            <h3 class="mb-0"># <span class="counter" [CountTo]="openOrder" [from]="0"
                                    [duration]="2">0</span></h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-3 col-md-6 xl-50">
            <div class="card o-hidden  widget-cards">
                <div class="bg-secondary card-body">
                    <div class="media static-top-widget row">
                        <div class="icons-widgets col-4">
                            <div class="align-self-center text-center">
                                <app-feather-icons [icon]="'box'" class="font-secondary"></app-feather-icons>
                            </div>
                        </div>
                        <div class="media-body col-8"><span class="m-0">Total Products</span>
                            <h3 class="mb-0"># <span class="counter" [CountTo]="totalProducts" [from]="0"
                                    [duration]="2">0</span></h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-3 col-md-6 xl-50">
            <div class="card o-hidden widget-cards">
                <div class="bg-primary card-body">
                    <div class="media static-top-widget row">
                        <div class="icons-widgets col-4">
                            <div class="align-self-center text-center">
                                <app-feather-icons [icon]="'gift'" class="font-primary"></app-feather-icons>
                            </div>
                        </div>
                        <div class="media-body col-8"><span class="m-0">Active Promotions</span>
                            <h3 class="mb-0"># <span class="counter" [CountTo]="activePromotions" [from]="0"
                                    [duration]="2">0</span></h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-3 col-md-6 xl-30">
            <div class="card o-hidden widget-cards">
                <div class="bg-danger card-body">
                    <div class="media static-top-widget row">
                        <div class="icons-widgets col-4">
                            <div class="align-self-center text-center">
                                <app-feather-icons [icon]="'users'" class="font-danger"></app-feather-icons>
                            </div>
                        </div>
                        <div class="media-body col-8"><span class="m-0">Total CS</span>
                            <h3 class="mb-0"># <span class="counter"
                                    [CountTo]="totalDropship + userSummary?.total_ds_under_ds" [from]="0"
                                    [duration]="2">0</span></h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="col-xl-6 xl-100">
            <div class="card">
                <div class="card-header">
                    <h5>Market Value</h5>
                </div>
                <div class="card-body">
                    <div class="market-chart">
                        <x-chartist class="board-chart ct-golden-section" [data]="chart3.data" [type]="chart3.type"
                            [options]="chart3.options" [events]="chart3.events">
                        </x-chartist>
                    </div>
                </div>
            </div>
        </div> -->
        <!-- <div class="col-xl-6 xl-100">
            <div class="card">
                <div class="card-header">
                    <h5>Latest Orders</h5>
                </div>
                <div class="card-body">
                    <div class="user-status table-responsive latest-order-table">
                        <table class="table table-bordernone">
                            <thead>
                                <tr>
                                    <th scope="col">Order ID</th>
                                    <th scope="col">Order Total</th>
                                    <th scope="col">Payment Method</th>
                                    <th scope="col">Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>1</td>
                                    <td class="digits">$120.00</td>
                                    <td class="font-danger">Bank Transfers</td>
                                    <td class="digits">On Way</td>
                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td class="digits">$90.00</td>
                                    <td class="font-secondary">Ewallets</td>
                                    <td class="digits">Delivered</td>
                                </tr>
                                <tr>
                                    <td>3</td>
                                    <td class="digits">$240.00</td>
                                    <td class="font-warning">Cash</td>
                                    <td class="digits">Delivered</td>
                                </tr>
                                <tr>
                                    <td>4</td>
                                    <td class="digits">$120.00</td>
                                    <td class="font-primary">Direct Deposit</td>
                                    <td class="digits">$6523</td>
                                </tr>
                                <tr>
                                    <td>5</td>
                                    <td class="digits">$50.00</td>
                                    <td class="font-primary">Bank Transfers</td>
                                    <td class="digits">Delivered</td>
                                </tr>
                            </tbody>
                        </table>
                        <a href="javascript:void(0)" class="btn btn-primary">View All Orders</a>
                    </div>
                </div>
            </div>
        </div> -->
        <!-- <div class="col-sm-12">
            <div class="card">
                <div class="card-header">
                    <h5>Filter</h5>
                </div>
                <div class="card-body sell-graph">
                    <div class="row px-2">
                        <div class="col-xl-3 col-md-4 p-1">
                            <div class="input-group">
                                <input class="form-control" placeholder="FORM" name="dp1" [(ngModel)]="fromDate"
                                    name="fromDate" ngbDatepicker #d1="ngbDatepicker">
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary calendar" (click)="d1.toggle()"
                                        type="button">
                                        <i style="font-size:19px" class="fa">&#xf073;</i>
                                    </button>

                                </div>
                            </div>
                        </div>
                        <div class="col-xl-3 col-md-4 p-1">
                            <div class="input-group">
                                <input class="form-control" placeholder="TO" name="dp2" [(ngModel)]="toDate"
                                    name="toDate" ngbDatepicker #d2="ngbDatepicker">
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary calendar" (click)="d2.toggle()"
                                        type="button">
                                        <i style="font-size:19px" class="fa">&#xf073;</i>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-3 col-md-4 p-1">
                            <button type="button" class="btn btn-primary mr-1"
                                (click)="submitFilter()">SUBMIT</button>
                        </div>
                    </div>

                </div>
            </div>
        </div> -->
        <div class="col-sm-6">
            <div class="card">
                <div class="card-header">
                    <h5>Cumulative Sales (RM{{cumulativeSales}})</h5>
                    <form [formGroup]="formFilter">
                        <div class="row px-2">
                            <div class="col-xl-3 col-md-4 p-1">
                                <div class="input-group">
                                    <input class="form-control" [showWeekdays]="false" placeholder="FORM" name="dp1"
                                        formControlName="fromDate" ngbDatepicker #d1="ngbDatepicker">
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar datepicker-only-month-select"
                                            (click)="d1.toggle()" type="button">
                                            <i style="font-size:19px" class="fa">&#xf073;</i>
                                        </button>

                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-3 col-md-4 p-1">
                                <div class="input-group">
                                    <input class="form-control" placeholder="TO" name="dp2" formControlName="toDate"
                                        ngbDatepicker #d2="ngbDatepicker">
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="d2.toggle()"
                                            type="button">
                                            <i style="font-size:19px" class="fa">&#xf073;</i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-3 col-md-4 p-1">
                                <button type="button" class="btn btn-primary mr-1"
                                    (click)="submitFilter('Cumulative Sales')">SUBMIT</button>
                            </div>
                        </div>
                    </form>
                </div>

                <div class="card-body sell-graph">
                    <canvas baseChart id="myGraph" [datasets]="cumulativeSalesChartData"
                        [labels]="cumulativeSalesChartLabels" [options]="lineChartOptions" [colors]="lineChartColors"
                        [legend]="lineChartLegend" [chartType]="lineChartType" (chartHover)="chartHovered($event)"
                        (chartClick)="chartClicked($event)"></canvas>
                </div>
            </div>
        </div>
        <div class="col-sm-6">
            <div class="card">
                <div class="card-header">
                    <h5>Cumulative Orders ({{cumulativeOrders}})</h5>
                    <form [formGroup]="formFilter">
                        <div class="row px-2">
                            <div class="col-xl-3 col-md-4 p-1">
                                <div class="input-group">
                                    <input class="form-control" placeholder="FORM" name="dp3" formControlName="fromDate"
                                        ngbDatepicker #d3="ngbDatepicker">
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="d3.toggle()"
                                            type="button">
                                            <i style="font-size:19px" class="fa">&#xf073;</i>
                                        </button>

                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-3 col-md-4 p-1">
                                <div class="input-group">
                                    <input class="form-control" placeholder="TO" name="dp4" formControlName="toDate"
                                        ngbDatepicker #d4="ngbDatepicker">
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="d4.toggle()"
                                            type="button">
                                            <i style="font-size:19px" class="fa">&#xf073;</i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-3 col-md-4 p-1">
                                <button type="button" class="btn btn-primary mr-1"
                                    (click)="submitFilter('Cumulative Orders')">SUBMIT</button>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="card-body sell-graph">
                    <canvas baseChart id="myGraph" [datasets]="cumulativeOrdersChartData"
                        [labels]="cumulativeOrdersChartLabels" [options]="lineChartOptions" [colors]="lineChartColors"
                        [legend]="lineChartLegend" [chartType]="lineChartType" (chartHover)="chartHovered($event)"
                        (chartClick)="chartClicked($event)"></canvas>
                </div>
            </div>
        </div>
        <!-- <div class="col-sm-6">
            <div class="card">
                <div class="card-header">
                    <h5>Cumulative Billplz</h5>
                </div>
                <div class="card-body sell-graph">
                    <canvas baseChart id="myGraph" [datasets]="lineChartData" [labels]="lineChartLabels"
                        [options]="lineChartOptions" [colors]="lineChartColors" [legend]="lineChartLegend"
                        [chartType]="lineChartType" (chartHover)="chartHovered($event)"
                        (chartClick)="chartClicked($event)"></canvas>
                </div>
            </div>
        </div> -->
        <!-- <div class="col-xl-6 xl-100">
            <div class="card height-equal">
                <div class="card-header">
                    <h5>Products Cart</h5>
                </div>
                <div class="card-body">
                    <div class="user-status table-responsive products-table">
                        <table class="table table-bordernone mb-0">
                            <thead>
                                <tr>
                                    <th scope="col">Details</th>
                                    <th scope="col">Quantity</th>
                                    <th scope="col">Status</th>
                                    <th scope="col">Price</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Simply dummy text of the printing</td>
                                    <td class="digits">1</td>
                                    <td class="font-primary">Pending</td>
                                    <td class="digits">$6523</td>
                                </tr>
                                <tr>
                                    <td>Long established</td>
                                    <td class="digits">5</td>
                                    <td class="font-secondary">Cancle</td>
                                    <td class="digits">$6523</td>
                                </tr>
                                <tr>
                                    <td>sometimes by accident</td>
                                    <td class="digits">10</td>
                                    <td class="font-secondary">Cancle</td>
                                    <td class="digits">$6523</td>
                                </tr>
                                <tr>
                                    <td>Classical Latin literature</td>
                                    <td class="digits">9</td>
                                    <td class="font-primary">Return</td>
                                    <td class="digits">$6523</td>
                                </tr>
                                <tr>
                                    <td>keep the site on the Internet</td>
                                    <td class="digits">8</td>
                                    <td class="font-primary">Pending</td>
                                    <td class="digits">$6523</td>
                                </tr>
                                <tr>
                                    <td>Molestiae consequatur</td>
                                    <td class="digits">3</td>
                                    <td class="font-secondary">Cancle</td>
                                    <td class="digits">$6523</td>
                                </tr>
                                <tr>
                                    <td>Pain can procure</td>
                                    <td class="digits">8</td>
                                    <td class="font-primary">Return</td>
                                    <td class="digits">$6523</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div> -->
        <!-- <div class="col-xl-6 xl-100">
            <div class="card height-equal">
                <div class="card-header">
                    <h5>Empolyee Status</h5>
                </div>
                <div class="card-body">
                    <div class="user-status table-responsive products-table">
                        <table class="table table-bordernone mb-0">
                            <thead>
                                <tr>
                                    <th scope="col">Name</th>
                                    <th scope="col">Designation</th>
                                    <th scope="col">Skill Level</th>
                                    <th scope="col">Experience</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td class="bd-t-none u-s-tb">
                                        <div class="align-middle image-sm-size"><img
                                                class="img-radius align-top m-r-15 rounded-circle"
                                                src="assets/images/dashboard/user2.jpg" alt="" data-original-title=""
                                                title="">
                                            <div class="d-inline-block">
                                                <h6>John Deo <span class="text-muted digits">(14+ Online)</span></h6>
                                            </div>
                                        </div>
                                    </td>
                                    <td>Designer</td>
                                    <td>
                                        <div class="progress-showcase">
                                            <div class="progress" style="height: 8px;">
                                                <div class="progress-bar bg-primary" role="progressbar"
                                                    style="width: 30%" aria-valuenow="50" aria-valuemin="0"
                                                    aria-valuemax="100"></div>
                                            </div>
                                        </div>
                                    </td>
                                    <td class="digits">2 Year</td>
                                </tr>
                                <tr>
                                    <td class="bd-t-none u-s-tb">
                                        <div class="align-middle image-sm-size"><img
                                                class="img-radius align-top m-r-15 rounded-circle"
                                                src="assets/images/dashboard/user1.jpg" alt="" data-original-title=""
                                                title="">
                                            <div class="d-inline-block">
                                                <h6>Holio Mako <span class="text-muted digits">(250+ Online)</span></h6>
                                            </div>
                                        </div>
                                    </td>
                                    <td>Developer</td>
                                    <td>
                                        <div class="progress-showcase">
                                            <div class="progress" style="height: 8px;">
                                                <div class="progress-bar bg-secondary" role="progressbar"
                                                    style="width: 70%" aria-valuenow="50" aria-valuemin="0"
                                                    aria-valuemax="100"></div>
                                            </div>
                                        </div>
                                    </td>
                                    <td class="digits">3 Year</td>
                                </tr>
                                <tr>
                                    <td class="bd-t-none u-s-tb">
                                        <div class="align-middle image-sm-size"><img
                                                class="img-radius align-top m-r-15 rounded-circle"
                                                src="assets/images/dashboard/man.png" alt="" data-original-title=""
                                                title="">
                                            <div class="d-inline-block">
                                                <h6>Mohsib lara<span class="text-muted digits">(99+ Online)</span></h6>
                                            </div>
                                        </div>
                                    </td>
                                    <td>Tester</td>
                                    <td>
                                        <div class="progress-showcase">
                                            <div class="progress" style="height: 8px;">
                                                <div class="progress-bar bg-primary" role="progressbar"
                                                    style="width: 60%" aria-valuenow="50" aria-valuemin="0"
                                                    aria-valuemax="100"></div>
                                            </div>
                                        </div>
                                    </td>
                                    <td class="digits">5 Month</td>
                                </tr>
                                <tr>
                                    <td class="bd-t-none u-s-tb">
                                        <div class="align-middle image-sm-size"><img
                                                class="img-radius align-top m-r-15 rounded-circle"
                                                src="assets/images/dashboard/user.png" alt="" data-original-title=""
                                                title="">
                                            <div class="d-inline-block">
                                                <h6>Hileri Soli <span class="text-muted digits">(150+ Online)</span>
                                                </h6>
                                            </div>
                                        </div>
                                    </td>
                                    <td>Designer</td>
                                    <td>
                                        <div class="progress-showcase">
                                            <div class="progress" style="height: 8px;">
                                                <div class="progress-bar bg-secondary" role="progressbar"
                                                    style="width: 30%" aria-valuenow="50" aria-valuemin="0"
                                                    aria-valuemax="100"></div>
                                            </div>
                                        </div>
                                    </td>
                                    <td class="digits">3 Month</td>
                                </tr>
                                <tr>
                                    <td class="bd-t-none u-s-tb">
                                        <div class="align-middle image-sm-size"><img
                                                class="img-radius align-top m-r-15 rounded-circle"
                                                src="assets/images/dashboard/designer.jpg" alt=""
                                                data-original-title="" title="">
                                            <div class="d-inline-block">
                                                <h6>Pusiz bia <span class="text-muted digits">(14+ Online)</span></h6>
                                            </div>
                                        </div>
                                    </td>
                                    <td>Designer</td>
                                    <td>
                                        <div class="progress-showcase">
                                            <div class="progress" style="height: 8px;">
                                                <div class="progress-bar bg-primary" role="progressbar"
                                                    style="width: 90%" aria-valuenow="50" aria-valuemin="0"
                                                    aria-valuemax="100"></div>
                                            </div>
                                        </div>
                                    </td>
                                    <td class="digits">5 Year</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div> -->
        <div class="col-sm-12">
            <div class="card">
                <!-- <div class="card-header">
                    <h5>Sales Status</h5>
                </div> -->
                <div class="card-body">
                    <div class="row">
                        <div class="col-xl-6 col-sm-6 xl-50">
                            <div class="order-graph">
                                <h6>Orders By Location</h6>
                                <div class="chart-block chart-vertical-center">
                                    <ngx-charts-pie-chart class="pie-colours-1" [view]="view"
                                        [scheme]="doughnutChartColorScheme" [results]="ordersByLocationChartData"
                                        [explodeSlices]="false" [labels]="doughnutChartShowLabels" [arcWidth]=0.50
                                        [doughnut]="true" [animations]="false" [tooltipDisabled]="doughnutChartTooltip"
                                        [gradient]="doughnutChartGradient">
                                    </ngx-charts-pie-chart>
                                </div>
                                <div class="order-graph-bottom">
                                    <ng-container *ngFor="let item of orderByLocation">
                                        <div class="media">
                                            <div class="order-color-primary"></div>
                                            <div class="media-body">
                                                <h6 class="mb-0">{{item.state}} <span
                                                        class="pull-right">{{item.total_order}}</span></h6>
                                            </div>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-6 col-sm-6 xl-50">
                            <div class="order-graph sm-order-space">
                                <h6>Sales By Location</h6>
                                <div class="peity-chart-dashboard text-center chart-vertical-center">
                                    <ngx-charts-pie-chart class="pie-colours-1" [view]="view"
                                        [scheme]="doughnutChartColorScheme" [results]="salesByLocationChartData"
                                        [explodeSlices]="false" [labels]="doughnutChartShowLabels" [arcWidth]=0.50
                                        [doughnut]="true" [animations]="false" [tooltipDisabled]="doughnutChartTooltip"
                                        [gradient]="doughnutChartGradient">
                                    </ngx-charts-pie-chart>
                                </div>
                                <div class="order-graph-bottom sales-location">
                                    <ng-container *ngFor="let item of orderByLocation">
                                        <div class="media">
                                            <div class="order-shape-primary"></div>
                                            <div class="media-body">
                                                <h6 class="mb-0 mr-0">{{item.state}} <span
                                                        class="pull-right">{{item.total_amount}}</span></h6>
                                            </div>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="col-xl-6 xl-100">
                            <div class="order-graph xl-space">
                                <h6>Revenue for last month</h6>
                                <div class="ct-4 flot-chart-container">
                                    <x-chartist [type]="chart5.type" [data]="chart5.data" [options]="chart5.options">
                                    </x-chartist>
                                </div>
                            </div>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>
<!-- Container-fluid Ends-->

<div class="loader" *ngIf="loader == true">