import { Role } from './role';
export class Staff {
    id: number;
    user_name: string;
    email: string;
    user_role: number;
    password: string;
    token: string | null;
    user_id: number;
    user: any | null;
    uplink: number; 
    // token: string | null;

}