import { Injectable } from "@angular/core";
import Swal from "sweetalert2";

@Injectable({
    providedIn: "root",
  })
export class SweetalertService {

  constructor() { }

  fireSwal(icon, title){
    Swal.fire({
        position: "center",
        icon: icon,
        title: title,
        showConfirmButton: false,
        timer: 1500,
      });
  }
}