import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from 'src/app/@services/api.service';
import { ExcelService } from 'src/app/@services/excel.service';
import { UserService } from 'src/app/@services/user.service';
import Swal from 'sweetalert2';
import { DropshipEditPasswordComponent } from '../dropship-edit-password/dropship-edit-password.component';

@Component({
  selector: 'app-dropship-list',
  templateUrl: './dropship-list.component.html',
  styleUrls: ['./dropship-list.component.scss']
})

export class DropshipListComponent implements OnInit {
  dropship: any;
  // viewd = false;
  dsid = "";
  searchText: string = '';
  page: any;
  lang: any;
  userRole: any;
  userId: any;
  pages: any[] = [];
  totalPage: number;
  lastPage: any;
  currentPage: any;
  previous = 0;
  next = 2;

  regLink = '';
  pageData;

  staffs: any;

  uplineDs:any

  loader: boolean = false;

  public dropshipFilterModel: any = {
    uplink: '',
    uplink_ds: '',
  }
  constructor(
    private userService: UserService,
    private api: ApiService,
    private modalService: NgbModal,
    private excelService: ExcelService,
    private router: Router,

  ) { }

  ngOnInit(): void {
    let user = this.userService.getUserAuth();
    this.userRole = user.role?.role;
    this.userId = user.id;
    this.regLink = this.api.siteLink +'registration/'+ 0 +'-'+ 0;

    this.getdropship();
  }
  // test(drid){
  //   if (this.viewd==true){ this.viewd=false;}
  //   else if (this.viewd==false){ this.viewd=true;}
  //   Swal.fire(
  //     'In progress!',
  //     'Dropship ID : '+drid ,
  //     'info'
  //   );
  // }
  // show3menu(dsid){
  //   if (this.viewd==true){ this.viewd=false;}
  //   else if (this.viewd==false){ this.viewd=true;}
  //   this.dsid=dsid;
  // }
  // apiUrl2  :any;
  getDownline(drid){
    // if (this.viewd==true){ this.viewd=false;}
    // else if (this.viewd==false){ this.viewd=true;}
    var apiUrl = this.api.userApi + 'my-downline/' + drid + '?page=' + 1 + '&search=';
    this.api.get(apiUrl).subscribe((res: any) => {
    })
  }
  showDownline(dropshId: any,dropshMail: any) {
    //console.log(dropshId);
    localStorage.setItem("mail",dropshMail);
    this.router.navigate(["users/downline-list/",dropshId])
  }

  showGenealogy(dropsh) {
    //console.log(dropshId);
    // localStorage.setItem("mail",dropshMail);
    this.router.navigate(["users/genealogy/",JSON.stringify(dropsh)])
  }

  uplineSalutation: any = null;
  getSalutationCode(s: any) {
    // use this here
    // let s = res.salutation;
    const words = s.split(' ');
    let sf = '';
    for (const word of words) {
      if (word.length > 0) {
        sf += word[0];
      }
    }
    this.uplineSalutation = sf;
  }

  get apiUrl(){
    if (this.userRole === 'Admin' || this.userRole === 'Manager') {
      return this.api.userApi +  'dropship-user/0?page=' + 1 + '&search=' + this.searchText + '&uplink=' + this.dropshipFilterModel.uplink + '&uplink_ds=' + this.dropshipFilterModel.uplink_ds;
    } else {
      return this.api.userApi + 'dropship-user/' + this.userId + '?page=' + 1 + '&search=' + this.searchText;
    }
  }
  getdropship() {
    this.loader = true;
    if(this.uplineDs){
      this.dropshipFilterModel.uplink_ds = this.uplineDs.id
    }
    this.api.get(this.apiUrl).subscribe((res: any) => {
      this.dropship = res.data;
      

      this.setSalutation();
      
      this.lastPage = res.last_page;
      this.currentPage = res.current_page;
      this.totalPage = res.last_page;
      this.loader = false;
      // this.firstPageChanged(1, 1);
      //console.log("data test! ", this.dropship);
      //console.log(res);
    })
  }

  setSalutation() {
    this.dropship.forEach(s => {
      // console.log(s);
      let cc = '[N/A]';
      if(s.details!=null) { 
      if(s.details.country == 'Malaysia') { cc='MY'}
      if(s.details.country == 'Brunei') { cc='BR'}
      if(s.details.country == 'Singapore') { cc='SG'}
      this.getSalutationCode(s.salutation);
      s.salt = this.uplineSalutation+'-'+s.id+cc;
      } else {
        s.salt = this.uplineSalutation+'-'+s.id+cc;
      }
    });
  }
  search(searchKey) {
    if(searchKey.length >=3){
      this.getdropship();
    }else{
      this.getdropship();
    }
  }
  edit(content) {
    const modalRef = this.modalService.open(DropshipEditPasswordComponent, { ariaLabelledBy: 'modal-basic-title', backdrop:true });
    (<DropshipEditPasswordComponent>modalRef.componentInstance).data = content;
    modalRef.result.then((result) => {
      if(result.status == 1){
      }
    }).catch( (result) => {
    });
  }

  agentSearch(searchKey, event) {
    if (event.key === "Enter") {
      if(searchKey.length >=3){
        this.getdropship();
      }else{
        this.getdropship();
      }
    }
  }

  agentSearchEnter(searchKey){
    if(searchKey.length >=3){
      this.searchText = searchKey;
      this.getdropship();
    }else{
      this.getdropship();
    }
  }
  pageChanged(dropship:any){
    //console.log(dropship);
    if(dropship == '')return
    this.dropship = dropship;
    this.dropship.forEach(s => {
      this.getSalutationCode(s.salutation);
      s.salt = this.uplineSalutation+'-'+s.id+'MY';;
    });
    
    window.scrollBy(0, -1990);


    // this.dropship.forEach(d => {
    //   console.log(d.details.country)
    // });
    
    this.setSalutation();

   }

   delete(id) {
    var model = {
      id: id,
      status: 2
    }
    Swal.fire({
      title: 'Are you sure?',
      text: 'You won\'t be able to revert this!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        this.api.create(this.api.userApi + 'user-status', model).subscribe(res => {
          this.getdropship();
          Swal.fire(
            'Deleted!',
            'Dropship has been deleted.',
            'success'
          );
        });
      }
    });
  }

  dropshipExport:any [] = [];
  exportLoader = false;
  totalPageForExport: number = 1
  async exportAsXLSX() {
    this.loader = true;
    this.exportLoader = true;
    for (var pageNumber = 1; pageNumber <= this.totalPageForExport; pageNumber++) {
        await this.getExportDataByPage(pageNumber)
    }

      var exportOrder = [];
      this.dropshipExport.forEach(e => {
        var expo = [];
        expo['ID'] = 'OBP-'+ e?.id +'MY';
        expo['First name'] = e?.first_name;
        expo['Last name'] = e?.last_name;
        expo['Email'] = e?.email;
        expo['Phone Number'] = e?.phone;
        expo['Address Line 1'] = e?.address_line1;
        expo['Address Line 2'] = e?.address_line2;
        expo['State'] = e?.state;
        expo['Post Code'] = e?.post_code;
        expo['Country'] = e?.country;
        // expo['CAE'] = e?.cae;
        expo['Upline'] = e?.uplink_ds;
        expo['Upline Email'] = e?.uplink_email;
        // expo['Credit Balance'] = e?.credit_balance;
        exportOrder.push(expo);
      });
      console.log(exportOrder);

      this.excelService.exportAsExcelFile(exportOrder, 'Dropship Report');
      this.exportLoader = false;
      this.loader = false;

  }

  getExportDataByPage(pageNumber){
    return new Promise((resolve,reject)=>{
    if (this.userRole === 'Admin'  || this.userRole === 'Manager') {
      var apiurl = this.api.userApi +  'dropship-user/0?page=' + pageNumber + '&search=' + this.searchText + '&uplink=' + '&uplink_ds='  + '&per_page=2000';
    } else {
      var apiurl = this.api.userApi + 'dropship-user/' + this.userId + '?page=' + pageNumber + '&search=' + this.searchText + '&per_page=2000';
    }
    this.api.get(apiurl).subscribe((res: any) => {
    this.totalPageForExport = res.last_page
      res.data.forEach(e => {
        this.dropshipExport.push({
          id: e.id,
          first_name: e.details?.first_name,
          last_name: e.details?.last_name,
          email: e?.email,
          phone: e.details?.phone,
          address_line1: e.details?.address_line1,
          address_line2: e.details?.address_line2,
          state: e.details?.state,
          post_code: e.details?.post_code,
          country: e.details?.country,
          cae: e?.cae,
          uplink_ds: e?.uplink_ds,
          credit_balance: e?.credit_balance
        }) ;
      });
  resolve(res)
  }, error=>{
    Swal.fire("Error", "File too large")
    // this.exportLoader = false
  })
});

  }

}
