<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-header ">
                  <div class="row">
                  <div class="col-sm-9">
                    <h5 >CUSTOMER LISTS</h5>
                    </div>
                    <div class="col-sm-3">
                    <div class="input-group">
                      <input type="text" (keyup.enter)="search(searchText)"  [(ngModel)]="searchText" class="form-control " placeholder="Search">
                      <div class="input-group-append">
                        <button (click)="search(searchText)" class="btn btn-secondary" type="button">
                          <i class="fa fa-search"></i>
                        </button>
                      </div>
                    </div>
                    </div>
                  </div>
                </div>
                <div class="card-body">
                  <!-- <div class="btn-popup pull-right">
                    <button type="button" class="btn btn-secondary" 
                        >ADD DROPSHIP</button>
                        </div> -->
                    <div id="batchDelete" class="custom-datatable">
                        <div class="table-responsive">
                            <!-- <ng2-smart-table [settings]="settings" [source]="digital_list"></ng2-smart-table> -->
                            <table class="table">
                                <thead class="thead-dark">
                                  <tr>
                                    <!-- <th>#</th> -->
                                    <th>NAME</th>
                                    <th>EMAIL</th>
                                    <th>PHONE</th>
                                    <th>ADDRESS</th>
                                    <th>STATUS</th>
                                    <th>ACTION</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr *ngFor="let customer of customers; let i = index">
                                    <td>{{ customer.user_name }}</td>
                                    <td>{{ customer.email }}</td>
                                    <td>{{ customer.details?.phone ?  customer.details.phone:'------------'}}</td>
                                    <td>{{ customer.details?.address_line1 ?  customer.details.address_line1:'------------'}}</td>
                                    <td *ngIf="customer.status == 1">
                                      <span class="statusCircles1"></span>
                                    </td>
                                    <td *ngIf="customer.status == 0">
                                      <span class="statusCircles2"></span>
                                      </td>
                                      <td>
                                      <app-feather-icons title="Delete" (click)="delete(customer.id)" [icon]="'trash-2'"></app-feather-icons>
                                      </td>
                                  </tr>
                                </tbody>
                              </table>
                        </div>
                        <div class="btn-popup pull-right" *ngIf="lastPage > 1 && customers?.length > 0">
                          <pagination-with-api [apiUrl]="apiUrl" [totalPage]="totalPage" (valueChanged)="pageChanged($event)"></pagination-with-api>
                      </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>