<!-- Container-fluid starts-->
<div class="loader" *ngIf="loader">
<mat-spinner style="margin:0 auto;"></mat-spinner>
</div>
<div class="container-fluid">

    <div class="row">
        <div class="col-xl-3 col-md-6 xl-50">
            <div class="card o-hidden widget-cards">
                <div class="bg-warning card-body">
                    <div class="media static-top-widget row">
                        <div class="icons-widgets col-4">
                            <div class="align-self-center text-center">
                                <app-feather-icons [icon]="'shopping-bag'" class="font-warning"></app-feather-icons>
                            </div>
                        </div>
                        <div class="media-body col-8"><span class="m-0">Open Orders</span>
                            <h3 class="mb-0"># <span class="counter" [CountTo]="openOrder" [from]="0"
                                    [duration]="2">0</span></h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-3 col-md-6 xl-50">
            <div class="card o-hidden  widget-cards">
                <div class="bg-secondary card-body">
                    <div class="media static-top-widget row">
                        <div class="icons-widgets col-4">
                            <div class="align-self-center text-center">
                                <app-feather-icons [icon]="'box'" class="font-secondary"></app-feather-icons>
                            </div>
                        </div>
                        <div class="media-body col-8"><span class="m-0">Total Products</span>
                            <h3 class="mb-0"># <span class="counter" [CountTo]="totalProducts" [from]="0"
                                    [duration]="2">0</span></h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-3 col-md-6 xl-50">
            <div class="card o-hidden widget-cards">
                <div class="bg-primary card-body">
                    <div class="media static-top-widget row">
                        <div class="icons-widgets col-4">
                            <div class="align-self-center text-center">
                                <app-feather-icons [icon]="'gift'" class="font-primary"></app-feather-icons>
                            </div>
                        </div>
                        <div class="media-body col-8"><span class="m-0">Active Promotions</span>
                            <h3 class="mb-0"># <span class="counter" [CountTo]="activePromotions" [from]="0"
                                    [duration]="2">0</span></h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-3 col-md-6 xl-30">
            <div class="card o-hidden widget-cards">
                <div class="bg-danger card-body">
                    <div class="media static-top-widget row">
                        <div class="icons-widgets col-4">
                            <div class="align-self-center text-center">
                                <app-feather-icons [icon]="'users'" class="font-danger"></app-feather-icons>
                            </div>
                        </div>
                        <div class="media-body col-8"><span class="m-0">Total DS</span>
                            <h3 class="mb-0"># <span class="counter"
                                    [CountTo]="totalDropship + userSummary?.total_ds_under_ds" [from]="0"
                                    [duration]="2">0</span></h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="col-xl-6 xl-100">
            <div class="card">
                <div class="card-header">
                    <h5>Market Value</h5>
                </div>
                <div class="card-body">
                    <div class="market-chart">
                        <x-chartist class="board-chart ct-golden-section" [data]="chart3.data" [type]="chart3.type"
                            [options]="chart3.options" [events]="chart3.events">
                        </x-chartist>
                    </div>
                </div>
            </div>
        </div> -->
        <div class="col-sm-12" *ngIf="dropshipByRank">
            <div class="card">
                <!-- <div class="card-header">
                    <h5>Sales Status</h5>
                </div> -->
                <div class="card-body">
                    <div class="row">
                        <div class="col-xl-3 col-sm-6 xl-50">
                            <div class="order-graph sm-order-space">
                                <h6>Dropship By Rank</h6>
                                <div class="order-graph-bottom sales-location">
                                    <div class="media">
                                        <div class="order-shape-primary"></div>
                                        <div class="media-body">
                                            <h6 class="mb-0 mr-0">OBSESS BUSINESS PARTNER <span
                                                    class="pull-right">{{dropshipByRank.OBP}}</span></h6>
                                        </div>
                                    </div>
                                    <div class="media">
                                        <div class="order-shape-warning"></div>
                                        <div class="media-body">
                                            <h6 class="mb-0 mr-0">OBSESS SILVER PARTNER <span
                                                    class="pull-right">{{dropshipByRank.OSP}}</span></h6>
                                        </div>
                                    </div>
                                    <div class="media">
                                        <div class="order-shape-secondary"></div>
                                        <div class="media-body">
                                            <h6 class="mb-0 mr-0">OBSESS GOLD PARTNER <span
                                                    class="pull-right">{{dropshipByRank.OGP}}</span></h6>
                                        </div>
                                    </div>
                                    <div class="media">
                                        <div class="order-shape-danger"></div>
                                        <div class="media-body">
                                            <h6 class="mb-0 mr-0">OBSESS PLATINUM PARTNER<span
                                                    class="pull-right">{{dropshipByRank.OPP}}</span></h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="col-xl-6 xl-100">
                            <div class="order-graph xl-space">
                                <h6>Revenue for last month</h6>
                                <div class="ct-4 flot-chart-container">
                                    <x-chartist [type]="chart5.type" [data]="chart5.data" [options]="chart5.options">
                                    </x-chartist>
                                </div>
                            </div>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
        <div class="col-sm-12">
            <div class="card">
                <!-- <div class="card-header">
                    <h5>Sales Status</h5>
                </div> -->
                <div class="card-body">
                    <div class="row">
                        <div class="col-xl-4 col-sm-6 xl-50">
                            <div class="order-graph sm-order-space">
                                <h6>Top 10 Personal Sales</h6>
                                <div class="order-graph-bottom sales-location" *ngIf="personalSales">
                                    <ng-container *ngFor="let item of personalSales">
                                    <div class="media">
                                        <div class="order-shape-primary"></div>
                                        <div class="media-body">
                                            <h6 class="mb-0 mr-0">{{item.user_name}} <span
                                                    class="pull-right">RM {{item.my_sale}}</span></h6>
                                        </div>
                                    </div>
                                </ng-container>
                                </div>
                                <div class="" *ngIf="topTenPersonalSales?.length > 0">
                                  <ul class="list-group">
                                      <li *ngFor="let user of topTenPersonalSales; let i = index"
                                          class="list-group-item d-flex justify-content-between align-content-center text-wrap">
                                          <div class="d-flex flex-row list-text">
                                              <div *ngIf="user.photo?.image_80 || user.photo?.image_80 != null; else noImage">
                                                  <img style="border-radius: 50%; margin-right: 10px" width="30px" height="30px"
                                                      [src]="user.photo?.image_80" />
                                              </div>
                                              <ng-template #noImage>
                                                  <i class="fa fa-user" style="margin-right: 10px"></i>
                                              </ng-template>

                                              <span>{{ i + 1 }}. {{ user?.user_details?.first_name }} {{ user?.user_details?.last_name }}
                                                  <br />
                                                  <span style="color: #ae0944">RM {{ user?.my_sale | number: "1.2-2" }}</span>
                                              </span>
                                          </div>
                                      </li>
                                  </ul>
                              </div>
                            </div>
                        </div>

                        <div class="col-xl-4 col-sm-6 xl-50">
                          <div class="order-graph sm-order-space">
                              <h6>Top 10 Affiliate</h6>
                              <div class="" *ngIf="top10Affiliate?.length > 0">
                                <ul class="list-group">
                                    <li *ngFor="let user of top10Affiliate; let i = index"
                                        class="list-group-item d-flex justify-content-between align-content-center text-wrap">
                                        <div class="d-flex flex-row list-text">
                                            <div *ngIf="user.image_80 || user.image_80 != null; else noImage">
                                                <img style="border-radius: 50%; margin-right: 10px" width="30px" height="30px"
                                                    [src]="user.image_80" />
                                            </div>
                                            <ng-template #noImage>
                                                <i class="fa fa-user" style="margin-right: 10px"></i>
                                            </ng-template>

                                            <span>{{ i + 1 }}. {{ user?.first_name }} {{ user?.last_name }}
                                                <br />
                                                <span style="color: #ae0944">{{ user?.total_recruite}}</span>
                                            </span>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                          </div>
                      </div>
                        <!-- <div class="col-xl-4 col-sm-6 xl-50" style="border-left: 1px solid rgb(181, 181, 181); border-right: 1px solid rgb(181, 181, 181);">
                            <div class="order-graph sm-order-space">
                                <h6>Top 10 Group Sales</h6>
                                <div class="order-graph-bottom sales-location" *ngIf="groupSales">
                                    <ng-container *ngFor="let item of groupSales">
                                    <div class="media">
                                        <div class="order-shape-primary"></div>
                                        <div class="media-body">
                                            <h6 class="mb-0 mr-0">{{item.user_name}} <span
                                                    class="pull-right">RM {{item.group_sale}}</span></h6>
                                        </div>
                                    </div>
                                    </ng-container>
                                </div>
                                <div class="card">
                                  <div class="" *ngIf="topTenGrouplSales?.length > 0">
                                      <ul class="list-group">
                                          <li *ngFor="let user of topTenGrouplSales; let i = index"
                                              class="list-group-item d-flex justify-content-between align-content-center text-wrap">
                                              <div class="d-flex flex-row list-text">
                                                  <div *ngIf="user.photo?.image_80 || user.photo?.image_80 != null; else noImage">
                                                      <img style="border-radius: 50%; margin-right: 10px" width="30px" height="30px"
                                                          [src]="user.photo?.image_80" />
                                                  </div>
                                                  <ng-template #noImage>
                                                      <i class="fa fa-user" style="margin-right: 10px"></i>
                                                  </ng-template>

                                                  <span>{{ i + 1 }}. {{ user?.user_name }}
                                                      <br />
                                                      <span style="color: #ae0944">RM {{ user?.group_sale | number: "1.2-2" }}</span>
                                                  </span>
                                              </div>
                                          </li>
                                      </ul>
                                  </div>
                              </div>
                            </div>
                        </div> -->
                        <!-- <div class="col-xl-4 col-sm-6 xl-50">
                            <div class="order-graph sm-order-space">
                                <h6>Top 10 Recruitment</h6>
                                <div class="order-graph-bottom sales-location" *ngIf="recruitment">
                                    <ng-container *ngFor="let item of groupSales">
                                    <div class="media">
                                        <div class="order-shape-primary"></div>
                                        <div class="media-body">
                                            <h6 class="mb-0 mr-0">{{item}} <span
                                                    class="pull-right">{{item.OBP}}</span></h6>
                                        </div>
                                    </div>
                                    </ng-container>
                                </div>
                            </div>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="col-xl-6 xl-100">
            <div class="card">
                <div class="card-header">
                    <h5>Latest Orders</h5>
                </div>
                <div class="card-body">
                    <div class="user-status table-responsive latest-order-table">
                        <table class="table table-bordernone">
                            <thead>
                                <tr>
                                    <th scope="col">Order ID</th>
                                    <th scope="col">Order Total</th>
                                    <th scope="col">Payment Method</th>
                                    <th scope="col">Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>1</td>
                                    <td class="digits">$120.00</td>
                                    <td class="font-danger">Bank Transfers</td>
                                    <td class="digits">On Way</td>
                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td class="digits">$90.00</td>
                                    <td class="font-secondary">Ewallets</td>
                                    <td class="digits">Delivered</td>
                                </tr>
                                <tr>
                                    <td>3</td>
                                    <td class="digits">$240.00</td>
                                    <td class="font-warning">Cash</td>
                                    <td class="digits">Delivered</td>
                                </tr>
                                <tr>
                                    <td>4</td>
                                    <td class="digits">$120.00</td>
                                    <td class="font-primary">Direct Deposit</td>
                                    <td class="digits">$6523</td>
                                </tr>
                                <tr>
                                    <td>5</td>
                                    <td class="digits">$50.00</td>
                                    <td class="font-primary">Bank Transfers</td>
                                    <td class="digits">Delivered</td>
                                </tr>
                            </tbody>
                        </table>
                        <a href="javascript:void(0)" class="btn btn-primary">View All Orders</a>
                    </div>
                </div>
            </div>
        </div> -->
        <!-- <div class="col-sm-12">
            <div class="card">
                <div class="card-header">
                    <h5>Filter</h5>
                </div>
                <div class="card-body sell-graph">
                    <div class="row px-2">
                        <div class="col-xl-3 col-md-4 p-1">
                            <div class="input-group">
                                <input class="form-control" placeholder="FORM" name="dp1" [(ngModel)]="fromDate"
                                    name="fromDate" ngbDatepicker #d1="ngbDatepicker">
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary calendar" (click)="d1.toggle()"
                                        type="button">
                                        <i style="font-size:19px" class="fa">&#xf073;</i>
                                    </button>

                                </div>
                            </div>
                        </div>
                        <div class="col-xl-3 col-md-4 p-1">
                            <div class="input-group">
                                <input class="form-control" placeholder="TO" name="dp2" [(ngModel)]="toDate"
                                    name="toDate" ngbDatepicker #d2="ngbDatepicker">
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary calendar" (click)="d2.toggle()"
                                        type="button">
                                        <i style="font-size:19px" class="fa">&#xf073;</i>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-3 col-md-4 p-1">
                            <button type="button" class="btn btn-primary mr-1"
                                (click)="submitFilter()">SUBMIT</button>
                        </div>
                    </div>

                </div>
            </div>
        </div> -->
        <div class="col-sm-6">
            <div class="card">
                <div class="card-header">
                    <h5>Cumulative Sales (RM{{cumulativeSales}})</h5>
                    <form [formGroup]="formFilter">
                        <div class="row px-2">
                            <div class="col-xl-3 col-md-4 p-1">
                                <div class="input-group">
                                    <input class="form-control" [showWeekdays]="false" placeholder="FORM" name="dp1"
                                        formControlName="fromDate" ngbDatepicker #d1="ngbDatepicker">
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar datepicker-only-month-select"
                                            (click)="d1.toggle()" type="button">
                                            <i style="font-size:19px" class="fa">&#xf073;</i>
                                        </button>

                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-3 col-md-4 p-1">
                                <div class="input-group">
                                    <input class="form-control" placeholder="TO" name="dp2" formControlName="toDate"
                                        ngbDatepicker #d2="ngbDatepicker">
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="d2.toggle()"
                                            type="button">
                                            <i style="font-size:19px" class="fa">&#xf073;</i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-3 col-md-4 p-1">
                                <button type="button" class="btn btn-primary mr-1"
                                    (click)="submitFilter('Cumulative Sales')">SUBMIT</button>
                            </div>
                        </div>
                    </form>
                </div>

                <div class="card-body sell-graph">
                    <canvas baseChart id="myGraph" [datasets]="cumulativeSalesChartData"
                        [labels]="cumulativeSalesChartLabels" [options]="lineChartOptions" [colors]="lineChartColors"
                        [legend]="lineChartLegend" [chartType]="lineChartType" (chartHover)="chartHovered($event)"
                        (chartClick)="chartClicked($event)"></canvas>
                </div>
            </div>
        </div>
        <div class="col-sm-6">
            <div class="card">
                <div class="card-header">
                    <h5>Cumulative Orders ({{cumulativeOrders}})</h5>
                    <form [formGroup]="formFilter">
                        <div class="row px-2">
                            <div class="col-xl-3 col-md-4 p-1">
                                <div class="input-group">
                                    <input class="form-control" placeholder="FORM" name="dp3" formControlName="fromDate"
                                        ngbDatepicker #d3="ngbDatepicker">
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="d3.toggle()"
                                            type="button">
                                            <i style="font-size:19px" class="fa">&#xf073;</i>
                                        </button>

                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-3 col-md-4 p-1">
                                <div class="input-group">
                                    <input class="form-control" placeholder="TO" name="dp4" formControlName="toDate"
                                        ngbDatepicker #d4="ngbDatepicker">
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="d4.toggle()"
                                            type="button">
                                            <i style="font-size:19px" class="fa">&#xf073;</i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-3 col-md-4 p-1">
                                <button type="button" class="btn btn-primary mr-1"
                                    (click)="submitFilter('Cumulative Orders')">SUBMIT</button>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="card-body sell-graph">
                    <canvas baseChart id="myGraph" [datasets]="cumulativeOrdersChartData"
                        [labels]="cumulativeOrdersChartLabels" [options]="lineChartOptions" [colors]="lineChartColors"
                        [legend]="lineChartLegend" [chartType]="lineChartType" (chartHover)="chartHovered($event)"
                        (chartClick)="chartClicked($event)"></canvas>
                </div>
            </div>
        </div>
        <div class="col-sm-6">
            <div class="card">
                <div class="card-header">
                    <h5>Cumulative Billplz Order (RM{{cumulativeBillplzOrders}})</h5>
                    <form [formGroup]="formFilter">
                        <div class="row px-2">
                            <div class="col-xl-3 col-md-4 p-1">
                                <div class="input-group">
                                    <input class="form-control" placeholder="FORM" formControlName="fromDate"
                                        ngbDatepicker #d5="ngbDatepicker">
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="d5.toggle()"
                                            type="button">
                                            <i style="font-size:19px" class="fa">&#xf073;</i>
                                        </button>

                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-3 col-md-4 p-1">
                                <div class="input-group">
                                    <input class="form-control" placeholder="TO" formControlName="toDate" ngbDatepicker
                                        #d6="ngbDatepicker">
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="d6.toggle()"
                                            type="button">
                                            <i style="font-size:19px" class="fa">&#xf073;</i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-3 col-md-4 p-1">
                                <button type="button" class="btn btn-primary mr-1"
                                    (click)="submitFilter('Cumulative Billplz Order')">SUBMIT</button>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="card-body sell-graph">
                    <canvas baseChart id="myGraph" [datasets]="cumulativeBillplzOrdersChartData"
                        [labels]="cumulativeBillplzOrdersChartLabels" [options]="lineChartOptions"
                        [colors]="lineChartColors" [legend]="lineChartLegend" [chartType]="lineChartType"
                        (chartHover)="chartHovered($event)" (chartClick)="chartClicked($event)"></canvas>
                </div>
            </div>
        </div>
        <div class="col-sm-6">
            <div class="card">
                <div class="card-header">
                    <h5>Cumulative Billplz Topup (RM{{cumulativeBillplzTopup}})</h5>
                    <form [formGroup]="formFilter">
                        <div class="row px-2">
                            <div class="col-xl-3 col-md-4 p-1">
                                <div class="input-group">
                                    <input class="form-control" placeholder="FORM" name="dp1" formControlName="fromDate"
                                        ngbDatepicker #d7="ngbDatepicker">
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="d7.toggle()"
                                            type="button">
                                            <i style="font-size:19px" class="fa">&#xf073;</i>
                                        </button>

                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-3 col-md-4 p-1">
                                <div class="input-group">
                                    <input class="form-control" placeholder="TO" name="dp2" formControlName="toDate"
                                        ngbDatepicker #d8="ngbDatepicker">
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="d8.toggle()"
                                            type="button">
                                            <i style="font-size:19px" class="fa">&#xf073;</i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-3 col-md-4 p-1">
                                <button type="button" class="btn btn-primary mr-1"
                                    (click)="submitFilter('Cumulative Billplz Topup')">SUBMIT</button>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="card-body sell-graph">
                    <canvas baseChart id="myGraph" [datasets]="cumulativeBillplzTopupChartData"
                        [labels]="cumulativeBillplzTopupChartLabels" [options]="lineChartOptions"
                        [colors]="lineChartColors" [legend]="lineChartLegend" [chartType]="lineChartType"
                        (chartHover)="chartHovered($event)" (chartClick)="chartClicked($event)"></canvas>
                </div>
            </div>
        </div>
        <!-- <div class="col-sm-6">
            <div class="card">
                <div class="card-header">
                    <h5>Cumulative Billplz</h5>
                </div>
                <div class="card-body sell-graph">
                    <canvas baseChart id="myGraph" [datasets]="lineChartData" [labels]="lineChartLabels"
                        [options]="lineChartOptions" [colors]="lineChartColors" [legend]="lineChartLegend"
                        [chartType]="lineChartType" (chartHover)="chartHovered($event)"
                        (chartClick)="chartClicked($event)"></canvas>
                </div>
            </div>
        </div> -->
        <!-- <div class="col-xl-6 xl-100">
            <div class="card height-equal">
                <div class="card-header">
                    <h5>Products Cart</h5>
                </div>
                <div class="card-body">
                    <div class="user-status table-responsive products-table">
                        <table class="table table-bordernone mb-0">
                            <thead>
                                <tr>
                                    <th scope="col">Details</th>
                                    <th scope="col">Quantity</th>
                                    <th scope="col">Status</th>
                                    <th scope="col">Price</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Simply dummy text of the printing</td>
                                    <td class="digits">1</td>
                                    <td class="font-primary">Pending</td>
                                    <td class="digits">$6523</td>
                                </tr>
                                <tr>
                                    <td>Long established</td>
                                    <td class="digits">5</td>
                                    <td class="font-secondary">Cancle</td>
                                    <td class="digits">$6523</td>
                                </tr>
                                <tr>
                                    <td>sometimes by accident</td>
                                    <td class="digits">10</td>
                                    <td class="font-secondary">Cancle</td>
                                    <td class="digits">$6523</td>
                                </tr>
                                <tr>
                                    <td>Classical Latin literature</td>
                                    <td class="digits">9</td>
                                    <td class="font-primary">Return</td>
                                    <td class="digits">$6523</td>
                                </tr>
                                <tr>
                                    <td>keep the site on the Internet</td>
                                    <td class="digits">8</td>
                                    <td class="font-primary">Pending</td>
                                    <td class="digits">$6523</td>
                                </tr>
                                <tr>
                                    <td>Molestiae consequatur</td>
                                    <td class="digits">3</td>
                                    <td class="font-secondary">Cancle</td>
                                    <td class="digits">$6523</td>
                                </tr>
                                <tr>
                                    <td>Pain can procure</td>
                                    <td class="digits">8</td>
                                    <td class="font-primary">Return</td>
                                    <td class="digits">$6523</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div> -->
        <!-- <div class="col-xl-6 xl-100">
            <div class="card height-equal">
                <div class="card-header">
                    <h5>Empolyee Status</h5>
                </div>
                <div class="card-body">
                    <div class="user-status table-responsive products-table">
                        <table class="table table-bordernone mb-0">
                            <thead>
                                <tr>
                                    <th scope="col">Name</th>
                                    <th scope="col">Designation</th>
                                    <th scope="col">Skill Level</th>
                                    <th scope="col">Experience</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td class="bd-t-none u-s-tb">
                                        <div class="align-middle image-sm-size"><img
                                                class="img-radius align-top m-r-15 rounded-circle"
                                                src="assets/images/dashboard/user2.jpg" alt="" data-original-title=""
                                                title="">
                                            <div class="d-inline-block">
                                                <h6>John Deo <span class="text-muted digits">(14+ Online)</span></h6>
                                            </div>
                                        </div>
                                    </td>
                                    <td>Designer</td>
                                    <td>
                                        <div class="progress-showcase">
                                            <div class="progress" style="height: 8px;">
                                                <div class="progress-bar bg-primary" role="progressbar"
                                                    style="width: 30%" aria-valuenow="50" aria-valuemin="0"
                                                    aria-valuemax="100"></div>
                                            </div>
                                        </div>
                                    </td>
                                    <td class="digits">2 Year</td>
                                </tr>
                                <tr>
                                    <td class="bd-t-none u-s-tb">
                                        <div class="align-middle image-sm-size"><img
                                                class="img-radius align-top m-r-15 rounded-circle"
                                                src="assets/images/dashboard/user1.jpg" alt="" data-original-title=""
                                                title="">
                                            <div class="d-inline-block">
                                                <h6>Holio Mako <span class="text-muted digits">(250+ Online)</span></h6>
                                            </div>
                                        </div>
                                    </td>
                                    <td>Developer</td>
                                    <td>
                                        <div class="progress-showcase">
                                            <div class="progress" style="height: 8px;">
                                                <div class="progress-bar bg-secondary" role="progressbar"
                                                    style="width: 70%" aria-valuenow="50" aria-valuemin="0"
                                                    aria-valuemax="100"></div>
                                            </div>
                                        </div>
                                    </td>
                                    <td class="digits">3 Year</td>
                                </tr>
                                <tr>
                                    <td class="bd-t-none u-s-tb">
                                        <div class="align-middle image-sm-size"><img
                                                class="img-radius align-top m-r-15 rounded-circle"
                                                src="assets/images/dashboard/man.png" alt="" data-original-title=""
                                                title="">
                                            <div class="d-inline-block">
                                                <h6>Mohsib lara<span class="text-muted digits">(99+ Online)</span></h6>
                                            </div>
                                        </div>
                                    </td>
                                    <td>Tester</td>
                                    <td>
                                        <div class="progress-showcase">
                                            <div class="progress" style="height: 8px;">
                                                <div class="progress-bar bg-primary" role="progressbar"
                                                    style="width: 60%" aria-valuenow="50" aria-valuemin="0"
                                                    aria-valuemax="100"></div>
                                            </div>
                                        </div>
                                    </td>
                                    <td class="digits">5 Month</td>
                                </tr>
                                <tr>
                                    <td class="bd-t-none u-s-tb">
                                        <div class="align-middle image-sm-size"><img
                                                class="img-radius align-top m-r-15 rounded-circle"
                                                src="assets/images/dashboard/user.png" alt="" data-original-title=""
                                                title="">
                                            <div class="d-inline-block">
                                                <h6>Hileri Soli <span class="text-muted digits">(150+ Online)</span>
                                                </h6>
                                            </div>
                                        </div>
                                    </td>
                                    <td>Designer</td>
                                    <td>
                                        <div class="progress-showcase">
                                            <div class="progress" style="height: 8px;">
                                                <div class="progress-bar bg-secondary" role="progressbar"
                                                    style="width: 30%" aria-valuenow="50" aria-valuemin="0"
                                                    aria-valuemax="100"></div>
                                            </div>
                                        </div>
                                    </td>
                                    <td class="digits">3 Month</td>
                                </tr>
                                <tr>
                                    <td class="bd-t-none u-s-tb">
                                        <div class="align-middle image-sm-size"><img
                                                class="img-radius align-top m-r-15 rounded-circle"
                                                src="assets/images/dashboard/designer.jpg" alt=""
                                                data-original-title="" title="">
                                            <div class="d-inline-block">
                                                <h6>Pusiz bia <span class="text-muted digits">(14+ Online)</span></h6>
                                            </div>
                                        </div>
                                    </td>
                                    <td>Designer</td>
                                    <td>
                                        <div class="progress-showcase">
                                            <div class="progress" style="height: 8px;">
                                                <div class="progress-bar bg-primary" role="progressbar"
                                                    style="width: 90%" aria-valuenow="50" aria-valuemin="0"
                                                    aria-valuemax="100"></div>
                                            </div>
                                        </div>
                                    </td>
                                    <td class="digits">5 Year</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div> -->
        <div class="col-sm-12">
            <div class="card">
                <!-- <div class="card-header">
                    <h5>Sales Status</h5>
                </div> -->
                <div class="card-body">
                    <div class="row">
                        <div class="col-xl-12 col-sm-12 xl-50">
                            <form [formGroup]="formFilter">
                                <div class="row px-2">
                                    <div class="col-xl-3 col-md-4 p-1">
                                        <div class="input-group">
                                            <input class="form-control" placeholder="FORM" name="dp9" formControlName="fromDate"
                                                ngbDatepicker #d9="ngbDatepicker">
                                            <div class="input-group-append">
                                                <button class="btn btn-outline-secondary calendar" (click)="d9.toggle()"
                                                    type="button">
                                                    <i style="font-size:19px" class="fa">&#xf073;</i>
                                                </button>

                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xl-3 col-md-4 p-1">
                                        <div class="input-group">
                                            <input class="form-control" placeholder="TO" name="dp10" formControlName="toDate"
                                                ngbDatepicker #d10="ngbDatepicker">
                                            <div class="input-group-append">
                                                <button class="btn btn-outline-secondary calendar" (click)="d10.toggle()"
                                                    type="button">
                                                    <i style="font-size:19px" class="fa">&#xf073;</i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xl-3 col-md-4 p-1">
                                        <button type="button" class="btn btn-primary mr-1"
                                            (click)="submitFilter('Orders By Location')">SUBMIT</button>
                                    </div>
                                </div>
                            </form>
                            </div>
                            <div class="col-xl-6 col-sm-6 xl-50 mt-2">
                            <div class="order-graph">
                                <h6>Orders By Location</h6>
                                <div class="chart-block chart-vertical-center">
                                    <ngx-charts-pie-chart class="pie-colours-1" [view]="view"
                                        [scheme]="doughnutChartColorScheme" [results]="ordersByLocationChartData"
                                        [explodeSlices]="false" [labels]="doughnutChartShowLabels" [arcWidth]=0.50
                                        [doughnut]="true" [animations]="false" [tooltipDisabled]="doughnutChartTooltip"
                                        [gradient]="doughnutChartGradient">
                                    </ngx-charts-pie-chart>
                                </div>
                                <div class="order-graph-bottom">
                                    <ng-container *ngFor="let item of orderByLocation">
                                        <div class="media">
                                            <div class="order-color-primary"></div>
                                            <div class="media-body">
                                                <h6 class="mb-0">{{item.state}} <span
                                                        class="pull-right">{{item.total_order}}</span></h6>
                                            </div>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-6 col-sm-6 xl-50 mt-2">
                            <div class="order-graph sm-order-space">
                                <h6>Sales By Location</h6>
                                <div class="peity-chart-dashboard text-center chart-vertical-center">
                                    <ngx-charts-pie-chart class="pie-colours-1" [view]="view"
                                        [scheme]="doughnutChartColorScheme" [results]="salesByLocationChartData"
                                        [explodeSlices]="false" [labels]="doughnutChartShowLabels" [arcWidth]=0.50
                                        [doughnut]="true" [animations]="false" [tooltipDisabled]="doughnutChartTooltip"
                                        [gradient]="doughnutChartGradient">
                                    </ngx-charts-pie-chart>
                                </div>
                                <div class="order-graph-bottom sales-location">
                                    <ng-container *ngFor="let item of orderByLocation">
                                        <div class="media">
                                            <div class="order-shape-primary"></div>
                                            <div class="media-body">
                                                <h6 class="mb-0 mr-0">{{item.state}} <span
                                                        class="pull-right">{{item.total_amount}}</span></h6>
                                            </div>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="col-xl-6 xl-100">
                            <div class="order-graph xl-space">
                                <h6>Revenue for last month</h6>
                                <div class="ct-4 flot-chart-container">
                                    <x-chartist [type]="chart5.type" [data]="chart5.data" [options]="chart5.options">
                                    </x-chartist>
                                </div>
                            </div>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>
<!-- Container-fluid Ends-->

<div class="loader" *ngIf="loader == true">
