import { Location } from "@angular/common";
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Staff } from 'src/app/@models/staff';
import { ApiService } from 'src/app/@services/api.service';
import { emailValidator } from 'src/app/shared/utils/app-validators';
import Swal from "sweetalert2";

@Component({
  selector: 'app-create-picker',
  templateUrl: './create-picker.component.html',
  styleUrls: ['./create-picker.component.scss']
})
export class CreatePickerComponent implements OnInit {
  model: Staff = new Staff();

  public accountForm: FormGroup;
  public permissionForm: FormGroup;

  constructor(private formBuilder: FormBuilder,
    private api: ApiService,
    private route: ActivatedRoute,
    private _location: Location

    ) {
    this.createAccountForm();
    this.createPermissionForm();
  }

  createAccountForm() {
    this.accountForm = this.formBuilder.group({
      'user_name': ['', Validators.required],
      'email': ['', Validators.compose([Validators.required, emailValidator])],
      'password': ['', Validators.required],
      'user_role': [17]
    })
  }
  createPermissionForm() {
    this.permissionForm = this.formBuilder.group({
    })
  }

  ngOnInit() {
     var id = this.route.snapshot.params.id
    if (id) {
      this.getStaff(id)
    }
  }

  getStaff(id) {
    this.api.get(this.api.userApi + `users/${id}`).subscribe((res: Staff) => {
      this.model.id = res.id;
      this.model.user_name = res.user_name;
      this.model.email = res.email;
      this.model.password = res.password;
      this.model.user_role = res.user_role;
    });
  }
  save() {
    // if (this.model.id) {
    //   // console.log(this.warehouse);

    //   // for update
    //   this.api.create(this.api.userApi + "users", this.accountForm.value).subscribe(res => {
    //     // console.log(this.warehouse);
    //   })
    // } else {
      
      if(!this.accountForm.valid)return;
      this.model.uplink = 0;
      this.api.create(this.api.userApi + "users", this.accountForm.value).subscribe((res:any) => {
        if(res.status == "ok"){
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Staff Added!',
            showConfirmButton: false,
            timer: 1500
          });
          this.goBack;
        }else{
          Swal.fire({
            position: 'center',
            icon: 'error',
            title: 'Username or email already exist!',
            showConfirmButton: false,
            timer: 1500
          });
        }
      }, error => {
        console.log(error);

      })
    // }
  }
  get goBack() {
    return this._location.back();
  }
}
