import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { PickerListComponent } from './picker/picker-list/picker-list.component';

import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { Ng2SmartTableModule } from 'ng2-smart-table';
import { CreateUserComponent } from './create-user/create-user.component';

import { ClipboardModule } from '@angular/cdk/clipboard';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CustomerInfoDialogComponent } from './customer/customer-info-dialog/customer-info-dialog.component';
import { CustomerListComponent } from './customer/customer-list/customer-list.component';
import { DeletedUsersComponent } from './deleted-users/deleted-users.component';
import { DownlineInfoComponent } from './downline/downline-info/downline-info.component';
import { DownlineListComponent } from './downline/downline-list/downline-list.component';
import { CreateDropshipComponent } from './dropship/create-dropship/create-dropship.component';
import { DropshipEditPasswordComponent } from './dropship/dropship-edit-password/dropship-edit-password.component';
import { DropshipInfoDialogComponent } from './dropship/dropship-info-dialog/dropship-info-dialog.component';
import { DropshipListComponent } from './dropship/dropship-list/dropship-list.component';
import { GenealogyComponent } from './dropship/genealogy/genealogy.component';
import { CreateOutletAdminComponent } from './outlet-admin/create-outlet-admin/create-outlet-admin.component';
import { OutletAdminEditPasswordComponent } from './outlet-admin/outlet-admin-edit-password/outlet-admin-edit-password.component';
import { OutletAdminListComponent } from './outlet-admin/outlet-admin-list/outlet-admin-list.component';
import { CreatePickerComponent } from './picker/create-picker/create-picker.component';
import { PickerEditPasswordComponent } from './picker/picker-edit-password/picker-edit-password.component';
const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'create-user',
        component: CreateUserComponent,
        data: {
          title: "Create User",
          breadcrumb: "Create User"
        }
      },
      {
        path: 'dropship-list',
        component: DropshipListComponent,
        data: {
          title: "Dropship List",
          breadcrumb: "Dropship List"
        }
      },
      {
        path: 'downline-list/:dropshId',
        component: DownlineListComponent,
        data: {
          title: "Downline List",
          breadcrumb: "Downline List"
        }
      },
      {
        path: 'genealogy/:dropshId',
        component: GenealogyComponent,
        data: {
          title: "Genealogy",
          breadcrumb: "Genealogy"
        }
      },
      {
        path: 'customer-list',
        component: CustomerListComponent,
        data: {
          title: "Customer List",
          breadcrumb: "Customer List"
        }
      },
      {
        path: 'deleted-users',
        component: DeletedUsersComponent,
        data: {
          title: "Deleted Users",
          breadcrumb: "Deleted Users"
        }
      },
      {
        path: 'picker-list',
        component: PickerListComponent,
        data: {
          title: "Picker List",
          breadcrumb: "Picker List"
        }
      },
      {
        path: 'create-picker',
        component: CreatePickerComponent,
        data: {
          title: "Picker List",
          breadcrumb: "Picker List"
        }
      },
      {
        path: 'create-picker/:id',
        component: CreatePickerComponent,
        data: {
          title: "Picker List",
          breadcrumb: "Picker List"
        }
      },
      {
        path: 'outlet-admin-list',
        component: OutletAdminListComponent,
        data: {
          title: "Outlet Admin List",
          breadcrumb: "Outlet Admin List"
        }
      },
      {
        path: 'create-outlet-admin',
        component: CreateOutletAdminComponent,
        data: {
          title: "Create Outlet Admin",
          breadcrumb: "Create Outlet Admin"
        }
      },
    ]
  }
];
@NgModule({
  declarations: [
    CreateUserComponent,
    DropshipListComponent,
    CreateDropshipComponent,
    CustomerListComponent,
    CustomerInfoDialogComponent,
    DropshipInfoDialogComponent,
    DeletedUsersComponent,
    CreatePickerComponent,
    PickerListComponent,
    PickerEditPasswordComponent,
    DropshipEditPasswordComponent,
    DownlineListComponent,
    DownlineInfoComponent,
    CreateOutletAdminComponent,
    OutletAdminListComponent,
    OutletAdminEditPasswordComponent,
    GenealogyComponent
  ],
  imports: [
    CommonModule,
    NgbModule,
    Ng2SmartTableModule,
    ReactiveFormsModule,
    SharedModule,
    RouterModule.forChild(routes),
    ClipboardModule
  ],
  schemas:[CUSTOM_ELEMENTS_SCHEMA]
})
export class UsersModule {}
