import { Location } from "@angular/common";
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/@services/api.service';
import { StorageService } from "src/app/@services/storage.service";
import Swal from "sweetalert2";
@Component({
  selector: 'app-genealogy',
  templateUrl: './genealogy.component.html',
  styleUrls: ['./genealogy.component.scss']
})
export class GenealogyComponent implements OnInit {

  users:any[] = [];
  vertical = false;
  horizontal = false;
  headOfGeneology = ''
  previousUserID:any[] = [];
  userDetail:any
  userRole: any;
  userId: any;
  currentUSerID:any;

  filled

  scrollValue: string = '';
  pageNumber: number = 1;
  searchText:string = ''
  // myParam: string;

  lastPage;
  totalPage;

  apiUrl
  constructor(
    private route: ActivatedRoute,
    private api: ApiService,
    private _location: Location,
    private storage: StorageService,

  ) { }

  dropshId : any;

  ngOnInit(): void {

    let user = JSON.parse(this.route.snapshot.params.dropshId);
    console.log(user);


    if(user.details !== null){
      this.userDetail = user.details
      this.headOfGeneology = this.userDetail.first_name + " " + this.userDetail.last_name
    }
    this.userRole = user.user_role;
    this.userId = user.id;;
    this.currentUSerID = user.id;
    this.getUser(user.id);

    const widths = [320, 600, 960, 1280];
    if (window.innerWidth>=widths[0] && window.innerWidth<widths[1]) {
      this.horizontal = true
      this.scrollValue = 'overflow-x: scroll';
      } else if (window.innerWidth>=widths[1] && window.innerWidth<widths[2]) {
        this.horizontal = true
        this.scrollValue = 'overflow-x: scroll';
    } else if (window.innerWidth>=widths[2] && window.innerWidth<widths[3]) {
        this.horizontal = true
        this.scrollValue = 'overflow-x: hide';
      } else {
        this.horizontal = true
        this.scrollValue = 'overflow-x: hide';
      }

  }

  totalAffiliate : number = 0;
  affiliateLv1 : number = 0;
  affiliateLv2 : number = 0;
  affiliateLv3 : number = 0;
  affiliateLv4 : number = 0;
  getUser(userID){
    console.log('asd');

    this.previousUserID.push(userID)
    console.log(this.previousUserID);

    this.userId = userID
    var urlApi = this.api.userApi + `my-downline/${userID}?page=${this.pageNumber}&search=${this.searchText}`;
    this.apiUrl = urlApi;
    this.api.get(urlApi).subscribe((res: any) => {
      this.lastPage = res.last_page;
      this.totalPage = res.last_page;
      this.users = res.data;
    // console.log(res);
    
    const obp = this.users.filter(item => item.salutation == "OBSESS BUSINESS PARTNER");
    const osp = this.users.filter(item => item.salutation == "OBSESS SILVER PARTNER");
    const ogp = this.users.filter(item => item.salutation == "OBSESS GOLD PARTNER");
    const opp = this.users.filter(item => item.salutation == "OBSESS PLATINUM PARTNER");

    this.affiliateLv1 = obp.length;
    this.affiliateLv2 = osp.length;
    this.affiliateLv3 = ogp.length;
    this.affiliateLv4 = opp.length;
    this.totalAffiliate = this.affiliateLv1 + this.affiliateLv2 + this.affiliateLv3 + this.affiliateLv4;

    });
  }

  userChange(user){
    console.log(this.previousUserID.length);
    if(this.previousUserID.length == 4){
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: 'You are on max level!',
        showConfirmButton: false,
        timer: 1500
      });
    }else{
      this.getUser(user.id)
      if(user.details == null ){
        this.headOfGeneology = ''
      } else{
        this.headOfGeneology = user.details.first_name + " " + user.details.last_name
      }
    }

  }
  resetUser(){
    this.previousUserID = []
    this.headOfGeneology = this.userDetail.first_name + " " + this.userDetail.last_name
    this.getUser(this.currentUSerID)
  }

  previousGeneology(){
    this.previousUserID.splice(this.previousUserID.length -1 , 1)
      let lastElement = this.previousUserID.pop();
      this.getUser(lastElement)
    this.api.get(this.api.userApi + `users/${lastElement}`).subscribe((res: any) => {
    this.headOfGeneology = res.user.details.first_name + " " + res.user.details.last_name

    })
  }

  searchDS(searchKey, event) {
    if(event.key === "Enter"){
      if(searchKey.length >=3){
        this.searchText = searchKey;
        this.getUser(this.userId);
      }else{
        this.getUser(this.userId);
      }
    }
  }




  get goBack() {
    return this._location.back();
  }
  pageChanged(users:any){
    // console.log(users);
    if(users == '')return
    this.users = users
    window.scrollBy(0, -1990);
   }


}
