import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from 'src/app/@services/api.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-order-cancel-dialog',
  templateUrl: './order-cancel-dialog.component.html',
  styleUrls: ['./order-cancel-dialog.component.scss']
})
export class OrderCancelDialogComponent implements OnInit {
  @Input() id: any = {};
  remarks:any;
  constructor(
    private api: ApiService,
    public activeModal: NgbActiveModal,

  ) { }

  ngOnInit(): void {
    
  }
  cancelOrder() {
   var model= {
        id: this.id,
        remarks: this.remarks
      }
    if(!this.remarks)return;
    Swal.fire({
      title: 'Are you sure, you wanto cancel this order?',
      text: "",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes!'
    }).then((result) => {
      if (result.isConfirmed) {
        this.api.create(this.api.orderApi + "cancel-order", model).subscribe((res: any) => {
          if (res.status === "ok") {
            Swal.fire(
              'Canceled!',
              'Order has been canceled.',
              'success'
            );
            this.activeModal.close();
          } else {
            Swal.fire(
              'Failed!',
              'Order cancel failed.',
              'error'
            )
          }

        }
        )
      }
    })
  }
  close() {
    this.activeModal.dismiss('close');
  }
}
