<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="modal-header">
        <button type="button" class="close" aria-label="Close" (click)="close()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <form  class="needs-validation">
            <div class="form">
                <ng-container *ngFor="let product of products">
                <div class="form-check">
                    <input class="form-check-input" type="checkbox" (click)="selectProduct(product)" [value]="product" id="flexCheckDefault">
                    <label class="form-check-label" for="flexCheckDefault">
                        {{product.product_title}}
                    </label>
                  </div>
                </ng-container>
                &nbsp;
                <div class="form-group">
                    <label for="validationCustom01" class="mb-1"><span>*</span> Remarks :</label>
                    <input [(ngModel)]="remarks" name="remarks" class="form-control" id="validationCustom01" type="text">
                </div>
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="onhold()">Onhold</button>
        <button class="btn btn-secondary" type="button" (click)="close()" data-dismiss="modal">CLOSE</button>
    </div>
</div>

<!-- Container-fluid Ends-->