import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ApiService } from 'src/app/@services/api.service';
import { StorageService } from 'src/app/@services/storage.service';
import * as chartData from '../../../shared/data/chart';

export var cumulativeSalesChartData: Array<any> = [];
export var cumulativeOrdersChartData: Array<any> = [];
export var cumulativeBillplzOrdersChartData: Array<any> = [];
export var cumulativeBillplzTopupChartData: Array<any> = [];

@Component({
  selector: 'app-customer-dashboard',
  templateUrl: './customer-dashboard.component.html',
  styleUrls: ['./customer-dashboard.component.scss']
})
export class CustomerDashboardComponent implements OnInit {
  

  openOrder:number;
  totalProducts:number;
  totalDropship:number;
  activePromotions:number;
  orderByLocation:any;
  constructor(
    private api: ApiService,
    private storage: StorageService,
    public formBuilder: FormBuilder,
    ) {
    // Object.assign(this, { doughnutData, pieData })
  }

  // doughnut 2
  ordersByLocationChartData;
  salesByLocationChartData;
  view = chartData.view;
  doughnutChartColorScheme = chartData.doughnutChartcolorScheme;
  doughnutChartShowLabels = chartData.doughnutChartShowLabels;
  doughnutChartGradient = chartData.doughnutChartGradient;
  doughnutChartTooltip = chartData.doughnutChartTooltip;

  // public chart5 = chartData.chart5;


  // lineChart
  cumulativeSales:number;
  cumulativeSalesChartData = cumulativeSalesChartData;
  cumulativeSalesChartLabels = chartData.lineChartLabels;

  cumulativeOrders:number;
  cumulativeOrdersChartData = cumulativeOrdersChartData;
  cumulativeOrdersChartLabels = chartData.lineChartLabels;

  cumulativeBillplzOrders:number;
  cumulativeBillplzOrdersChartData = cumulativeBillplzOrdersChartData;
  cumulativeBillplzOrdersChartLabels = chartData.lineChartLabels;

  cumulativeBillplzTopup:number;
  cumulativeBillplzTopupChartData = cumulativeBillplzTopupChartData;
  cumulativeBillplzTopupChartLabels = chartData.lineChartLabels;

  lineChartOptions = chartData.lineChartOptions;
  lineChartColors = chartData.lineChartColors;
  lineChartLegend = false;
  lineChartType = 'line';

  // lineChart
  
  // public chart3 = chartData.chart3;



  // events
  public chartClicked(e: any): void {
  }
  public chartHovered(e: any): void {
  }


  dashboard:any;
  userSummary:any;
  loader:boolean = false;

  fromDate:any = "";
  toDate:any = "";
  formFilter: FormGroup;


  ngOnInit() {
    // this.getDashboard();
    // this.getUserSummary();
  
    this.formFilter = this.formBuilder.group({
      fromDate: [''],
      toDate: [''],
    })
    this.callApis();
  }

  callApis(){
    
    this.getOpenOrder();
    this.getTotalProducts();
    this.getTotalDropship();
    this.getActivePromotions();
    this.getCumulativeSales();
    this.getCumulativeOrders();
    this.getOrderByLocation();
  }

  getOpenOrder(){
    this.api.get(this.api.orderApi + 'open-orders-cs').subscribe((res: any) => {
      this.openOrder = res.open_order
    });
  }
  getTotalProducts(){
    this.api.get(this.api.orderApi + 'total-products').subscribe((res: any) => {
      this.totalProducts = res.published_product
    });
  }
  getTotalDropship (){
    this.api.get(this.api.orderApi + 'total-direct-customers').subscribe((res: any) => {
      this.totalDropship = res.customer
    });
  }
  getActivePromotions (){
    this.api.get(this.api.orderApi + 'active-promotions').subscribe((res: any) => {
      this.activePromotions = res.promotion
    });
  }
  getCumulativeSales(){
    this.api.get(this.api.orderApi + `amount-graph?from=${this.fromDate}&to=${this.toDate}&role=2`).subscribe((res: any) => {
      this.cumulativeSales = res.cumulative_amount;
      cumulativeSalesChartData[0].data = res.order_amount.map(e=>{
        return e.amount
      })
      this.cumulativeSalesChartLabels = res.order_amount.map(e=>{
        return e.date
      })
    });
  }
  getCumulativeOrders(){
    this.api.get(this.api.orderApi + `quantity-graph?from=${this.fromDate}&to=${this.toDate}&role=2`).subscribe((res: any) => {
      this.cumulativeOrders = res.cumulative_order;
      cumulativeOrdersChartData[0].data = res.order_quantity.map(e=>{
        return e.amount
      })
      this.cumulativeOrdersChartLabels = res.order_quantity.map(e=>{
        return e.date
      })
      
    });
  }
  // getCumulativeBillplzOrders(){
  //   this.api.get(this.api.orderApi + `cumulative-billplz-cs-order?from${this.fromDate}=&to=${this.toDate}`).subscribe((res: any) => {
  //     this.cumulativeBillplzOrders = res.cumulative_amount;
  //     cumulativeBillplzOrdersChartData[0].data = res.order_amount.map(e=>{
  //       return e.amount
  //     })
  //     this.cumulativeBillplzOrdersChartLabels = res.order_amount.map(e=>{
  //       return e.date
  //     })
  //   });
  // }
  // getCumulativeBillplzTopup(){
  //   this.api.get(this.api.orderApi + `cumulative-billplz-cs-topup?from=${this.fromDate}&to=${this.toDate}`).subscribe((res: any) => {
  //     this.cumulativeBillplzTopup = res.cumulative_amount;
  //     cumulativeBillplzTopupChartData[0].data = res.order_amount.map(e=>{
  //       return e.amount
  //     })
  //     this.cumulativeBillplzTopupChartLabels = res.order_amount.map(e=>{
  //       return e.date
  //     })
  //   });
  // }
  getOrderByLocation(){
    this.api.get(this.api.orderApi + `order-by-location-graph-cs?from=${this.fromDate}&to=${this.toDate}`).subscribe((res: any) => {
      this.orderByLocation = res
      this.ordersByLocationChartData = res.map(e=>{
        return {
          value: e.total_order, 
          name: e.state
        }
      })
      this.salesByLocationChartData = res.map(e=>{
        return {
          value: e.total_amount, 
          name: e.state
        }
      })
    });
  }
  

  submitFilter(graphName){
    var fromDate  = this.formFilter.value.fromDate
    var toDate  = this.formFilter.value.toDate

    // from
      if(fromDate.month.toString().length < 2){
        fromDate.month = '0' + fromDate.month 
      }
      if(fromDate.day.toString().length < 2){
        fromDate.day = '0' + fromDate.day 
      }

    // to
      if(toDate.month.toString().length < 2){
        toDate.month = '0' + toDate.month 
      }
      if(toDate.day.toString().length < 2){
        toDate.day = '0' + toDate.day 
      }

      this.fromDate = fromDate.year + '-' + fromDate.month +'-' + fromDate.day
      this.toDate = toDate.year + '-' + toDate.month +'-' + toDate.day
         
    console.log(this.fromDate);
    console.log(this.toDate);
    if(graphName == 'Cumulative Sales'){
      this.getCumulativeSales();
    }else {
      this.getCumulativeOrders();
    }
    // this.getUnitSold();
  }
}
