<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-header ">
                    <div class="row">
                        <div class="col-sm-9">
                            <h5>POINTS LIST</h5>
                        </div>
                        <div class="col-sm-3">
                            <div class="input-group">
                                <input type="text" class="form-control " (keyup.enter)="searchByEmail(searchText)"
                                    [(ngModel)]="searchText" name="search" placeholder="Search By Email">
                                <div class="input-group-append">
                                    <button (click)="searchByEmail(searchText)" class="btn btn-secondary" type="button">
                                        <i class="fa fa-search"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="row pull-right pt-2">
                        <div class="text-right">
                            <button class="btn btn-primary" (click)="exportAsXLSX()" type="button"
                                data-toggle="collapse" data-target="#collapseExample" aria-expanded="false"
                                aria-controls="collapseExample">
                                EXPORT
                            </button>
                        </div>
                        <div class="text-right ml-2" style="margin-right: 14px;">
                            <button class="btn btn-primary" (click)="isCollapsed = !isCollapsed" type="button"
                                data-toggle="collapse" data-target="#collapseExample" aria-expanded="false"
                                aria-controls="collapseExample">
                                FILTER
                            </button>
                        </div>
                    </div> -->
                </div>
                <div class="row">
                    <div class="collapse w-100" id="collapseExample" [ngbCollapse]="isCollapsed">
                        <div class="card-body">
                            <form [formGroup]="formFilter">
                                <div class="row px-2">
                                    <div class="col-xl-3 col-md-4 p-1">
                                        <div class="input-group">
                                            <input class="form-control" placeholder="FORM" name="dp1"
                                                formControlName="fromDate" ngbDatepicker #d1="ngbDatepicker">
                                            <div class="input-group-append">
                                                <button class="btn btn-outline-secondary calendar" (click)="d1.toggle()"
                                                    type="button">
                                                    <i style="font-size:19px" class="fa">&#xf073;</i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xl-3 col-md-4 p-1">
                                        <div class="input-group">
                                            <input class="form-control" placeholder="TO" name="dp2"
                                                formControlName="toDate" ngbDatepicker #d2="ngbDatepicker">
                                            <div class="input-group-append">
                                                <button class="btn btn-outline-secondary calendar" (click)="d2.toggle()"
                                                    type="button">
                                                    <i style="font-size:19px" class="fa">&#xf073;</i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xl-2 col-md-3 p-1">
                                        <button type="button" class="btn btn-primary mr-1 pull-right"
                                            (click)="submitFilter()">SUBMIT</button>
                                    </div>
                                </div>
                            </form>

                        </div>
                    </div>
                    <div class="card-body" style="overflow-x: hidden;">
                        <div class="btn-popup pull-right">
                            <div class="row">
                            </div>
                        </div>
                        <div id="batchDelete" class="custom-datatable">
                            <div class="table-responsive">
                                <!-- <ng2-smart-table [settings]="settings" [source]="digital_list"></ng2-smart-table> -->
                                <table class="table">
                                    <thead class="thead-dark">
                                        <tr>
                                            <th>NAME</th>
                                            <th>EMAIL</th>
                                            <th>CURRENT BALANCE</th>
                                            <th>ACCUMULATIVE AMOUNT</th>
                                            <th colspan="3">ACTION</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let point of points; let i = index">
                                            <td>{{point.user.user_name | uppercase}} </td>
                                            <td>{{point.user.email}}</td>
                                            <td>{{point.balance | number:'1.2'}}</td>
                                            <td>{{point.accumulative_amount | number:'1.2'}}</td>
                                            <td>
                                                <app-feather-icons title="Credit History" (click)="showDetails(point)" [icon]="'info'"></app-feather-icons>
                                            </td>
                                            <td>
                                              <app-feather-icons title="Add Credit" (click)="addPoint(point.email, point.id)"
                                                  [icon]="'plus-circle'"></app-feather-icons>
                                          </td>
                                          <td>
                                            <app-feather-icons title="Deduct Credit" (click)="deductPoint(point.email, point.id)"
                                                [icon]="'minus-circle'"></app-feather-icons>
                                        </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div class="btn-popup pull-right" *ngIf="lastPage > 1 && points?.length > 0">
                                <pagination-with-api [apiUrl]="apiUrl" [totalPage]="totalPage"
                                    (valueChanged)="pageChanged($event)">
                                </pagination-with-api>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
