
import { Injectable } from "@angular/core";
import * as S3 from 'aws-sdk/clients/s3';
import { combineLatest, from, Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { environmentImage } from '../../environments/environment.prod';
// import { ImageFile } from '../@data-services/firestore-file.service';
import * as _ from 'lodash';

@Injectable({
    providedIn: "root",
})
export class S3UploadService {
    elementPropertyName: string;
    elementFolderpath: string;
    bucket = new S3(environmentImage.s3);
    constructor() {

    }


    fileUpload(elementType: string, elemet_id: number, files) {
// debugger



if (window.location.href.includes('obsesscosmetics.com')) {

        if (elementType === 'category') {
            this.elementPropertyName = 'category_id';
            this.elementFolderpath = 'Categories';
        }
        if (elementType === 'product') {
            this.elementPropertyName = 'product_id';
            this.elementFolderpath = 'Products';
        }

        if (elementType === 'category-collection') {
            this.elementPropertyName = 'category_collection_id';
            this.elementFolderpath = 'categoriesCollection';
        }
        if (elementType === 'banner') {
            this.elementPropertyName = 'banner_id';
            this.elementFolderpath = 'banners';
        }
        if (elementType === 'slider') {
          this.elementPropertyName = 'banner_id';
          this.elementFolderpath = 'sliders';
        }
        if (elementType === 'promote') {
            this.elementPropertyName = 'advertise_id';
            this.elementFolderpath = 'promote';
        }

        if (elementType === 'bankStatementImage') {
            this.elementPropertyName = 'user_id';
            this.elementFolderpath = 'bankStatementImages';
        }
        if (elementType === 'icFrontImage') {
            this.elementPropertyName = 'user_id';
            this.elementFolderpath = 'icFrontImages';
        }
        if (elementType === 'icBackImage') {
            this.elementPropertyName = 'user_id';
            this.elementFolderpath = 'icbackImages';
        }

        if (elementType === 'notifiations') {
            this.elementPropertyName = 'notification_id';
            this.elementFolderpath = 'notifiations';
        }
    }else{
        if (elementType === 'category') {
            this.elementPropertyName = 'category_id';
            this.elementFolderpath = 'Categories-test';
        }
        if (elementType === 'product') {
            this.elementPropertyName = 'product_id';
            this.elementFolderpath = 'Products-test';
        }

        if (elementType === 'category-collection') {
            this.elementPropertyName = 'category_collection_id';
            this.elementFolderpath = 'categoriesCollection-test';
        }
        if (elementType === 'banner') {
            this.elementPropertyName = 'banner_id';
            this.elementFolderpath = 'banners-test';
        }
        if (elementType === 'slider') {
          this.elementPropertyName = 'banner_id';
          this.elementFolderpath = 'sliders-test';
      }
        if (elementType === 'promote') {
            this.elementPropertyName = 'advertise_id';
            this.elementFolderpath = 'promote-test';
        }

        if (elementType === 'bankStatementImage') {
            this.elementPropertyName = 'user_id';
            this.elementFolderpath = 'bankStatementImages-test';
        }
        if (elementType === 'icFrontImage') {
            this.elementPropertyName = 'user_id';
            this.elementFolderpath = 'icFrontImages-test';
        }
        if (elementType === 'icBackImage') {
            this.elementPropertyName = 'user_id';
            this.elementFolderpath = 'icbackImages-test';
        }

        if (elementType === 'notifiations') {
            this.elementPropertyName = 'notification_id';
            this.elementFolderpath = 'notifiations-test';
        }
    }
        return this.uploadAllfiles(elemet_id, files);

    }


    async createBlob(url) { // create blob file from download url
        const blob = await fetch(url).then(r => r.blob());
        return blob;
    }

    uploadAllfiles(elemet_id, files): Observable<any> {
        const filterUploadFiles: Array<any> = files.filter(_file => _file.original && !_file.hasOwnProperty('aws_key') && !_file.hasOwnProperty('id')) || [];
        let _existFiles: Array<any> = files.filter(_file => _file.original && _file.hasOwnProperty('aws_key')) || [];

        if (filterUploadFiles && filterUploadFiles.length) {
            const uploaddCalls = [];
            filterUploadFiles.forEach(_file => {
                let n = this.makeid(16)
                uploaddCalls.push(this.compress(80, _file.original, `${this.elementFolderpath}/${elemet_id}/80/${n}`))
                uploaddCalls.push(this.compress(250, _file.original, `${this.elementFolderpath}/${elemet_id}/250/${n}`))
                uploaddCalls.push(this.compress(350, _file.original, `${this.elementFolderpath}/${elemet_id}/350/${n}`))
                uploaddCalls.push(this.compress(560, _file.original, `${this.elementFolderpath}/${elemet_id}/560/${n}`))
                uploaddCalls.push(this.compress(800, _file.original, `${this.elementFolderpath}/${elemet_id}/800/${n}`))
                uploaddCalls.push(this.compress(null, _file.original, `${this.elementFolderpath}/${elemet_id}/original/${n}`)) // for original image

            })

            if (uploaddCalls.length) {
                return combineLatest(uploaddCalls).pipe(map(responses => {


                    const uploadResults = this.formateResponse(responses)
                    _existFiles = _existFiles.concat(uploadResults);
                    return this.createGenericResponse(_existFiles);


                }, err => {
                    return null;
                }))
            }
        } else {
            return of(this.createGenericResponse(_existFiles));
        }



        return of(null)
    }





    createGenericResponse(_existFiles) {
        let fomatedData = {
            images: []
        };

        fomatedData[this.elementPropertyName] = null;


        let fGd = _.groupBy(_existFiles, r => r[this.elementPropertyName]);
        for (let elementId in fGd) {
            fomatedData[this.elementPropertyName] = Number(elementId);
            fGd[elementId].forEach((_i, index) => {
                _i.serial = index + 1;
                fomatedData.images.push(_i)

            })
        }
        return fomatedData;
    }

    formateResponse(results) {
        let responses = [];
        results = results.map(res => {
            res['aws_key'] = String(res.key).split('/')[3];
            return res;
        })



        // _.mapValues(_.groupBy(results, 'aws_key'),
        //     clist => clist.map(response => _.omit(response, 'aws_key')));

        let grouped = _.groupBy(results, r => r.aws_key);


        // console.log(grouped)

        for (let aws_key in grouped) {
            const imageResult = {}
            grouped[aws_key].forEach(item => {
                const folderName = String(item.key).split('/')[2];
                const elementId = String(item.key).split('/')[1];
                imageResult['image_' + folderName] = environmentImage.AWS_IMAGE_URL + item.key;
                imageResult['aws_key'] = aws_key;
                imageResult[this.elementPropertyName] = Number(elementId);
                imageResult['serial'] = 1;
            })
            responses.push(imageResult)
        }

        return responses;
    }




    compress(width: any, src, path) {
        let p;
        if (width) {
            p = this.createBlob(src).then(file => {
                return new Promise((res, rej) => {
                    //      console.log(file)
                    const img = new Image();
                    img.src = URL.createObjectURL(file);
                    img.onload = () => {
                        const elem = document.createElement("canvas");
                        const newX = width;
                        const newY = (img.height * newX) / img.width;
                        elem.width = newX;
                        elem.height = newY;
                        const ctx: any = elem.getContext("2d");
                        ctx.drawImage(img, 0, 0, newX, newY);
                        const data = ctx.canvas.toDataURL();
                        //  res(data);


                        var byteString = atob(data.split(',')[1]);
                        var ab = new ArrayBuffer(byteString.length);
                        var ia = new Uint8Array(ab);

                        for (var i = 0; i < byteString.length; i++) {
                            ia[i] = byteString.charCodeAt(i);
                        }
                        const blob = new Blob([ab], { type: file.type });
                        // const blob = fetch(data)
                        res(blob);
                    };
                    img.onerror = (error) => rej(error);
                });
            })
        } else {
            p = this.createBlob(src);
        }



        return from(p).pipe(switchMap(response1 => {
            if (response1) {
                return this.uploadInS3(response1, path).pipe(map(response2 => {
                    return response2;
                }, err => {
                    console.log(err)
                }))

            }
        }))
    }





    removeExistingFile(file): Observable<any> {

        let params = {
            Bucket: environmentImage.AWS_BUCKET,
            Delete: {
                Objects: [
                ],
                Quiet: false
            }

        };

        for (let url in file) {

            if (url.match(/image/g)) {
                const obj = {
                    Key: file[url].split(`${environmentImage.AWS_IMAGE_URL}`)[1]
                }
                params.Delete.Objects.push(obj);
                //    params.Key = file[url].split(`${environmentImage.AWS_IMAGE_URL}`)[1];
            }
        }

        return from(this.bucket.deleteObjects(params).promise()).pipe(map(res => {
            console.log(res)
            if (res) {
                return true;
            } else return false;
        }, err => {
            console.log(err)
        }))

    }


    uploadInS3(file, path) {
        const contentType = file.type;
        const bucket = new S3(environmentImage.s3);
        const params = {
            Bucket: environmentImage.AWS_BUCKET,
            Key: path,
            Body: file,
            ACL: 'public-read',
            ContentType: contentType
        };

        return from(bucket.upload(params).promise());
    }



    // resizeAndUploadFile(src, width, path: string) {
    //     let p = new Promise(null);
    //     if (width) {
    //         p = new Promise((res, rej) => {
    //             const img = new Image();
    //             img.src = src;
    //             img.onload = () => {
    //                 const elem = document.createElement("canvas");
    //                 const newX = width;
    //                 const newY = (img.height * newX) / img.width;
    //                 elem.width = newX;
    //                 elem.height = newY;
    //                 const ctx = elem.getContext("2d");
    //                 ctx.drawImage(img, 0, 0, newX, newY);
    //                 const data = ctx.canvas.toDataURL();
    //                 res(data);
    //             };
    //             img.onerror = (error) => rej(error);
    //         });
    //     }

    //    return from(p).pipe(switchMap(response1 => {
    //         if (response1) {
    //             const file = width ? response1 : src;
    //             return from(this.uploadInS3(response1, path)).pipe(map(response2 => {
    //                 return response2;
    //             }))

    //         }
    //     }))
    // }


    makeid(length) {
        var result = '';
        var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for (var i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }

}
