import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { TokenInterceptor } from './@interceptors/token.interceptor';
import { AppService } from './app.service';
import { AuthModule } from './components/auth/auth.module';
import { CouponsModule } from './components/coupons/coupons.module';
import { DashboardModule } from './components/dashboard/dashboard.module';
import { InvoiceModule } from './components/invoice/invoice.module';
import { LocalizationModule } from './components/localization/localization.module';
import { MediaModule } from './components/media/media.module';
import { MenusModule } from './components/menus/menus.module';
import { PagesModule } from './components/pages/pages.module';
import { PrintingComponent } from './components/printing/printing.component';
import { ReportsModule } from './components/reports/reports.module';
import { SalesModule } from './components/sales/sales.module';
import { SettingModule } from './components/setting/setting.module';
import { UsersModule } from './components/users/users.module';
import { VendorsModule } from './components/vendors/vendors.module';
import { SharedModule } from './shared/shared.module';
import { PointsComponent } from './components/points/points.component';

@NgModule({
  declarations: [
    AppComponent,
    PrintingComponent,
    PointsComponent,
  ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    DashboardModule,
    InvoiceModule,
    SettingModule,
    ReportsModule,
    AuthModule,
    SharedModule,
    LocalizationModule,
    // ProductsModule,
    // VariationsModule,
    SalesModule,
    VendorsModule,
    CouponsModule,
    PagesModule,
    MediaModule,
    MenusModule, MatMenuModule, MatButtonModule,
    UsersModule,
    HttpClientModule,
    FormsModule,
    NgMultiSelectDropDownModule.forRoot()
  ],
  providers: [
    AppService,
    // { provide: HTTP_INTERCEPTORS, useClass: AppInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
