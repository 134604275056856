<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="modal-header">
        <h5 class="modal-title f-w-600" id="exampleModalLabel">CHANGE SHIPPING ADDRESS</h5>
        <button type="button" class="close" aria-label="Close" (click)="close()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <form [formGroup]="form"  class="needs-validation">
            <div class="form">
                <div class="form-group">
                    <label for="validationCustom01" class="mb-1"><span>*</span> NAME :</label>
                    <input formControlName="receiver_name" class="form-control" id="validationCustom01" type="text">
                    <div class="errorMsg" *ngIf="nameErrorMsg">
                        Name is required.
                      </div>
                </div>
                <div class="form-group">
                    <label for="validationCustom01" class="mb-1"><span>*</span> PHONE :</label>
                    <input formControlName="phone" class="form-control" id="validationCustom01" type="text">
                    <div class="errorMsg" *ngIf="nameErrorMsg">
                        Name is required.
                      </div>
                </div>
                <div class="form-group">
                    <label for="validationCustom01" class="mb-1"><span>*</span> ADDRESS LINE 1 :</label>
                    <input formControlName="address_line1" class="form-control" id="validationCustom01" type="text">
                    <div class="errorMsg" *ngIf="nameErrorMsg">
                        Name is required.
                      </div>
                </div>
                <div class="form-group">
                    <label for="validationCustom01" class="mb-1">ADDRESS LINE 2 :</label>
                    <input formControlName="address_line2" class="form-control" id="validationCustom01" type="text">
                    <div class="errorMsg" *ngIf="nameErrorMsg">
                        Name is required.
                      </div>
                </div>
                <div class="form-group">
                    <label for="validationCustom01" class="mb-1"><span>*</span> POST CODE :</label>
                    <input formControlName="post_code" class="form-control" id="validationCustom01" type="text">
                    <div class="errorMsg" *ngIf="nameErrorMsg">
                        Name is required.
                      </div>
                </div>
                <div class="form-group">
                    <label class="col-form-label"><span>*</span>STATE</label>
                    <!-- <ng-multiselect-dropdown #multiSelect [data]="subCategories" placeholder="--Select--"
                        [settings]="subCategorySettings" [disabled]="false"
                        (onSelect)="onSubCategotySelect($event)"
                        [formControl]="form.controls.category">
                    </ng-multiselect-dropdown> -->

                    <select class="custom-select" formControlName="state">
                        <option value="">--Select--</option>
                        <option *ngFor="let state of states" [value]="state.name">{{
                            state.name }}
                        </option>
                    </select>
                    <!-- <div class="errorMsg" *ngIf="categoryErrorMsg">
                        Sub category is required.
                    </div> -->
                </div>
                <div class="form-group">
                    <label class="col-form-label"><span>*</span>COUNTRY</label>
                    <!-- <ng-multiselect-dropdown #multiSelect [data]="subCategories" placeholder="--Select--"
                        [settings]="subCategorySettings" [disabled]="false"
                        (onSelect)="onSubCategotySelect($event)"
                        [formControl]="form.controls.category">
                    </ng-multiselect-dropdown> -->

                    <select class="custom-select" formControlName="country">
                        <option value="">--Select--</option>
                        <option *ngFor="let country of countries" [value]="country.name">{{
                            country.name }}
                        </option>
                    </select>
                    <!-- <div class="errorMsg" *ngIf="categoryErrorMsg">
                        Sub category is required.
                    </div> -->
                </div>
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="save()">Save</button>
        <button class="btn btn-secondary" type="button" (click)="close()" data-dismiss="modal">Close</button>
    </div>
</div>

<!-- Container-fluid Ends-->