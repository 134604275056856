<div *ngIf="order==undefined">page loading ...</div>
<div *ngIf="order!=undefined">
<div class="container-fluid">
    <button type="button" class="btn btn-primary" style="margin-bottom: 10px;" (click)="goBack">Back</button>
    <div class="row">
        <div class="col-xl-12">
            <div class="card">
                <div class="card-header">
                    <div class="pull-right">
                        <p>Date: {{order?.order_date}}</p>
                    </div>
                    <h5>#{{order?.invoice}}</h5>
                    <span style="width: 127px; color: white;" class="badge badge-warning"
                        *ngIf="order?.payment_status == 0">Pending Payment</span>
                    <span style="width: 39px; color: white;" class="badge badge-success"
                        *ngIf="order?.payment_status == 1">Paid</span>
                    &nbsp;
                    <div class="row">
                        <button type="button" class="btn btn-secondary header-btn"
                            title="Download Invoice" [printSectionId]="'invoice-print-section'" ngxPrint>
                            <app-feather-icons title="View" [icon]="'download'"></app-feather-icons>
                        </button>
                        <button type="button" class="btn btn-secondary header-btn"
                            (click)="sendInvoice()">SEND INVOICE</button>
                        <button type="button" class="btn btn-warning header-btn" *ngIf="(order?.order_status == 0 || order?.order_status== 1)  && !postSystem && (userDetails.role.role!='Outlet Admin') && showCancelButtown" (click)="cancelOrder(order.id)">CANCEL</button>
                        <button type="button" class="btn btn-secondary header-btn" *ngIf="order?.order_status != 6 && !isViewerOnly && !postSystem" (click)="onholdOrder(order)">ONHOLD</button>
                        <button type="button" class="btn btn-secondary header-btn" *ngIf="order?.order_status == 6  && !postSystem && order?.shipping_type!='AWBShopee' && order?.shipping_type!='AWBTiktok'"
                            (click)="unholdOrder(order.id)">UNHOLD</button>


                        <!-- <button type="button" class="btn btn-secondary header-btn"
                            (click)="manualConsignmentDialog(order)">EDIT/UPDATE CONSIGNMENT</button>
                             -->

                             <!-- For AWB confirmation -->
                             <!-- {{order?.order_status}} -->
                             <button type="button" class="btn btn-secondary header-btn"
                             *ngIf="((order?.order_status == 6)&&((order?.shipping_type=='AWBShopee')||(order?.shipping_type=='AWBTiktok')))"
                            (click)="confirmOrder(order.id)"
                            >CONFIRM</button>


                            <button type="button"  class="btn btn-secondary header-btn" (click)="awbPDF()"
                            *ngIf="order?.awb_image!=null"
                            ngxPrint
                            >AWB PDF</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-8">
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-3">
                            <h5>Total Amount</h5>
                            RM{{order?.transaction_amount | number : '1.2-2'}}
                        </div>
                        <div class="col-3">
                            <h5>Payment Method</h5>
                            {{order?.payment_type | uppercase}}
                        </div>
                        <div class="col-3">
                            <h5>Total Item</h5>
                            {{order?.total_item}}
                        </div>
                    </div>
                    <hr>
                    <div></div>
                    <table>
                        <thead>
                            <tr style="border-bottom: 1px solid #E5E5E5;">
                                <th></th>
                                <th>TITLE</th>
                                <th class="text-center">Quantity</th>
                                <th class="text-right">PRICE</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let product of order?.order_products;" style="border-bottom: 1px solid #E5E5E5; margin-top: 5px;">
                                <td>
                                    <img width="40px" src="{{product.feature_image}}">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                </td>
                                <td>
                                    {{product.product_title}}<br /> {{product.color}}, {{product.size}}<br />
                                    {{product.product_sku}}
                                    <ol>
                                      <li *ngFor="let item of product.combo_items">
                                        {{item.product_title}} | {{item.size}} | {{item.color}} | {{item.flavour}} | QTY: {{item.quantity}}<br>
                                      </li>
                                    </ol>
                                </td>
                                <td class="text-center">
                                    {{product.quantity}}
                                </td>
                                <td class="text-right">
                                    <!--
                                        * update condition for starter pack 
                                        RM{{ product.total_amount | number : '1.2-2'}}   -->

                                    <span *ngIf="order.is_starterorder ==0">RM{{ product.total_amount | number : '1.2-2'}}</span>
                                    <span *ngIf="order.is_starterorder ==1">RM{{ (order.total_amount) | number : '1.2-2'}}</span>

                                </td>
                            </tr>
                            <tr *ngIf="order?.combo_pairin_amount > 0">
                              <td colspan="3" class="text-right">
                                  {{order?.combo_pairin_promo_name}}:
                              </td>
                              <td class="text-right">
                                  -RM{{order?.combo_pairin_amount | number : '1.2-2'}}
                              </td>
                          </tr>
                          <tr *ngIf="order?.discount_coupon_amount > 0">
                            <td colspan="3" class="text-right">
                                {{order?.discount_coupon}}:
                            </td>
                            <td class="text-right">
                                -RM{{order?.discount_coupon_amount | number : '1.2-2'}}
                            </td>
                        </tr>

                        
                        <tr>
                            <td colspan="3" class="text-right">
                                Sub Total:
                            </td>
                            <td class="text-right">
                                RM{{order?.total_amount | number : '1.2-2' }}
                            </td>
                        </tr>
                            <tr>
                                <td colspan="3" class="text-right">
                                    Shipment fee:
                                </td>
                                <td class="text-right">
                                    RM{{order?.shipment_fee | number : '1.2-2'}}
                                </td>
                            </tr>
                            <tr>
                                <td colspan="3" class="text-right">
                                    Total:
                                </td>
                                <td class="text-right">
                                    RM{{order?.transaction_amount | number : '1.2-2' }}
                                </td>
                            </tr>


                            <tr *ngIf="order?.order_cash_transaction!=null" style="border-top: 1px solid #E5E4E2;">
                                <td colspan="3" class="text-right">
                                    Cash Amount:
                                </td>
                                <td class="text-right">
                                    RM{{order?.order_cash_transaction.cash_amount | number : '1.2-2' }}
                                </td>
                            </tr>
                            
                            
                            <tr *ngIf="order?.order_cash_transaction!=null" style="border-bottom: 1px solid #E5E4E2;">
                                <td colspan="3" class="text-right">
                                    Balance:
                                </td>
                                <td class="text-right">
                                    RM{{order?.order_cash_transaction.balance | number : '1.2-2' }}
                                </td>
                            </tr>

                        </tbody>
                    </table>
                </div>
            </div>
            <div class="card">
                <div class="card-header">
                    <h5>Order History</h5>
                </div>
                <div class="card-body">
                    <table>
                        <tbody>
                            <tr *ngFor="let history of order?.history">
                                <td>
                                    <p>{{history.created_at | date:"dd/MM/yyyy HH:mm"}}</p>
                                </td>
                                <td>
                                    <p>{{history.history}}</p>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="card">
                <div class="card-header">
                    <h5>Courier Information</h5>
                </div>
                <div class="card-body">
                    <table>
                        <tbody>
                            <tr>
                                <td>
                                    <p>Courier</p>
                                </td>
                                <td>
                                    <p>{{order?.shipping_type}}</p>
                                </td>
                            </tr>
                            <tr *ngIf="order?.shipping_type != 'Pickup'">
                                <td>
                                    <p>Tracking Number</p>
                                </td>
                                <td>
                                    <p>{{order?.jntconsign?.bill_code ? order?.jntconsign?.bill_code: 'No Tracking Number'}}</p>
                                </td>
                            </tr>
                            <tr *ngIf="order?.shipping_type == 'Pickup'">
                                <td>
                                    <p>Pickup Date</p>
                                </td>
                                <td>
                                    <p>{{order?.pickup?.delivery_date | date:'dd-MM-yyyy'}}</p>
                                </td>
                            </tr>
                            <tr *ngIf="order?.order_status === 3 && order?.shipping_type != 'Pickup'">
                                <td>
                                <p *ngIf="order?.shipping_type == 'J&T Express' || order?.shipping_type == 'J&T EXPRESS'"><a
                                        href="https://www.jtexpress.my/track.php?awbs={{order?.jntconsign?.bill_code}}"
                                        target="_blank">https://www.jtexpress.my/track.php?awbs={{order?.jntconsign?.bill_code}}</a>
                                </p>
                                <p *ngIf="order?.shipping_type == 'ABX EXPRESS'"><a
                                        href="https://www.tracking.my/abx/{{order?.jntconsign?.bill_code}}"
                                        target="_blank">https://www.tracking.my/abx/{{order?.jntconsign?.bill_code}}</a></p>
                                <p *ngIf="order?.shipping_type == 'CITY-LINK EXPRESS'"><a
                                        href="https://www.citylinkexpress.com/tracking-result/?track0={{order?.jntconsign?.bill_code}}"
                                        target="_blank">https://www.citylinkexpress.com/tracking-result/?track0={{order?.jntconsign?.bill_code}}</a>
                                </p>
                                <p *ngIf="order?.shipping_type == 'POSLAJU' || order?.shipping_type == 'Poslaju' || order?.shipping_type == 'POSLAJU EXPRESS'"><a
                                        href="https://www.tracking.my/poslaju/{{order?.jntconsign?.bill_code}}"
                                        target="_blank">https://www.tracking.my/poslaju/{{order?.jntconsign?.bill_code}}</a>
                                </p>
                                <p *ngIf="order?.shipping_type == 'Ninja VAn' || order?.shipping_type == 'NINJA VAN' || order?.shipping_type == 'NINJAVAN'"><a
                                        href="https://www.ninjavan.co/en-my/tracking?id={{order?.jntconsign?.bill_code}}"
                                        target="_blank">https://www.ninjavan.co/en-my/tracking?id={{order?.jntconsign?.bill_code}}</a>
                                </p>
                                <p *ngIf="order?.shipping_type == 'GDEX'"><a
                                        href="https://www.ninjavan.co/en-my/tracking?id={{order?.jntconsign?.bill_code}}"
                                        target="_blank">https://gdexpress.com/tracking/{{order?.jntconsign?.bill_code}}</a>
                                </p>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div class="col-xl-4">
            <div class="card">
                <div class="card-header">
                    <h5>Customer details</h5>
                </div>
                <div class="card-body">
                    <p style="color: #4E7BE0">{{order?.billing_address.first_name}} {{order?.billing_address.last_name}}
                    </p>
                    <p>{{order?.order_email}}</p>
                </div>
            </div>
            <div class="card" *ngIf="!postSystem">
                <div class="card-header">
                    <h5>Billing address</h5>
                </div>
                <div class="card-body">
                    <h3>{{order?.billing_address.first_name}} {{order?.billing_address.last_name}}</h3>
                    <p>
                        {{order?.billing_address.address_line1}}<br />
                        <span *ngIf="order?.billing_address.address_line2 !== '' ">{{order?.billing_address.address_line2}}
                            <br /></span> {{order?.billing_address.post_code}}, {{order?.billing_address.state}}<br />
                        {{order?.billing_address.country}}
                        <br /> {{order?.billing_address.phone}}

                    </p>
                </div>
            </div>
            <div class="card" *ngIf="postSystem">
                <div class="card-header">
                    <h5>Outlet address</h5>
                </div>
                <div class="card-body">
                    <div style="font-size: 10.1; font-weight: bold;" *ngIf="wh!=null">
                        <p>
                            Obsess Cosmetics Sdn. Bhd. (1314146-T)<br/>
                            {{wh.address_1}}<br/>
                            {{wh.address_2}}<br/>
                            {{wh.post_code}} , {{wh.state}}
                        </p>
                        <p>
                            Phone: {{wh.phone}} <br/>
                            Email: obsess2you&#64;gmail.com
                        </p>
                    </div>
                </div>
            </div>
            <div class="card" *ngIf="(order?.shipping_type!='AWBShopee')&&(order?.shipping_type!='AWBTiktok') && !postSystem">
                <div class="card-header">
                    <div class="row">
                        <div class="col-11">
                            <h5>Shipping address</h5>
                        </div>
                        <div class="col-1" *ngIf="order?.order_status == 1 || order?.order_status == 7">
                            <app-feather-icons (click)="editShippingAddess(order?.shipping_address, order.id)" [icon]="'edit-3'"></app-feather-icons>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <h3>{{order?.shipping_address.receiver_name}}</h3>
                    <p>
                        {{order?.shipping_address.address_line1}}<br />
                        <span *ngIf="order?.shipping_address.address_line2 !== '' ">{{order?.shipping_address.address_line2}}
                            <br /></span> {{order?.shipping_address.post_code}}, {{order?.shipping_address.state}}<br />
                        {{order?.shipping_address.country}}
                        <br /> {{order?.shipping_address.phone}}
                    </p>
                </div>
            </div>
            <div class="card" *ngIf="!postSystem">
                <div class="card-header">
                    <div class="row">
                        <div class="col-11">
                            <h5>Customer note</h5>
                        </div>
                        <div class="col-1">
                            <app-feather-icons (click)="editCustomerNote()" [icon]="'edit-3'"></app-feather-icons>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <p style="word-wrap: break-word;" *ngIf="updateCustomerNoteMode == false">
                        {{order?.order_note}}
                    </p>
                    <ng-container *ngIf="updateCustomerNoteMode">
                    <textarea rows="4" matInput [(ngModel)]="customerNoteModel.order_note" name="note" #note="ngModel" style="width: 100%;">
                        {{order?.order_note}}
                    </textarea>
                    <button rows="4" type="button" class="btn btn-primary mr-1 pull-right" (click)="updateCustomerNote(order.id)">SAVE</button>
                </ng-container>
                </div>
            </div>
        </div>
    </div>
</div>

<div hidden [id]="'invoice-print-section'">
<div style="padding: 60px 60px 40px 60px; font-family:Calibri, sans-serif;">

    <table style="width: 100%;">
        <tbody>
            <tr>
                <td>
                    <img src="../../../../assets/OBSESS LOGO.png">
                </td>
                <td style="text-align: end; font-size: 11px; font-weight: bold;">
                    Invoice: #{{order?.invoice}}<br/>
                    Date: {{order?.order_date}}
                </td>

            </tr>
        </tbody>
    </table>
    <hr style="border-bottom: 2px solid;">
    <div style="font-size: 10.1; font-weight: bold;">
        <p>
            Obsess Cosmetics Sdn. Bhd. (1314146-T)<br/>
            No.79, Jalan Zapin Utama,<br/>
            Bandar Inderapura,<br/>
            27000 Jerantut, Pahang
        </p>
        <p>
            Phone: 09-260 8659<br/>
            Email: obsess2you&#64;gmail.com
        </p>
    </div>
    <hr style="border-bottom: 2px solid;">
    <table style="width: 100%; font-size: 10.1; font-weight: bold;">
        <thead>
            <tr>
                <td>
                    <span *ngIf="postSystem">Customer Details</span>
                    <span *ngIf="!postSystem">Billing Address</span>
                    
                </td>
                <td>
                    <span *ngIf="postSystem">Outlet Address</span>
                    <span *ngIf="!postSystem">Shipping Address</span>
                </td>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td>
                    <p style="margin-top: 15px;">
                        {{order?.billing_address.first_name}} {{order?.billing_address.last_name}}<br/>
                        {{order?.billing_address.address_line1}}<br />
                        <span *ngIf="order?.billing_address.address_line2 !== '' ">{{order?.billing_address.address_line2}}
                            <br /></span> {{order?.billing_address.post_code}}, {{order?.billing_address.state}}<br />
                        {{order?.billing_address.country}}
                        <br /> {{order?.billing_address.phone}}
                    </p>
                </td>
                <td> 
                    <p style="margin-top: 15px;" *ngIf="!postSystem">
                        {{order?.shipping_address.receiver_name}}<br />
                        {{order?.shipping_address.address_line1}}<br />
                        <span *ngIf="order?.shipping_address.address_line2 !== '' ">{{order?.shipping_address.address_line2}}
                        <br /></span> {{order?.shipping_address.post_code}}, {{order?.shipping_address.state}}<br />
                        {{order?.shipping_address.country}}
                        <br /> {{order?.shipping_address.phone}}
                    </p>
                    <p style="margin-top: 15px;" *ngIf="postSystem && wh!=null">
                        <span>
                            Obsess Cosmetics Sdn. Bhd. (1314146-T)<br/>
                            {{wh.address_1}}<br/>
                            {{wh.address_2}}<br/>
                            {{wh.post_code}} , {{wh.state}}
                        </span>
                        <span>
                            Phone: {{wh.phone}} <br/>
                            Email: obsess2you&#64;gmail.com
                        </span>
                    </p>
                </td>
            </tr>
        </tbody>
    </table>
    <p style="width: 100%; font-size: 10.1; font-weight: bold;">
        Payment Method: {{order?.payment_type | uppercase}}<br/>
        Shipping Type: {{order?.shipping_type | uppercase}}
    </p>
    <table style="width: 100%; font-size: 10.1;" cellpadding="0" cellspacing="0">
        <thead style="border: 1px solid;">
            <tr style="border: 1px solid; text-align: center;">
                <th style="border: 1px solid;">SKU</th>
                <th style="border: 1px solid;">Products</th>
                <th style="border: 1px solid;">Code</th>
                <th style="border: 1px solid; width: 118px">Qty</th>
                <th style="border: 1px solid; width: 100px">Price</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let product of order?.order_products;">
                <td style="border-bottom: 1px solid; border-left: 1px solid; border-right: 1px solid;  padding: 2px;">
                    {{product.product_sku}}
                </td>
                <td style="border-bottom: 1px solid; border-right: 1px solid; padding: 2px;">
                    {{product.product_title}}
                    <ol>
                      <li *ngFor="let item of product.combo_items">
                        {{item.product_title}} | {{item.size}} | {{item.color}} | {{item.flavour}} |  QTY: {{item.quantity}}<br>

                      </li>
                    </ol>
                </td>
                <td style="border-bottom: 1px solid; border-right: 1px solid; padding: 2px;">
                    {{product.color}}
                </td>
                <td style="border-bottom: 1px solid; border-right: 1px solid; padding: 2px; text-align: center; ">
                    {{product.quantity}}
                </td>
                <td style="border-bottom: 1px solid; border-left: 1px solid; border-right: 1px solid; padding: 2px; text-align: end;">
                    <!-- ejas sini -->
                    
                    <span *ngIf="!userRoleIsTiga" >RM{{ (product.price_b2c * product.quantity) | number : '1.2-2'}}</span>
                    <span *ngIf="userRoleIsTiga" >RM{{ (product.price_b2a * product.quantity) | number : '1.2-2'}}</span>
                    
                </td>
            </tr>

            <tr>
                <td colspan="5">`</td>
            </tr>

            <tr *ngIf="order?.discount_coupon_amount > 0"  style="text-align: end;">
                <td colspan="4">
                    {{order?.discount_coupon_code}}:
                </td>
                <td>
                    -RM{{order?.discount_coupon_amount | number : '1.2-2'}}
                </td>
            </tr>

            <tr style="text-align: end;">
                <td colspan="4">
                    Sub Total:
                </td>
                <td>
                    RM{{order?.total_amount | number : '1.2-2' }}
                    <!-- RM{{order?.net_amount | number : '1.2-2' }} salah ni -->
                </td>
            </tr>
            <tr style="text-align: end;">
                <td colspan="4">
                    Shipment fee :
                </td>
                <td>
                    RM{{order?.shipment_fee | number : '1.2-2'}}
                </td>
            </tr>
            <tr *ngIf="order?.order_cash_transaction==null" style="text-align: end;">
                <td colspan="4">
                    Total Paid:
                </td>
                <td>
                    RM{{order?.transaction_amount | number : '1.2-2' }}
                </td>
            </tr>
            <tr *ngIf="order?.order_cash_transaction!=null" style="text-align: end;">
                <td colspan="4">
                    Cash Amount
                </td>
                <td>
                    RM{{order?.order_cash_transaction.cash_amount | number : '1.2-2' }}
                </td>
            </tr>
            <tr *ngIf="order?.order_cash_transaction!=null" style="text-align: end;">
                <td colspan="4">
                    Balance
                </td>
                <td>
                    RM{{order?.order_cash_transaction.balance | number : '1.2-2' }}
                </td>
            </tr>
        </tbody>
    </table>
    <!-- footer -->
    <div style="
    left: 0px;
    bottom: 0px;
    text-align: center;
    position: fixed;
    width: 100%;
    font-size: 10.1;"
    >
        <p>This is a computer-generated document. No signature is required.</p>
        <hr style="border-bottom: 2px solid;">
        <p>Obsess Cosmetics Sdn Bhd | 2022 Copyright. All rights reserved.</p>
    </div>
    <!-- footer -->

</div>
</div>
</div>