<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="modal-header">
        <button type="button" class="close" aria-label="Close" (click)="close()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <form  class="needs-validation">
            <div class="form">
                <div class="form-group">
                    <label for="validationCustom01" class="mb-1"><span>*</span> BILL CODE :</label>
                    <input [(ngModel)]="billCode" name="billCode" class="form-control" id="validationCustom01" type="text">
                </div>
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="confirm()">CONFIRM</button>
        <button class="btn btn-secondary" type="button" (click)="close()" data-dismiss="modal">CLOSE</button>
    </div>
</div>

<!-- Container-fluid Ends-->