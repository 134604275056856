import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { ApiService } from 'src/app/@services/api.service';
import { Location } from "@angular/common";

@Component({
  selector: 'app-downline-list',
  templateUrl: './downline-list.component.html',
  styleUrls: ['./downline-list.component.scss']
})
export class DownlineListComponent implements OnInit {

  myParam: string;
  constructor(
    private route: ActivatedRoute,
    private api: ApiService,
    private _location: Location
  ) { }

  dropshId : any;

  ngOnInit(): void {
    this.dropshId = this.route.snapshot.params.dropshId;
    this.getDownline(this.dropshId);
    this.getdropship();
  } 

  apiUrl  : any;
  apiUrl2  : any;
  downlineList : any;
  dropshipname: any;
  mail:any;
  totalDownline:any;
  havedata:boolean ;
  searchText: string = '';
  totalPage: number;
  lastPage: any;
  currentPage: any;
  //pages: any[] = [];
  //previous = 0;
  //next = 2;

  getdropship() {
    this.mail = localStorage.getItem("mail");
    //localStorage.removeItem("mail"); //localStorage.clear()
    //this.apiUrl2 =this.api.userApi + 'dropship-user/' + this.dropshId + '?page=' + 1 + '&search=' + this.searchText;
    this.apiUrl2 =this.api.userApi + 'dropship-user/0?page=' + 1 + '&search='+this.mail;
    this.api.get(this.apiUrl2).subscribe((res: any) => {
      this.dropshipname = res.data[0].user_name;
      //this.lastPage = res.last_page;
      //this.currentPage = res.current_page
      //this.totalPage = res.last_page
    }) 
  }

  getDownline(drid){
    this.apiUrl = this.api.userApi + 'my-downline/' + drid + '?page=' + 1 + '&search=' + this.searchText;
    this.api.get(this.apiUrl).subscribe((res: any) => {
    this.downlineList = res.data;
    this.lastPage = res.last_page;
    this.currentPage = res.current_page
    this.totalPage = res.last_page;
    //console.log("RES",res);
    this.totalDownline = res.total;
    if(this.downlineList!=""){ this.havedata=false; } else { this.havedata=true; }
    /*for(let i=0; i<res.total; i++) { 
      if(res.data[i].email_verified=='1'){ this.active++; } else { this.inactive++;}
      //console.log(res.data[i].email_verified); 
    } */
    })
    this.downlineList.forEach(d => {
      console.log(d)
    });
  }

  get goBack() {
    return this._location.back();
  }
  
  pageChanged(downlineList:any){
    //console.log(downlineList);
    if(downlineList == '')return
    this.downlineList = downlineList
    window.scrollBy(0, -1990);
   }

   search(searchKey) {
    //console.log(this.dropshId);
    //console.log(searchKey);
      this.getDownline(this.dropshId);
  }

}
