import { HostListener, Inject, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { WINDOW } from "./windows.service";
// Menu
export interface Menu {
	path?: string;
	title?: string;
	icon?: string;
	type?: string;
	badgeType?: string;
	badgeValue?: string;
	active?: boolean;
	accessKey?: string;
	bookmark?: boolean;
	children?: Menu[];
}

@Injectable({
	providedIn: 'root'
})

export class NavService {

	public screenWidth: any
	public collapseSidebar: boolean = false

	constructor(@Inject(WINDOW) private window) {
		this.onResize();
		if (this.screenWidth < 991) {
			this.collapseSidebar = true
		}
	}

	// Windows width
	@HostListener("window:resize", ['$event'])
	onResize(event?) {
		this.screenWidth = window.innerWidth;
	}

	MENUITEMS: Menu[] = [
		// {
		// 	path: 'dashboard/dropship-dashboard', title: 'DASHBOARD', icon: 'home', type: 'link', badgeType: 'primary', active: false,
		// },
		{
			title: 'DASHBOARD', icon: 'home', type: 'sub', active: false, accessKey: '0000', children: [
				{path: 'dashboard/dropship-dashboard', title: 'DROPSHIP', icon: 'home', type: 'link', badgeType: 'primary', active: false, accessKey: '0010'},
				{path: 'dashboard/customer-dashboard', title: 'CUSTOMER', icon: 'home', type: 'link', badgeType: 'primary', active: false, accessKey: '0020'},
			]
		},
		{
			title: 'SALES', icon: 'dollar-sign', type: 'sub', active: false, accessKey: '1000', children: [
				{ path: '/sales/orders', title: 'ORDERS', type: 'link', accessKey: '1010' },
				{ path: '/fullfillment/fullfillment-list', title: 'FULLFILLMENT', type: 'link', accessKey: '1020' },
				{ path: '/fullfillment/fullfillment-pickup-list', title: 'PICKUP FULLFILLMENT', type: 'link', accessKey: '1030' },
				{ path: '/fullfillment/fullfillment-awb-list', title: 'AWB FULLFILLMENT', type: 'link', accessKey: '1040' },
				// { path: '/sales/transactions', title: 'TRANSACTIONS', type: 'link' },
			]
		},
		{
			title: 'RETAIL ORDER', icon: 'shopping-bag', type: 'sub', active: false, accessKey: 'RT1000', children: [
				{ path: '/retail-order', title: 'PLACE ORDER', type: 'link', accessKey: 'RT1010' },
				// { path: '/sales/transactions', title: 'TRANSACTIONS', type: 'link' },
			]
		},
		{
			title: 'PRODUCTS', icon: 'box', type: 'sub', active: false, accessKey: '2000', children: [
				// {
					// title: 'PHYSICAL', type: 'sub', children: [

				// 		{ path: '/products/physical/product-list', title: 'PRODUCT LIST', type: 'link' },
						// { path: '/products/physical/product-detail', title: 'PRODUCT DETAIL', type: 'link' },
						// { path: '/products/physical/add-product', title: 'ADD PRODUCT', type: 'link' },
				// 		{ path: '/products/physical/category', title: 'CATEGORY', type: 'link' },
				// 		{ path: '/products/physical/sub-category', title: 'SUB CATEGORY', type: 'link' },
					// ]
				// },
				// {
					// title: 'DIGITAL', type: 'sub', children: [

						{ path: '/products/product-list', title: 'PRODUCT LIST', type: 'link', accessKey: '2010' },
						{ path: '/products/add-product', title: 'ADD PRODUCT', type: 'link', accessKey: '2020' },
						{ path: '/products/create-combo-product', title: 'CREATE COMBO', type: 'link', accessKey: '2030' },
						{ path: '/products/create-starter', title: 'STARTER PACK', type: 'link', accessKey: '2040' },
						// { path: '/products/physical/add-product', title: 'ADD PRODUCT 2', type: 'link' },

					// ]
				// },
			]
		},
		{
			title: 'INVENTORY', icon: 'activity', type: 'sub', active: false, accessKey: '3000', children: [
				{ path: '/outlet-inventory/request-inventory-list', title: 'REQUEST INVENTORY', type: 'link', accessKey: '3060' },
				{ path: '/inventory/warehouse-inventory-out-info', title: 'WAREHOUSE INVENTORY OUT INFO', type: 'link', accessKey: '3070' },
				{ path: '/inventory/product-inventory', title: 'PRODUCT INVENTORY', type: 'link', accessKey: '3010' },
				{ path: '/inventory/inventory-out', title: 'INVENTORY OUT', type: 'link', accessKey: '3020' },
				{ path: '/inventory/inventory-out-info', title: 'INVENTORY OUT INFO', type: 'link', accessKey: '3030' },
				{ path: '/inventory/inventory-out-log', title: 'INVENTORY OUT LOG', type: 'link', accessKey: '3040' },
				{ path: '/inventory/item-check', title: 'ITEM CHECK', type: 'link', accessKey: '3050' },
				// { path: '/variations/sub-category', title: 'SUB CATEGORY', type: 'link' },
				// { path: '/variations/flavour', title: 'FLAVOUR', type: 'link' },
				// { path: '/variations/size', title: 'SIZE', type: 'link' },
				// { path: '/variations/color', title: 'COLOR', type: 'link' },
			]
		},
		{
			title: 'VARIATIONS', icon: 'sliders', type: 'sub', active: false, accessKey: '4000', children: [
				{ path: '/variations/category', title: 'CATEGORY', type: 'link', accessKey: '4010' },
				{ path: '/variations/sub-category', title: 'SUB CATEGORY', type: 'link', accessKey: '4020' },
				{ path: '/variations/flavour', title: 'FLAVOUR', type: 'link', accessKey: '4030' },
				{ path: '/variations/size', title: 'SIZE', type: 'link', accessKey: '4040' },
				{ path: '/variations/code', title: 'CODE', type: 'link', accessKey: '4050' },
			]
		},
		{
			title: 'PROMOTION', icon: 'gift', type: 'sub', active: false, accessKey: '5000', children: [
				{ path: '/promotion/promotion-list', title: 'PROMOTION LIST', type: 'link', accessKey: '5010' },
				{ path: '/promotion/add-promotion', title: 'ADD PROMOTION', type: 'link', accessKey: '5020' },
			]
		},
		{
			title: 'REPORT', icon: 'pie-chart', type: 'sub', active: false, accessKey: '6000', children: [
				{ path: '/report/dropship-commission', title: 'DS COMMISSION', type: 'link', accessKey: '6010' },
				{ path: '/report/sales-by-dropship', title: 'SALES BY DROPSHIP', type: 'link', accessKey: '6020' },
				{ path: '/report/sales-by-group', title: 'SALES BY GROUP', type: 'link', accessKey: '6030' },
				{ path: '/report/sales-by-product', title: 'SALES BY PRODUCT', type: 'link', accessKey: '6040' },
				{ path: '/report/product-units-sold', title: 'PRODUCT UNITS SOLD', type: 'link', accessKey: '6050' },
			]
		},
		{
			title: 'CREDIT', icon: 'credit-card', type: 'sub', active: false, accessKey: '7000', children: [
				{ path: '/credit/credit-list', title: 'CREDIT LIST', type: 'link', accessKey: '7010' },
			]
		},
		{
			title: 'POINTS', icon: 'credit-card', type: 'sub', active: false, accessKey: '11000', children: [
				{ path: '/points', title: 'POINTS LIST', type: 'link', accessKey: '11010' },
			]
		},
    	{
			title: 'EWALLET', icon: 'credit-card', type: 'sub', active: false, accessKey: '7100', children: [
				{ path: '/withdraw/withdraw-list', title: 'WITHDRAW LIST', type: 'link', accessKey: '7110' },
				{ path: '/ewallet/ewallet-list', title: 'EWALLET LIST', type: 'link', accessKey: '7120' },
			]
		},
		// {
		// 	title: 'Coupons', icon: 'tag', type: 'sub', active: false, children: [
		// 		{ path: '/coupons/list-coupons', title: 'List Coupons', type: 'link' },
		// 		{ path: '/coupons/create-coupons', title: 'Create Coupons', type: 'link' },
		// 	]
		// },
		// {
		// 	title: 'Pages', icon: 'clipboard', type: 'sub', active: false, children: [
		// 		{ path: '/pages/list-page', title: 'List Page', type: 'link' },
		// 		{ path: '/pages/create-page', title: 'Create Page', type: 'link' },
		// 	]
		// },
		// {
		// 	title: 'Media', path: '/media', icon: 'camera', type: 'link', active: false
		// },
		// {
		// 	title: 'Menus', icon: 'align-left', type: 'sub', active: false, children: [
		// 		{ path: '/menus/list-menu', title: 'Menu Lists', type: 'link' },
		// 		{ path: '/menus/create-menu', title: 'Create Menu', type: 'link' },
		// 	]
		// },
		{
			title: 'USERS', icon: 'user-plus', type: 'sub', active: false, accessKey: '8000', children: [
				{ path: '/users/picker-list', title: 'PICKER LIST', type: 'link', accessKey: '8010' },
				{ path: '/users/dropship-list', title: 'DROPSHIP LIST', type: 'link', accessKey: '8020' },
				{ path: '/users/customer-list', title: 'CUSTOMER LIST', type: 'link', accessKey: '8030' },
				{ path: '/users/deleted-users', title: 'DELETED USERS', type: 'link', accessKey: '8040' },
				// { path: '/users/create-user', title: 'Create User', type: 'link', accessKey: '8050' },
			]
		},
		{
			title: 'SETTINGS', icon: 'tool', type: 'sub', active: false, accessKey: '9000', children: [
				// { path: '/settings/salutation', title: 'SALUTATION', type: 'link', accessKey: '9XXX' },
				{ path: '/settings/user-role', title: 'USER ROLE', type: 'link', accessKey: '9010' },
				{ path: '/settings/tier-configuration', title: 'TIER CONFIGURATION', type: 'link', accessKey: '9020' },
				{ path: '/settings/courier', title: 'COURIER', type: 'link', accessKey: '9030' },
				{ path: '/settings/shipping-fees', title: 'SHIPPING FEES', type: 'link', accessKey: '9040' },
				{ path: '/settings/self-pickup', title: 'SELF PICKUP', type: 'link', accessKey: '9050' },
				{ path: '/settings/banner', title: 'BANNER', type: 'link', accessKey: '9060' },
				{ path: '/settings/slider', title: 'SLIDER', type: 'link', accessKey: '9070' },
				{ path: '/settings/home-video', title: 'HOME VIDEO', type: 'link', accessKey: '9080' },
				{ path: '/settings/announcement', title: 'ANNOUNCEMENT', type: 'link', accessKey: '9090' },
				{ path: '/settings/maintenance', title: 'MAINTENANCE', type: 'link', accessKey: '9100' },
			]
		},
    {
      title: 'OUTLET SETTINGS', icon: 'tool', type: 'sub', active: false, accessKey: '10000', children: [
				{ path: '/users/outlet-admin-list', title: 'OUTLET ADMIN', type: 'link', accessKey: '10010' },
				{ path: '/settings/outlet', title: 'OUTLET', type: 'link', accessKey: '10020' },
				{ path: 'outlet-settings/outlet-inventory-request', title: 'INVENTORY REQUEST', type: 'link', accessKey: '10030' },
				// { path: 'outlet-settings/outlet-inventory-out', title: 'INVENTORY OUT', type: 'link', accessKey: '10040' },
				{ path: 'outlet-settings/outlet-request-report', title: 'REPORT', type: 'link', accessKey: '10050' },
      ]
    },
		// {
		// 	title: 'Vendors', icon: 'users', type: 'sub', active: false, children: [
		// 		{ path: '/vendors/list-vendors', title: 'Vendor List', type: 'link' },
		// 		{ path: '/vendors/create-vendors', title: 'Create Vendor', type: 'link' },
		// 	]
		// },
		// {
		// 	title: 'Localization', icon: 'chrome', type: 'sub', children: [
		// 		{ path: '/localization/translations', title: 'Translations', type: 'link' },
		// 		{ path: '/localization/currency-rates', title: 'Currency Rates', type: 'link' },
		// 		{ path: '/localization/taxes', title: 'Taxes', type: 'link' },
		// 	]
		// },
		// {
		// 	title: 'Reports', path: '/reports', icon: 'bar-chart', type: 'link', active: false
		// },
		// {
		// 	title: 'Settings', icon: 'settings', type: 'sub', children: [
		// 		{ path: '/settings/profile', title: 'Profile', type: 'link' },
		// 	]
		// },
		// {
		// 	title: 'Invoice', path: '/invoice', icon: 'archive', type: 'link', active: false
		// },
		{
			// title: 'Login',path: '/auth/login', icon: 'log-in', type: 'link', active: false
		}
	]
	PICKERMENUITEMS: Menu[] = [
		{
			title: 'SALES', icon: 'dollar-sign', type: 'sub', active: false, children: [
				{ path: '/fullfillment/fullfillment-list', title: 'FULLFILLMENT', type: 'link' },
				{ path: '/fullfillment/fullfillment-pickup-list', title: 'PICKUP FULLFILLMENT', type: 'link' },
			]
		},
		{
			title: 'INVENTORY', icon: 'activity', type: 'sub', active: false, children: [
				// { path: '/inventory/product-inventory', title: 'PRODUCT INVENTORY', type: 'link' },
				{ path: '/inventory/inventory-out', title: 'INVENTORY OUT', type: 'link' },
				{ path: '/inventory/item-check', title: 'ITEM CHECK', type: 'link' },
				// { path: '/inventory/inventory-out-info', title: 'INVENTORY OUT INFO', type: 'link' },
				// { path: '/inventory/inventory-out-log', title: 'INVENTORY OUT LOG', type: 'link' },
			]
		},

	]

  OUTLETMENUITEMS: Menu[] = [
		// {
		// 	path: 'dashboard/dropship-dashboard', title: 'DASHBOARD', icon: 'home', type: 'link', badgeType: 'primary', active: false,
		// },
		// {
			// title: 'DASHBOARD', icon: 'home', type: 'sub', active: false, children: [
			// 	{path: 'dashboard/dropship-dashboard', title: 'DROPSHIP', icon: 'home', type: 'link', badgeType: 'primary', active: false},
			// 	{path: 'dashboard/customer-dashboard', title: 'CUSTOMER', icon: 'home', type: 'link', badgeType: 'primary', active: false},
			// ]
		// },
    {path: '/sales/orders', title: 'ORDERS', icon: 'list', type: 'link', badgeType: 'primary', active: false,},{
		title: 'RETAIL ORDER', icon: 'shopping-bag', type: 'sub', active: false, accessKey: 'RT1000', children: [
			{ path: '/retail-order', title: 'PLACE ORDER', type: 'link', accessKey: 'RT1010' },
			// { path: '/sales/transactions', title: 'TRANSACTIONS', type: 'link' },
		]
	},
    {
			title: 'INVENTORY', icon: 'activity', type: 'sub', active: false, children: [
				{ path: '/outlet-inventory/outlet-product-inventory', title: 'PRODUCT INVENTORY', type: 'link' },
				{ path: '/outlet-inventory/request-inventory-list', title: 'REQUEST INVENTORY', type: 'link' },
				{ path: '/inventory/inventory-out', title: 'INVENTORY OUT', type: 'link' },

				// { path: '/variations/sub-category', title: 'SUB CATEGORY', type: 'link' },
				// { path: '/variations/flavour', title: 'FLAVOUR', type: 'link' },
				// { path: '/variations/size', title: 'SIZE', type: 'link' },
				// { path: '/variations/color', title: 'COLOR', type: 'link' },
			],
    },
    {path: '/fullfillment/fullfillment-pickup-list', title: 'FULLFILLMENT', icon: 'list', type: 'link', badgeType: 'primary', active: false,},
  ]

  HQ_OUTLET_MENUITEMS: Menu[] = [
    {path: '/sales/orders', title: 'ORDERS', icon: 'list', type: 'link', badgeType: 'primary', active: false,},
    {
			title: 'INVENTORY', icon: 'activity', type: 'sub', active: false, children: [
				{ path: '/outlet-inventory/outlet-product-inventory', title: 'PRODUCT INVENTORY', type: 'link' },
				{ path: '/outlet-inventory/request-inventory-list', title: 'REQUEST INVENTORY', type: 'link' },
				{ path: '/inventory/inventory-out', title: 'INVENTORY OUT', type: 'link' }
			],
    },
    {
			title: 'FULLFILLMENTS', icon: 'activity', type: 'sub', active: false, children: [
				// { path: '/sales/orders', title: 'ORDERS', type: 'link'},
				{ path: '/fullfillment/fullfillment-list', title: 'FULLFILLMENT', type: 'link' },
				{ path: '/fullfillment/fullfillment-pickup-list', title: 'PICKUP FULLFILLMENT', type: 'link' },
				{ path: '/fullfillment/fullfillment-awb-list', title: 'AWB FULLFILLMENT', type: 'link' },
			],
    },

    // {path: '/fullfillment/fullfillment-pickup-list', title: 'FULLFILLMENT HQ', icon: 'list', type: 'link', badgeType: 'primary', active: false,}
  ]
	// Array
	items = new BehaviorSubject<Menu[]>(this.MENUITEMS);
	pickerItems = new BehaviorSubject<Menu[]>(this.PICKERMENUITEMS);
	outletItems = new BehaviorSubject<Menu[]>(this.OUTLETMENUITEMS);
	outletItemsHQ = new BehaviorSubject<Menu[]>(this.HQ_OUTLET_MENUITEMS);
}
