import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from 'src/app/@services/api.service';
import { ExcelService } from 'src/app/@services/excel.service';
import Swal from 'sweetalert2';
import { PickerEditPasswordComponent } from '../picker-edit-password/picker-edit-password.component';

@Component({
  selector: 'app-picker-list',
  templateUrl: './picker-list.component.html',
  styleUrls: ['./picker-list.component.scss']
})
export class PickerListComponent implements OnInit {

  pickers: any;
  loader = false;

  constructor(
    private api: ApiService,
    private router: Router,
    private excelService: ExcelService,
    private modalService: NgbModal,

  ) { }

  ngOnInit(): void {
    this.getPickers();
  }

  getPickers() {
    this.api.get(this.api.userApi + "staff-user?role=17").subscribe((res: any) => {
      this.pickers = res;
    })
  }
  // agentSearch(searchKey, event) {
  //   if (event.key === "Enter") {
  //     if(searchKey.length >=3){
  //       this.searchText = searchKey;
  //       this.getdropship();
  //     }else{
  //       this.getdropship();
  //     }
  //   }
  // }

  // agentSearchEnter(searchKey){
  //   if(searchKey.length >=3){
  //     this.searchText = searchKey;
  //     this.getdropship();
  //   }else{
  //     this.getdropship();
  //   }
  // }

  addPicker(){
    this.router.navigate(["/users/create-picker"])
  }
  edit(content) {
    const modalRef = this.modalService.open(PickerEditPasswordComponent, { ariaLabelledBy: 'modal-basic-title', backdrop:true });
    (<PickerEditPasswordComponent>modalRef.componentInstance).data = content;
    modalRef.result.then((result) => {
      if(result.status == 1){
      }
    }).catch( (result) => {
    });
  }

   delete(staff) {
    staff.status = 2;
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        this.api.create(this.api.userApi + "user-status", staff).subscribe(res => {
          Swal.fire(
            'Deleted!',
            'Staff has been deleted.',
            'success'
          )
          this.getPickers()
        })

      }

    })
  }


  staffExport: any;
  exportAsXLSX(): void {
    this.loader = true;

    this.api.get(this.api.userApi + "staff-user?role=17").subscribe((res: any) => {
      this.staffExport = res;
      if (res.length>0) {
        this.loader = false
      }
    var exportOrder = []
    this.staffExport.forEach(e => {
      var expo = []
      expo['First name'] = e.details?.first_name;
      expo['Last name'] = e.details?.last_name;
      expo['Email'] = e?.email;
      expo['Phone Number'] = e.details?.phone;
      expo['Address Line 1'] = e.details?.address_line1;
      expo['Address Line 2'] = e.details?.address_line2;
      expo['State'] = e.details?.state;
      expo['Post Code'] = e.details?.post_code;
      expo['Country'] = e.details?.country;

      exportOrder.push(expo)
    });
    this.excelService.exportAsExcelFile(exportOrder, 'Staff Report');
  }, error=>{
    this.loader = false
    Swal.fire("Error", "File too large")
  })

    }
}
