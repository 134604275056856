<div class="container-fluid">
  <div class="row">
    <div class="col-sm-12">
      <div class="card">
        <div class="card-header ">
          <div class="row">
            <div class="col-sm-9">
              <h5>DELETED USERS</h5>
            </div>
            <div class="col-sm-3">
              <div class="input-group">
                <input type="text" (keyup.enter)="search(searchText)"  [(ngModel)]="searchText" class="form-control " placeholder="Search By Email">
                <div class="input-group-append">
                  <button (click)="search(searchText)" class="btn btn-secondary" type="button">
                    <i class="fa fa-search"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card-body">
          <!-- <div class="btn-popup pull-right">
                    <button type="button" class="btn btn-secondary" 
                        >ADD DROPSHIP</button>
                        </div> -->
          <div id="batchDelete" class="custom-datatable">
            <div class="table-responsive">
              <!-- <ng2-smart-table [settings]="settings" [source]="digital_list"></ng2-smart-table> -->
              <table class="table">
                <thead class="thead-dark">
                  <tr>
                    <!-- <th>#</th> -->
                    <th>NAME</th>
                    <th>EMAIL</th>
                    <th>PHONE</th>
                    <th>ADDRESS</th>
                    <th>ACTION</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let user of deletedUsers; let i = index">
                    <td>{{ user.user_name }}</td>
                    <td>{{ user.email }}</td>
                    <td>{{ user.details?.phone ? user.details.phone:'------------'}}</td>
                    <td>{{ user.details?.address_line1 ? user.details.address_line1:'------------'}}</td>
                    <td>
                      <app-feather-icons title="Revert" (click)="revert(user.id)" [icon]="'user-plus'">
                      </app-feather-icons>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="btn-popup pull-right" *ngIf="lastPage > 1 && deletedUsers?.length > 0">
              <pagination-with-api [apiUrl]="apiUrl" [totalPage]="totalPage" (valueChanged)="pageChanged($event)">
              </pagination-with-api>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>