import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/@services/auth.service';
import { StorageService } from 'src/app/@services/storage.service';
import { ApiService } from '../../../@services/api.service';
// import Swal from 'sweetalert2'

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  email:string;
  password: string;
  public loginForm: FormGroup;
  public registerForm: FormGroup;


      constructor(private formBuilder: FormBuilder,
        private auth: AuthService,
        private storage: StorageService,
        private router: Router,
        private api  :ApiService
        ) {
    this.createLoginForm();
    this.createRegisterForm();
  }

  owlcarousel = [
    {
      title: "Welcome to Obsess",
      desc: " ",
    },

  ]
  owlcarouselOptions = {
    loop: true,
    items: 1,
    dots: true
  };

  createLoginForm() {
    this.loginForm = this.formBuilder.group({
      email: [''],
      password: [''],
    })
  }
  createRegisterForm() {
    this.registerForm = this.formBuilder.group({
      email: [''],
      password: [''],
      confirmPassword: [''],
    })
  }


  ngOnInit() {
    if (this.auth.loggedIn()) {
      this.router.navigate(["/"])
    }
  }

  login() {
    // console.log(this.loginForm.value);

    this.auth.login(this.loginForm.value.email, this.loginForm.value.password).then((res:any) => {

      if(res.user.role.role === "Customer" || res.user.role.role === "Agent" || res.user.role.role === "Team Sale")
      {
        // Swal.fire({
        //   position: 'center',
        //   icon: 'error',
        //   title: 'Access denied',
        //   showConfirmButton: false,
        //   timer: 1500
        // })
        localStorage.clear();
        // localStorage.setItem("leng", 'eng');

      }else{
        this.afterLogin(res)
      }

    }, error => {
      //later show error
      if(error){
        this.email === ''
        this.password === ''
      }
      // Swal.fire({
      //   position: 'center',
      //   icon: 'error',
      //   title: 'Email or password incorrect ',
      //   showConfirmButton: false,
      //   timer: 1500
      // })
    })
  }


  afterLogin(res:any) {
    // console.log(res.user.id);
    this.storage.set("auth", res);
    if(res.user.user_role == 18){
      this.router.navigate(["sales/orders"])
    } else if(res.user.user_role == 17){
      this.router.navigate(["fullfillment/fullfillment-list"])
    }else{
      this.router.navigate(["/"])
    }
  }

  /*
  access_code : any = null;
  async getAccess(userid){
    // this.api.get(this.api.userApi + "getaccess/"+userid).subscribe((res: any) => {
    //   this.storage.set("accesscode", res.data.access);
    // },
    // (error: any) => {
    //   console.error(error);
    // });

    let apiUrl = this.api.userApi + "getaccess/"+userid;
    this.access_code = await this.api.get(apiUrl).toPromise();
    console.log(this.access_code.data.access);
    // this.storage.set("accesscode",this.access_code.data.access);
    return this.access_code.data.access;
  } */


}
