import { Routes } from '@angular/router';
import { AuthGuard } from 'src/app/@guards/auth.guard';
import { NoAccessGuard } from 'src/app/@guards/no-access.guard';

export const content: Routes = [
  {
    path: 'no-access',
    loadChildren: () => import('../../components/no-access/no-access.module').then(m => m.NoAccessModule),
    data: {
      role: [1]
    },
    // canActivate: [AuthGuard], no need, this will infinite loop
    canActivate: [NoAccessGuard],
  },
  {
    path: 'dashboard',
    loadChildren: () => import('../../components/dashboard/dashboard.module').then(m => m.DashboardModule),
    data: {
      role: [1],
      accessCode: "0000"
    },
    canActivate: [AuthGuard],
  },
  {
    path: 'sales',
    loadChildren: () => import('../../components/sales/sales.module').then(m => m.SalesModule),
    data: {
      breadcrumb: "Sales",
      role: [1, 18],
      accessCode: "1000"
    },
    canActivate: [AuthGuard],

  },
  {
    path: 'retail-order',
    loadChildren: () => import('../../components/retail-order/retail-order.module').then(m => m.RetailOrderModule),
    data: {
      breadcrumb: "Retail Order Purchase",
      role: [1, 18],
      accessCode: "RT1000"
    },
    canActivate: [AuthGuard],

  },
  {
    path: 'order-possystem-confirm/:signature/:orderid',
    loadChildren: () => import('../../components/retail-order/order-possystem-confirm/order-possystem-confirm.module').then(m => m.OrderPossystemConfirmModule),
    data: {
      breadcrumb: "Retail Order Purchase",
      // role: [1, 18],
      accessCode: "RT1000"
    },
    canActivate: [AuthGuard],

  },
  {
    path: 'fullfillment',
    loadChildren: () => import('../../components/fullfillment/fullfillment.module').then(m => m.FullfillmentModule),
    data: {
      breadcrumb: "Fullfillment",
      role:[1, 17, 18],
      accessCode: "1000" // same key access as 'sales'
    },
    canActivate: [AuthGuard],

  },
  {
    path: 'products',
    loadChildren: () => import('../../components/products/products.module').then(m => m.ProductsModule),
    data: {
      breadcrumb: "Products",
      role: [1],
      accessCode: "2000"
    },
    canActivate: [AuthGuard],

  },
  {
    path: 'inventory',
    loadChildren: () => import('../../components/inventory/inventory.module').then(m => m.InventoryModule),
    data: {
      breadcrumb: "Inventory",
      role: [1, 18, 17],
      accessCode: "3000"
    },
    canActivate: [AuthGuard],

  },
  {
    path: 'variations',
    loadChildren: () => import('../../components/variations/variations.module').then(m => m.VariationsModule),
    data: {
      breadcrumb: "Variations",
      role: [1],
      accessCode: "4000"
    },
    canActivate: [AuthGuard],

  },
  {
    path: 'promotion',
    loadChildren: () => import('../../components/promotion/promotion.module').then(m => m.PromotionModule),
    data: {
      breadcrumb: "Promotion",
      role: [1],
      accessCode: "5000"
    },
    canActivate: [AuthGuard],

  },
  {
    path: 'report',
    loadChildren: () => import('../../components/report/report.module').then(m => m.ReportModule),
    data: {
      breadcrumb: "Report",
      role: [1],
      accessCode: "6000"
    },
    canActivate: [AuthGuard],

  },
  {
    path: 'credit',
    loadChildren: () => import('../../components/credit/credit.module').then(m => m.CreditModule),
    data: {
      breadcrumb: "Credit",
      role:[ 1],
      accessCode: "7000"
    },
    canActivate: [AuthGuard],

  },
  {
    path: 'points',
    loadChildren: () => import('../../components/points/points.module').then(m => m.PointsModule),
    data: {
      breadcrumb: "Points",
      role:[ 1],
      accessCode: "7000"
    },
    canActivate: [AuthGuard],

  },
  {
    path: 'withdraw',
    loadChildren: () => import('../../components/withdraw/withdraw.module').then(m => m.WithdrawModule),
    data: {
      breadcrumb: "Withdraw",
      role:[ 1],
      accessCode: "7100"
    },
    canActivate: [AuthGuard],

  },
  {
    path: 'ewallet',
    loadChildren: () => import('../../components/ewallet-list/ewallet-list.module').then(m => m.EwalletListModule),
    data: {
      breadcrumb: "ewallet",
      role:[ 1],
      accessCode: "7100"
    },
    canActivate: [AuthGuard],

  },
  {
    path: 'coming-soon',
    loadChildren: () => import('../../components/coming-soon/coming-soon.module').then(m => m.ComingSoonModule),
    data: {
      breadcrumb: "Coming Soon",
      role:[ 1],
      accessCode: "1000"
    },
    canActivate: [AuthGuard],

  },
  {
    path: 'users',
    loadChildren: () => import('../../components/users/users.module').then(m => m.UsersModule),
    data: {
      breadcrumb: "Users",
      role:[ 1],
      accessCode: "8000"
    },
    canActivate: [AuthGuard],

  },
  {
    path: 'settings',
    loadChildren: () => import('../../components/settings/settings.module').then(m => m.SettingsModule),
    data: {
      breadcrumb: "Settings",
      role: [1],
      accessCode: "9000"
    },
    canActivate: [AuthGuard],

  },
  // {
  //   path: 'settings',
  //   loadChildren: () => import('../../components/setting/setting.module').then(m => m.SettingModule),
  //   data: {
  //     breadcrumb: "Settings",
  //     role:[ 1]
  //   }
  // },
  {
    path: 'outlet-settings',
    loadChildren: () => import('../../components/outlet-settings/outlet-settings.module').then(m => m.OutletSettingsModule),
    data: {
      breadcrumb: "Settings",
      role:[ 1],
      accessCode: "10000"
    },
    canActivate: [AuthGuard],
  },
  {
    path: 'coupons',
    loadChildren: () => import('../../components/coupons/coupons.module').then(m => m.CouponsModule),
    data: {
      breadcrumb: "Coupons"
    },
    canActivate: [AuthGuard],

  },
  {
    path: 'pages',
    loadChildren: () => import('../../components/pages/pages.module').then(m => m.PagesModule),
    data: {
      breadcrumb: "Pages"
    },
    canActivate: [AuthGuard],

  },
  {
    path: 'media',
    loadChildren: () => import('../../components/media/media.module').then(m => m.MediaModule),
  },
  {
    path: 'menus',
    loadChildren: () => import('../../components/menus/menus.module').then(m => m.MenusModule),
    data: {
      breadcrumb: "Menus",
      role: [18,1]
    }
  },
  {
    path: 'vendors',
    loadChildren: () => import('../../components/vendors/vendors.module').then(m => m.VendorsModule),
    data: {
      breadcrumb: "Vendors",
      role:[ 1]
    }
  },
  {
    path: 'localization',
    loadChildren: () => import('../../components/localization/localization.module').then(m => m.LocalizationModule),
    data: {
      breadcrumb: "Localization",
      role:[ 1]
    }
  },
  {
    path: 'reports',
    loadChildren: () => import('../../components/reports/reports.module').then(m => m.ReportsModule),
    data: {
      breadcrumb: "reports",
      role:[ 1]
    }
  },
  {
    path: 'invoice',
    loadChildren: () => import('../../components/invoice/invoice.module').then(m => m.InvoiceModule),
    data: {
      breadcrumb: "Invoice",
      role:[ 1]
    }
  },
  {
    path: 'outlet-inventory',
    loadChildren: () => import('../../components/outlet-inventory/outlet-inventory.module').then(m => m.OutletInventoryModule),
    data: {
      breadcrumb: "Outlet Inventory",
      role: [18],
      accessCode: "3000" // same parent accessKey 3000 as /inventory
    },
    canActivate: [AuthGuard],
  },
];
