import { StorageService } from 'src/app/@services/storage.service';
import { Injectable } from '@angular/core';
import * as CryptoJS from "crypto-js";
import { AccessmodelService } from '../@models/accessmodel.service';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class AccessService {

  sk = this.accessModel.sk;
  userAccessCodeList : any = null;

  constructor(
    private accessModel : AccessmodelService,
    private api : ApiService,
    private storage : StorageService
  ) { }

  checkAccessCode(ac){
    // no-access-debug-code
    // console.log(ac.pageAccessCode);
    let rawDec = CryptoJS.AES.decrypt(ac.encAccessCode, this.sk);
    let aceStr = rawDec.toString(CryptoJS.enc.Utf8);
    this.userAccessCodeList = aceStr.split(",");
    // console.log(this.userAccessCodeList);
    if (this.userAccessCodeList.includes(ac.pageAccessCode)) {
      // console.log("accessKey exists in the array");
      return true;
    } else {
      // console.log("accessKey does not exist in the array");
      return false;
    }
  }

  resp: any;
  async getUserAccess(userid){
      let apiUrl = this.api.userApi + "getaccess/"+userid;
      this.resp = await this.api.get(apiUrl).toPromise();
      // console.log(this.resp.data.access);
      // this.api.get(apiUrl).subscribe((res: any) => { return res.data.access; });
      return this.resp.data;
  }

  // pindah sini
  accessKey : any;
  isViewerOnly:boolean;
  isViewerCheck(){

    // opt 1: get access key without calling several same api, but not updated
    const user = this.storage.get("auth");
    this.accessKey = user.acl;

    // console.log(user.user.id)
    // opt 2: get access key by calling api several time, so data always updated
    // this.api.get(this.api.userApi + "getaccess/"+user.user.id).subscribe((res: any) => { this.accessKey = res.data.access; });
    // this.resp = this.api.get(this.api.userApi + "getaccess/"+user.user.id).toPromise();
    // console.log(this.accessKey);

    let viewerToCheck = {
      encAccessCode : this.accessKey,
      pageAccessCode : '111111'
    }
    this.isViewerOnly = this.checkAccessCode(viewerToCheck);
    // console.log(this.isViewerOnly);
    return this.isViewerOnly;
  }

}
