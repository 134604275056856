import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Color } from 'src/app/@models/color';
import { ApiService } from 'src/app/@services/api.service';
import Swal from "sweetalert2";
import { AppService } from './../../../app.service';

@Component({
  selector: 'app-change-shipping-address-dialog',
  templateUrl: './change-shipping-address-dialog.component.html',
})
export class ChangeShippingAddressDialogComponent implements OnInit {
  public closeResult: string;
  // model: Category = new Category();
  form: FormGroup;
  @Input() address: any = {} as Color
  public settings = {};
  countries: any;
  states: any;
  constructor(
      public activeModal: NgbActiveModal,
      private api: ApiService,
      private formBuilder: FormBuilder,
      private appService: AppService
      ) {
  }
  ngOnInit() {
    
    this.form = this.formBuilder.group({
      id: [this.address.id],
      phone: [this.address.phone, Validators.compose([Validators.required,Validators.pattern('[0-9]{9,14}')])],
      receiver_name: [this.address.receiver_name, Validators.required],
      address_line1: [this.address.address_line1, Validators.required],
      address_line2: [this.address.address_line2, Validators.nullValidator],
      state: [this.address.state, Validators.required],
      country: [this.address.country, Validators.required],
      post_code: [this.address.post_code, Validators.compose([Validators.required,Validators.minLength(5), Validators.maxLength(6)])],    
   })
    this.countries = this.appService.getCountries()
    this.states = this.appService.getStates()
  }

  close(){
    this.activeModal.dismiss('close')
  }

  save() {
    // this.validation()
    if(!this.form.valid){
      Swal.fire({
        position: "center",
        icon: "error",
        title: "Invalid Form",
        showConfirmButton: false,
        timer: 1500,
      });
    };
      this.api.create(this.api.orderApi + "update-order-shipping-address", this.form.value).subscribe(
        (res: any) => {
          if (res.status == "ok") {
            Swal.fire({
              position: "center",
              icon: "success",
              title: "Address Updated!",
              showConfirmButton: false,
              timer: 1500,
            });
          }
          this.activeModal.close();
        },
        (error) => {
          console.log(error);
        }
      );
  }
  
  // validation //
  nameErrorMsg:boolean = false;
  codeErrorMsg:boolean = false;
  validation(){
   if(this.form.controls.color.status == "INVALID"){
    this.nameErrorMsg = true;
   }else{
    this.nameErrorMsg = false;
   }

   if(this.form.controls.color_code.status == "INVALID"){
    this.codeErrorMsg = true;
   }else{
    this.codeErrorMsg = false;
   }

  }
}
export interface CategoryImageFile {
  id: string | null;
  categoryId: number;
  original: string;
  // added for new model 
  image_80: string;
  image_250: string;
  image_350: string;
  image_560: string;
  image_800: string;
  image_original: string;
}