import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/@services/api.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-deleted-users',
  templateUrl: './deleted-users.component.html',
  styleUrls: ['./deleted-users.component.scss']
})
export class DeletedUsersComponent implements OnInit {
  deletedUsers: any;
  customersExport: any;
  searchText: string = '';
  totalPage: number;
  lastPage: any;
  currentPage: any;
  pages: any[] = [];
  next = 2;
  constructor(
    private api: ApiService,
  ) { }

  ngOnInit(): void {
    this.getUsers();
  }

  get apiUrl (){
    return this.api.userApi + 'deleted-user?page=' + 1 + '&search=' + this.searchText;
  }
  getUsers() {
    this.api.get(this.apiUrl).subscribe((res: any) => {
      this.deletedUsers = res.data;
      this.lastPage = res.last_page;
      this.currentPage = res.current_page
      this.totalPage = res.last_page
    })
  }
  search(searchKey) {
    if(searchKey.length >=3){
      this.getUsers();
    }else{
      this.getUsers();
    }
  }
  pageChanged(deletedUsers:any){
    if(deletedUsers == '')return
    this.deletedUsers = deletedUsers
    window.scrollBy(0, -1990);
   }

   
  revert(id) {
    var model = {
      id: id,
      status: 1
    }
    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to revert this user!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, revert it!'
    }).then((result) => {
      if (result.isConfirmed) {
        this.api.create(this.api.userApi + 'user-status', model).subscribe(res => {
          Swal.fire(
            'Reverted!',
            'User has been reverted.',
            'success'
          );
          this.getUsers()
        });
      }
    });
  }
  
}
